<template>
  <bao-widget-display :widget="widget" :previewMode="previewMode">
    <template v-slot:additional-info>
      <div class="d-flex justify-content-end align-items-center w-100 pb-1 main-slate">
        <div class="mr-3 my-auto font-size-12">
          <span>{{staticText.overallReachability}}: <b>{{overallReachability}}</b>  %</span>
        </div>
        <div class="d-flex font-size-10">
          <div class="my-auto mr-2">{{ staticText.low }}</div>
          <div class="my-auto  mr-2 div-scale"></div>
          <div class="my-auto">{{ staticText.high }}</div>
        </div>
      </div>
    </template>
    <template v-slot:chart>
      <div class="pt-3">
        <slot name="chart"></slot>
      </div>
    </template>
  </bao-widget-display>
</template>

<script>

import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"
import { getTranslatedTimeframes } from "@/apps/dashboard/chartUtils"

export default {
  name: "BaoWidgetReachabilityMatrixDisplay",
  components: { BaoWidgetDisplay },
  props: {
    widget: {
      type: Object,
      required: true
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false
    },
    overallReachability: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      getTranslatedTimeframes,
      staticTextDefault: {
        configuration: "Configuration",
        reachabilityScale: "Reachability Scale",
        low: "Low",
        high: "High",
        overallReachability: "Overall Reachability"
      },
      translatedTimeFrames: {}
    }
  },
  watch: {
    widget () {
      this.setUp()
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    setUp () {
      this.translatedTimeFrames = this.getTranslatedTimeframes()
    }
  }
}
</script>

<style scoped lang="scss">
.reachability-scale {
  width: 100%;
  display: flex;
}

.div-scale {
  height: 0.5em;
  width: 60px;
  background-image: linear-gradient(to right, rgba(197, 128, 93, 0.05), rgba(197, 128, 93, 1));
}

.main-slate {
  color: $slate;
}

.font-size-10{
  font-size: 10px;
}

</style>
