import axios from "axios"
import Vue from "vue"

const state = {
  selectedCalls: {},
  currentCall: {},
  currentCallItem: null
}

const getters = {
  getSelectedCalls: state => {
    return state.selectedCalls
  },
  getCurrentCallItem (state) {
    return state.currentCallItem
  }
}

const mutations = {
  addToSelectedCalls (state, call) {
    if (!state.selectedCalls[call.id]) {
      Vue.set(state.selectedCalls, call.id, call)
    }
  },
  removeFromSelectedCalls (state, call) {
    if (state.selectedCalls[call.id]) {
      Vue.delete(state.selectedCalls, call.id)
    }
  },
  clearSelectedCalls (state) {
    state.selectedCalls = {}
  },
  setCallItem (state, newItem) {
    state.currentCallItem = newItem
  },
  resetCallItem (state) {
    state.currentCallItem = null
  }
}

const actions = {
  setCallItem (context, item) {
    context.commit("setCallItem", item)
  },
  resetCallItem (context) {
    context.commit("resetCallItem")
  },
  callSelected (context, call) {
    context.commit("addToSelectedCalls", call)
  },
  callDeselected (context, call) {
    context.commit("removeFromSelectedCalls", call)
  },
  clearSelectedCalls (context) {
    context.commit("clearSelectedCalls")
  },
  deleteSelectedCalls ({ dispatch, context }, calls) {
    const deletePromises = []
    for (const i in calls) {
      const call = calls[i]
      const deletePromise = axios.delete("/api/calls/" + call.id).then(() => {
        dispatch("callDeselected", call)
      }).catch(error => {
        throw (error)
      })
      deletePromises.push(deletePromise)
    }
    return Promise.all(deletePromises)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
