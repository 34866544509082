import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  createdDateArray,
  getDefaultLineChartOptions
} from "@/apps/dashboard/chartUtils"
import moment from "moment"
import { defaultLineDatasetValues } from "@/apps/dashboard/chartConfig"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const LineChart = () => import(/* webpackChunkName: "LineChart" */ "../BaoWidgetsBaseComponents/LineChart")

export default {
  name: "BaoWidgetCallsPerMonth",
  info: {
    title: "Call activity per month",
    description: "How many conversations did my team have in a particular month over a 12-month period?"
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getDefaultLineChartOptions,
      createdDateArray,
      chartComponent: LineChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay
    }
  },
  methods: {
    mappingFunc (items) {
      const delta = this.widget.config && this.widget.config.created_at__gte
        ? this.widget.config.created_at__gte - 1
        : 11
      const dateArray = this.createdDateArray(moment().subtract(delta, "month"), "month")
      const data = dateArray.map(date => {
        const result = { text: date.toLocaleString("default", { month: "short", year: "numeric" }), count: 0 }
        const data = items.filter(item => {
          return item.created_at__month === moment(date).month() + 1 && item.created_at__year === moment(date).year()
        })
        if (data && data.length > 0) result.count = data[0].count
        return result
      })
      const result = {}
      result.labels = data.map(item => item.text)
      result.datasets = [{
        ...defaultLineDatasetValues,
        data: data.map(item => Math.round(item.count * 100) / 100)
      }]
      return result
    },
    getChartOptions () {
      return this.getDefaultLineChartOptions(this.widget, {})
    },
    getConfigOptions () {
      return []
    },
    getDisplayProps () {
      return { widget: this.widget }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
