const BASE_URL = process.env.VUE_APP_BASEURL
export const canDebug = BASE_URL && BASE_URL.includes("localhost")

export const debug = {
  log: (...params) => {
    if (canDebug) {
      console.log(...params)
      console.trace("Traceback:")
    }
  }
}

export const getCallIdFromURL = () => {
  return new Promise((resolve) => {
    const MAX_RETRIES = 10
    const retryDelay = 1000
    let retries = 0

    const extractCallId = () => {
      const match = window.location.href.match(/\/(\d+)(?:\?|$)/)
      if (match) {
        console.log("Found call ID:", parseInt(match[1]))
        resolve(parseInt(match[1]))
      } else if (retries < MAX_RETRIES) {
        retries++
        console.log("Failed to find call ID in URL, retrying in", retryDelay, "ms")
        setTimeout(extractCallId, retryDelay)
      } else {
        console.log("Failed to find call ID in URL after", MAX_RETRIES, "retries")
        resolve(null)
      }
    }

    extractCallId()
  })
}

const cookieString = (cname, cvalue) => {
  return cname + "=" + cvalue + ";SameSite=None;Secure;domain=" + location.hostname + ";path=/"
}

export const removeCookie = (cname) => {
  const cookie = getCookie(cname)
  if (cookie) {
    document.cookie = cookieString(cname) + ";Expires=Thu, 01 Jan 1970 00:00:00 GMT"
  }
}

// source: https://www.w3schools.com/js/js_cookies.asp
export const getCookie = (cname) => {
  const name = cname + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const setCookie = (cname, cvalue) => {
  document.cookie = cookieString(cname, cvalue)
}
