<template>
  <base-filter
    :value="options.value"
    :label="options.label || staticText.selectShortcuts"
    :showLabel="false"
    :url="getUrl"
    :multiselect="options.multiselect"
    key-to-extract-value="id"
    :required="options.required"
    :internal-search="true"
    :hasError="hasError"
    @input="handleValueChanged"
  ></base-filter>
</template>

<script>
import BaseFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BaseFilter"
import { WidgetConfigOptions } from "@/apps/dashboard/chartUtils"

export default {
  name: "ObjectionSelector",
  components: { BaseFilter },
  props: {
    options: {
      type: WidgetConfigOptions,
      required: true
    },
    selectedPlaybookId: { // playbook items selected for filtering
      type: Number,
      required: true
    }
  },
  data () {
    return {
      selectedObjections: [],
      hasError: false,
      staticTextDefault: {
        selectShortcuts: "Shortcuts"
      }
    }
  },
  computed: {
    getUrl () {
      return `/api/talkscripts/${this.selectedPlaybookId}/get_objections`
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    handleValueChanged (newValue) {
      this.selectedObjections = newValue
      this.$emit("input", this.selectedObjections)
      if (this.selectedObjections.length > 0) this.validateFilter()
    },
    validateFilter () {
      this.hasError = !this.selectedObjections.length
      return !this.hasError
    }
  }
}
</script>

<style scoped>

</style>
