import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  FILTER_KEY_PLAYBOOK,
  getDefaultHorizontalBarChartOptions
} from "@/apps/dashboard/chartUtils"
import { defaultHorizontalDatasetValues } from "@/apps/dashboard/chartConfig"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const HorizontalBarChart = () => import(/* webpackChunkName: "HorizontalBarChart" */ "@/apps/dashboard/BaoWidgetsBaseComponents/HorizontalBarChart")

export default {
  name: "BaoWidgetMostFrequentAnswerForItem",
  info: {
    title: "Most Frequent Answers",
    description: "Shows which answers were given most often per selected item."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getDefaultHorizontalBarChartOptions,
      chartComponent: HorizontalBarChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      totalAnswers: 0
    }
  },
  methods: {
    mappingFunc (items) {
      items = items
        .sort((a, b) => b.count_text - a.count_text)
        .filter(item => item.count_text > 0)
        .splice(0, 10)
      // TODO: combine the rest into "Other" field
      const labels = []
      const data = []
      const callIds = []
      this.totalAnswers = this.getTotalCount(items, "count_text")
      for (const item of items) {
        labels.push(item.text)
        data.push(item.count_text)
        callIds.push(item.arrayagg_call_item__call__id)
      }
      return {
        labels,
        datasets: [{ ...defaultHorizontalDatasetValues, data }],
        callIds
      }
    },
    getChartOptions () {
      const dataLabelClickCallBack = (dataIndex) => {
        this.showConversations(dataIndex)
      }
      return this.getDefaultHorizontalBarChartOptions(
        this.widget,
        this,
        {
          dataLabelClickCallBack
        }
      )
    },
    getConfigOptions () {
      return [
        {
          type: "filterOptions",
          blacklist: [FILTER_KEY_PLAYBOOK]
        },
        {
          type: "playbookAndPlaybookItemFilter",
          value: {
            playbooks: [],
            playbookItems: []
          }, // default
          fieldName: "playbookAndPlaybookItemFilter"
        }
      ]
    },
    getDisplayProps () {
      return { widget: this.widget, totalItems: this.totalAnswers }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
