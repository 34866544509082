import axios from "axios"

const state = {
  allPlaybooks: [],
  defaultPlaybook: null
}

const getters = {
  getAllPlaybooks (state) {
    return state.allPlaybooks
  },
  getDefaultPlaybook (state) {
    return state.defaultPlaybook
  }
}

const mutations = {
  setAllPlaybooks (state, playbooks) {
    state.allPlaybooks = playbooks
  },
  setDefaultPlaybook (state, playbook) {
    state.defaultPlaybook = playbook
  }
}

const actions = {
  async fetchAllPlaybooks ({ commit }, queryParams = {}) {
    try {
      queryParams = { ...queryParams, type: "default", active: "True" }
      const { data } = await axios.get("/api/talkscripts/get_min_view", { params: queryParams })
      commit("setAllPlaybooks", data.sort((a, b) => a.name.localeCompare(b.name)))
    } catch (error) {
      console.error(error, "error")
    }
  },
  async fetchDefaultPlaybook ({ commit }) {
    try {
      const { data } = await axios.get("/api/talkscripts/get_default")

      if (data && Object.keys(data).length) commit("setDefaultPlaybook", data)
      else commit("setDefaultPlaybook", null)
    } catch (error) {
      console.error(error, "error")
    }
  },
  async setDefaultPlaybook ({ commit }, playbook) {
    try {
      const { data } = await axios.get(`/api/talkscripts/${playbook.id}/set_default`)
      commit("setDefaultPlaybook", data)
    } catch (error) {

    }
  },
  async unsetDefaultPlaybook ({ commit }) {
    await axios.get("/api/users/unset_default_playbook")
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
