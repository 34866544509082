import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  getDefaultHorizontalBarChartOptions
} from "@/apps/dashboard/chartUtils"
import { defaultHorizontalDatasetValues } from "@/apps/dashboard/chartConfig"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const HorizontalBarChart = () => import(/* webpackChunkName: "HorizontalBarChart" */ "@/apps/dashboard/BaoWidgetsBaseComponents/HorizontalBarChart")

export default {
  name: "BaoWidgetMostFrequentShortcuts",
  info: {
    title: "Most Used Shortcuts",
    description: "Indicates which shortcuts are used most frequently."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getDefaultHorizontalBarChartOptions,
      chartComponent: HorizontalBarChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      totalObjections: 0
    }
  },
  methods: {
    mappingFunc (items) {
      items = items
        .sort((a, b) => b.countdistinct_additional_data_for_call__created_at - a.countdistinct_additional_data_for_call__created_at)
        .filter(item => item.countiftrue_additional_data_for_call__is_objection > 0)
        .splice(0, 10)
      const labels = []
      const data = []
      const callIds = []
      this.totalObjections = this.getTotalCount(items, "countdistinct_additional_data_for_call__created_at")
      for (const item of items) {
        labels.push(item.additional_data_for_call__selected_choice__talkscript__name)
        data.push(item.countdistinct_additional_data_for_call__created_at)
        callIds.push(item.arrayagg_id)
      }
      return {
        labels,
        datasets: [{ ...defaultHorizontalDatasetValues, data }],
        callIds
      }
    },
    getChartOptions () {
      const dataLabelClickCallBack = (dataIndex) => {
        this.showConversations(dataIndex)
      }
      return this.getDefaultHorizontalBarChartOptions(
        this.widget,
        this,
        {
          dataLabelClickCallBack
        }
      )
    },
    getConfigOptions () {
      return []
    },
    getDisplayProps () {
      return { widget: this.widget, totalItems: this.totalObjections }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
