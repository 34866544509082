import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  getDefaultLineChartOptions,
  getUsername,
  computeDateLabelForData,
  formatMillisecondsToHHMMSS
} from "@/apps/dashboard/chartUtils"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const LineChart = () => import(/* webpackChunkName: "LineChart" */ "@/apps/dashboard/BaoWidgetsBaseComponents/LineChart")

export default {
  name: "BaoWidgetTotalCallTime",
  info: {
    title: "Total Call Time",
    description: "Displays the summarized call duration of calls over time."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getUsername,
      computeDateLabelForData,
      getDefaultLineChartOptions,
      formatMillisecondsToHHMMSS,
      chartComponent: LineChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      totalCallTime: 0,
      staticTextDefault: {
        hourLabel: "h",
        minutesLabel: "min",
        secondsLabel: "sec"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showGoToConversationsButton () {
      return this.totalCallTime > 0
    }
  },
  methods: {
    mappingFunc (data) {
      this.totalCallTime = data.total_call_time
      const transformedData = this.computeDateLabelForData(data, this.widget)
      const { labels, dataSet } = this.getDatasets(transformedData)
      const defaultConfig = {
        fill: false,
        tension: 0.1,
        borderWidth: 0.9
      }
      return {
        labels,
        datasets: [
          {
            label: this.staticText.avg,
            data: dataSet,
            borderColor: "rgba(177, 82, 36, 1)",
            ...defaultConfig
          }
        ]
      }
    },
    getDatasets (transformedData) {
      const labels = []
      const dataSet = []
      for (const item of transformedData) {
        labels.push(item.text)
        if (item.sum_duration) dataSet.push(item.sum_duration)
        else dataSet.push(0.0)
      }
      return { labels, dataSet }
    },
    getChartOptions () {
      const chartOptions = this.getDefaultLineChartOptions(this.widget)
      chartOptions.scales.yAxes[0].ticks.callback = (milliseconds) => this.formatMillisecondsToHHMMSS(milliseconds)
      chartOptions.scales.yAxes[0].scaleLabel = { display: true, labelString: "HH:MM:SS", fontSize: 10, padding: 0 }
      chartOptions.tooltips.callbacks.label = (value) => this.formatMillisecondsToHHMMSS(value.yLabel)
      return chartOptions
    },
    getConfigOptions () {
      return []
    },
    getDisplayProps () {
      const formattedTotalCallTime = this.formatTotalCallTime(this.formatMillisecondsToHHMMSS(this.totalCallTime))
      return { widget: this.widget, totalItems: formattedTotalCallTime, showConversationsView: this.showGoToConversationsButton }
    },
    formatTotalCallTime (timeInHHMMSS) {
      // This formats time in HH:MM:SS to HH h, MM mins, SS secs
      const timeArray = timeInHHMMSS.split(":")
      const hours = Number(timeArray[0])
      const minutes = Number(timeArray[1])
      const seconds = Number(timeArray[2])
      let formattedTime = ""
      if (hours > 0 || minutes > 0 || seconds > 0) {
        if (hours > 0) formattedTime += `${hours} ${this.staticText.hourLabel}, `
        if (minutes > 0) formattedTime += `${minutes} ${this.staticText.minutesLabel}, `
        if (seconds > 0) formattedTime += `${seconds} ${this.staticText.secondsLabel}`
        return formattedTime
      } else return 0
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
