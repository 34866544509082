const Signout = () => import(/* webpackChunkName: "Signout" */ "./SignoutModal.vue")

// exports basic app infos required for other modules and CallViewItem_components
const baseUrl = "/"
export default {
  name: "Home",
  apiUrl: "/api" + baseUrl,
  route: {
    path: baseUrl,
    redirect: "/call"
  },
  routes: [
    { path: "/signout", component: Signout }
  ]
}
