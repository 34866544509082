import appInfo from "."
import axios from "axios"
import Vue from "vue"
import { baoDelayService } from "@/apps/call"

// exports services required for other modules and CallViewItem_components
export const playbookServices = {
  name: "PlaybookServices",
  httpRequestService: axios,
  runningIndex: 0,
  methods: {
    getUniqueId () {
      playbookServices.runningIndex += 1
      return "playbookItem" + Date.now() + Math.floor(Math.random()) + "-" + playbookServices.runningIndex
    },
    getDefaultPlaybookItem ({ parent = null, order = null, isObjection = false, isMainContainer = false } = {}) {
      let itemType = "static"
      let name = "Item Name"
      if (isMainContainer) {
        itemType = "container"
        name = "MainContainer"
      }
      let defaultPlaybookItem = {
        additional_talkscripts: [],
        additionalPlaybookFlag: false,
        answer_choices: [],
        display_text: "",
        ending_item: false,
        is_objection: isObjection,
        is_library_item: false,
        item_type: itemType,
        linked_field: {},
        name,
        note: true,
        objectionFlag: false,
        objections: [],
        open: true,
        relevant_for_text_match: false,
        settings: false,
        tags: [],
        uniqueId: playbookServices.methods.getUniqueId(),
        versions: []
      }
      // parent id is required so that the newly created item can be attached to the parent in backend
      if (parent || order) {
        defaultPlaybookItem = parent ? { ...defaultPlaybookItem, parent, order } : { ...defaultPlaybookItem, order }
      } else {
        if (!isMainContainer) defaultPlaybookItem = { ...defaultPlaybookItem, is_library_item: true }
      }
      return defaultPlaybookItem
    },
    transformItem (item, mainContainer) {
      item.uniqueId = item.uniqueId || playbookServices.methods.getUniqueId()
      Vue.set(item, "open", true)
      Vue.set(item, "settings", false)
      if (item.objections && item.objections.length > 0) {
        Vue.set(item, "objectionFlag", true)
      } else if (!item.objectionFlag) Vue.set(item, "objectionFlag", false)
      Vue.set(item, "currentVersionId", playbookServices.methods.getVersionId(item))
      if (mainContainer && mainContainer.id) item.parent = mainContainer.id
      if (!item.linked_field) item.linked_field = {}
      if (item.with_importance === undefined && item.answer_choices && item.answer_choices.length > 0) {
        item.with_importance = item.answer_choices.some(answer => answer.with_importance)
      }

      // Used to keep track of all the waiting-update promises. It is loaded when item change request is sent and
      // used when needed to execute all operations instantly
      item.waitingUpdatePromises = []
      return item
    },
    savePlaybook (playbook) {
      return new Promise((resolve, reject) => {
        let method = "post"
        let url = appInfo.apiUrl.playbook
        // check if saving as a new playbook only then do a update else create new container and playbook
        if (playbook.id) {
          method = "patch"
          url += "/" + playbook.id
        }
        playbookServices.httpRequestService[method](url, playbook).then((response) => {
          resolve(response.data)
        }, (error) => {
          reject(error)
        })
      })
    },
    saveMainContainer (mainContainer) {
      return playbookServices.httpRequestService.patch(
        appInfo.apiUrl.playbookItem + "/" + mainContainer.id,
        {
          children: mainContainer.children ? mainContainer.children.map(playbookServices.methods.prepareItemForSave) : [],
          objections: mainContainer.objections,
          additional_talkscripts: mainContainer.additional_talkscripts
        }
      )
    },
    prepareItemForSave (playbookItem) {
      // TODO: simplify this method and the general workflow how playbook items are saved
      const {
        id,
        name,
        item_type,
        display_text,
        answer_choices,
        data,
        ending_item,
        note,
        linked_field,
        required,
        collapse,
        additional_talkscripts,
        objections,
        parent,
        order,
        crm_items,
        is_library_item,
        relevant_for_text_match
      } = playbookItem
      const itemToSave = {
        id,
        name,
        item_type,
        display_text,
        answer_choices,
        data,
        ending_item,
        note,
        required,
        collapse,
        parent,
        order,
        objections,
        crm_items,
        is_library_item,
        relevant_for_text_match
      }
      if (additional_talkscripts) {
        itemToSave.additional_talkscripts = additional_talkscripts
      }
      if (objections && objections.length > 0) {
        itemToSave.objections = objections
      }
      if (linked_field && Object.keys(linked_field).length > 0) {
        itemToSave.linked_field = { ...linked_field, crm_object_link: linked_field.crm_object_link.id }
      }
      if (itemToSave.answer_choices) {
        const answerChoices = itemToSave.answer_choices.map((item, index) => {
          return {
            id: item.id ? item.id : null,
            label: item.label,
            with_importance: !!playbookItem.with_importance,
            order: index + 1,
            actions: item.actions.map(answerAction => {
              return {
                type: answerAction.type,
                data: answerAction.data,
                order: answerAction.order
              }
            }),
            tags: item.tags
          }
        })
        itemToSave.data = { input_items: answerChoices }
      }
      return itemToSave
    },
    setImportance (items) {
      if (!items || items.length === 0) {
        return []
      }
      return items.map(item => {
        item.with_importance = item.answer_choices ? item.answer_choices.some(item => item.with_importance) : false
        return item
      })
    },
    saveObjection (objection, relatedItemId) {
      return new Promise((resolve, reject) => {
        const playbook = { name: objection.name, type: "objection" }
        if (objection.talkscript) playbook.id = objection.talkscript
        const playbookPromise = playbookServices.methods.savePlaybook(playbook)
        return playbookPromise.then(newPlaybook => {
          const newMainContainer = newPlaybook.main_container
          newMainContainer.children = objection.workflow.children

          newMainContainer.objections = []
          const mainContainerPromise = playbookServices.methods.saveMainContainer(newMainContainer)
          return mainContainerPromise.then(() => {
            objection.talkscript = newPlaybook.id
            return playbookServices.methods.saveObjectionChoice(objection, relatedItemId).then(resolve)
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    getObjectionChoice (objectionChoiceId) {
      const url = appInfo.apiUrl.objectionChoice + "/" + objectionChoiceId
      return playbookServices.httpRequestService.get(url)
    },
    saveObjectionChoice (objection, relatedItemId) {
      // save the objection choice itself
      let url = appInfo.apiUrl.objectionChoice
      let method = "post"
      if (objection.id) {
        url += "/" + objection.id
        method = "put"
      }
      let connectedPlaybookItems = objection.talkscript_items
      if (!connectedPlaybookItems) {
        connectedPlaybookItems = [relatedItemId]
      } else if (connectedPlaybookItems.indexOf(relatedItemId) < 0) {
        connectedPlaybookItems.push(relatedItemId)
      }
      return playbookServices.httpRequestService[method](url, {
        name: objection.name,
        talkscript_items: connectedPlaybookItems,
        talkscript: objection.talkscript,
        order: objection.order
      })
    },
    cloneTemplate (playbookId, newPlaybookName, targetSchema = null) {
      return new Promise((resolve, reject) => {
        const data = { id: playbookId, name: newPlaybookName }
        if (targetSchema) data.schema_name = targetSchema
        const url = appInfo.apiUrl.playbookClone
        playbookServices.httpRequestService.post(url, data).then(response => {
          resolve(response.data.id)
        }, error => {
          reject(error)
        })
      })
    },
    addNewPlaybookItem (parent, items, array) {
      /**
             * Adds the newly created items in array(playbook item list) and sends a request to backend for creation
             * if items were added in the middle of the array then, send an order-change request for the parent
             */
      return new Promise((resolve, reject) => {
        const arrayLength = array.length
        if (items[0].order > arrayLength + 1) {
          reject(new Error("Item's order out of range"))
          return
        }
        const itemCreationPromises = []
        for (const item of items) {
          itemCreationPromises.push(playbookServices.methods.saveAndAddItem(item, array))
        }
        Promise.all(itemCreationPromises).then(() => {
          if (items[0].order < arrayLength + 1) {
            return playbookServices.methods.handleOrderChanged(parent, array).then(resolve)
          } else if (items[0].order === arrayLength + 1) {
            resolve()
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    createNewPlaybookAtEnd (parent, playbookItems, isObjection = false) {
      return new Promise((resolve, reject) => {
        const itemPosition = playbookItems.length + 1
        const item = playbookServices.methods.getDefaultPlaybookItem({
          parent: parent.id,
          order: itemPosition,
          isObjection
        })
        playbookServices.methods.addNewPlaybookItem(parent, [item], playbookItems).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    saveAndAddItem (item, array) {
      /**
             * This method adds the item to playbook-items list and creates it at the backend
             * it sets the loading flag so that the user is notified of the item being created
             */
      return new Promise((resolve, reject) => {
        Vue.set(item, "loading", true)
        const itemIndex = item.order - 1
        array.splice(itemIndex, 0, item)
        item.loadingPromise = playbookServices.methods.savePlaybookItem(item).then(response => {
          Vue.set(item, "currentVersionId", playbookServices.methods.getVersionId(response.data))
          Object.assign(item, response.data)
          Vue.set(item, "loading", false)
          resolve(item)
        }).catch(error => {
          // During this request if any item was deleted, the index can change, so we find the index of the item based
          // on uniqueId and then remove it from the array
          array.splice(playbookServices.methods.findItemIndex(array, item), 1)
          Vue.set(item, "error", error)
          Vue.set(item, "loading", false)
          reject(error)
        })
      })
    },
    findItemIndex (array, item) {
      return array.findIndex(element => element.uniqueId === item.uniqueId)
    },
    getResolvedPromise () {
      return Promise.resolve()
    },
    handleSavePlaybookItem (item, delay = 10000) {
      const time = item.noRequestDelay ? 100 : delay
      return new Promise((resolve, reject) => {
        const opId = playbookServices.methods.getSaveItemOpId(item)
        // The item has to be cloned so that during the wait of delayedOperation, if item was updated with invalid data-
        // i.e., for example if the item name was made blank, then we still have the latest "validated" version of
        // the item to save
        const clonedItem = JSON.parse(JSON.stringify(item))
        if (clonedItem.clonedItem) delete clonedItem.clonedItem
        item.clonedItem = clonedItem
        // The following line is required so that if the axios request is in-progress then wait for it to complete
        // before adding new request to the queue.
        // getResolvedPromise is used for testing purpose
        const previousItemUpdatePromise = item.updateInProgress ? item.delayedUpdatePromise : playbookServices.methods.getResolvedPromise()
        // Create a pending promise for current request that is used to check if the item's update request is waiting
        // to be added to the delayed queue and resolve it when the current request is added to queue
        // This helps us to keep track of waiting promise in the queue
        let updateRequestAddedToDelayedQueue
        const waitingUpdateRequest = new Promise(function (resolve) {
          updateRequestAddedToDelayedQueue = resolve
        })
        item.waitingUpdatePromises = item.waitingUpdatePromises ? item.waitingUpdatePromises : []
        item.waitingUpdatePromises.push(waitingUpdateRequest)
        previousItemUpdatePromise.then(() => {
          item.delayedUpdatePromise = baoDelayService.methods.delayedOperation(opId,
            playbookServices.methods.saveAndUpdatePlaybookItem, item, time)
          // if the request was added to delayed Queue then resolve the "waitingUpdateRequest" promise.
          // This is needed to check if all the waiting promises were completed
          updateRequestAddedToDelayedQueue()
          return item.delayedUpdatePromise.then(() => {
            resolve()
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    saveAndUpdatePlaybookItem (item, config = { url: appInfo.apiUrl.playbookItem }) {
      return new Promise((resolve, reject) => {
        item.updateInProgress = true
        playbookServices.methods.savePlaybookItem(item, config).then((response) => {
          playbookServices.methods.semiUpdateItem(item, response.data)
          item.updateInProgress = false
          resolve(item)
        }).catch(error => {
          item.updateInProgress = false
          reject(error)
        })
      })
    },
    updateAdcIds (adcs, updatedAdcs) {
      /**
             * The newly created ADC ids in current item must be updated if the talkscript and ADC names of the updated item
             * matches with current item's Adc
             */
      for (const adc of adcs) {
        for (const updatedAdc of updatedAdcs) {
          if (adc.name === updatedAdc.name && adc.talkscript === updatedAdc.talkscript && !adc.id) {
            adc.id = updatedAdc.id
          }
        }
      }
      return adcs
    },
    semiUpdateItem (item, updatedItem) {
      /**
             * This method updates the item's id, versions, updated_at, additional_talkscripts ids and linked field id, enable
             * objectionFlag
             * If the additional_talkscripts' and linked_field ids are not updated, then everytime a new
             * additional_talkscript/linked_field is created when the item is updated
             */
      item.id = updatedItem.id
      item.versions = updatedItem.versions
      item.updated_at = updatedItem.updated_at
      item.currentVersionId = playbookServices.methods.getVersionId(updatedItem)
      if (item.additional_talkscripts.length > 0 && updatedItem.additional_talkscripts.length > 0) {
        item.additional_talkscripts = playbookServices.methods.updateAdcIds(item.additional_talkscripts, updatedItem.additional_talkscripts)
      }
      if (item.linked_field && !item.linked_field.id && updatedItem.linked_field && updatedItem.linked_field.id) {
        item.linked_field.id = updatedItem.linked_field.id
      }
    },
    savePlaybookItem (item, config = { url: appInfo.apiUrl.playbookItem }) {
      /**
             * This method updates/creates the item in the backend
             * Parameter: clonedItem - It is passed because the item might be updated when the system is waiting for the
             * the delay time and if during this delay, the item name/ crm field becomes invalid then the invalid data
             * shall be sent to backend resulting in error.
             * Parameter: item - It is used to check if the request for the item is in progress. It the update was in
             * progress then no request must be sent at the same time to avoid inconsistency
             */
      let method = "post"
      let url = config.url
      const data = playbookServices.methods.prepareItemForSave(item.clonedItem || item)
      if (item.id) {
        method = "put"
        url = url + "/" + item.id
      }
      return playbookServices.httpRequestService[method](url + "?versioned=True", data)
    },
    handleOrderChanged (item, children, delayTime = 3000) {
      return new Promise((resolve, reject) => {
        const data = {
          id: item.id,
          children: children.filter(item => !!item.id).map(item => item.id),
          objections: item.objections.filter(item => !!item.id).map(item => item.id)
        }
        const opId = playbookServices.methods.getUpdateOrderOpId(item)
        item.orderChangedPromise = baoDelayService.methods.delayedOperation(opId,
          playbookServices.methods.updateItemsOrder, data, delayTime)
          .then(() => {
            resolve()
          })
          .catch(error => {
            Vue.set(item, "error", error)
            reject(error)
          })
      })
    },
    updateItemsOrder (data) {
      return new Promise((resolve, reject) => {
        const url = "/api/talkscriptitems/" + data.id + "/update_order"
        playbookServices.httpRequestService.put(url, data).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getVersionLabel (item, versionId) {
      /**
             * Returns the version name(index + create_at field) that corresponds to the version-id which is selected in dropdown
             */
      const length = item.versions.length
      const index = item.versions.findIndex(version => version.id === versionId)
      if (index > -1) {
        return (length - index) + " - " + item.versions[index].created_at
      }
      return null
    },
    getVersionId (item) {
      /**
             * Returns the version id that corresponds to the main item's update_at
             */
      const index = item.versions.findIndex(version => version.updated_at === item.updated_at)
      if (index > -1) {
        return item.versions[index].id
      }
      return null
    },
    getSaveItemOpId (item) {
      // Not using item id because initially item has no id
      return "save-playbook-item-" + item.parent + "-" + item.uniqueId
    },
    getSavePlaybookOpId (playbook) {
      return "save-playbook" + playbook.id
    },
    getUpdateOrderOpId (item) {
      // Not using unique id because mainContainer has no uniqueId
      return "item-update-order-" + item.id
    },
    getAllPendingPromisesForItem (item) {
      // TODO. if any item update request is pending(waiting for delete to complete) then this method must also
      // get the pending promise into account.
      // On objection and answer deletion, the item update executes after the deletion request is completed from
      // backend, during the deletion request if this method is called it doesnt wait for that item-update request to
      // complete hence, add it to the queue waitingPromiseQueue
      /**
             *  Returns all the pending promises for the item
             */
      const waitingPromises = []
      const opId = playbookServices.methods.getSaveItemOpId(item)
      if (item.loading) {
        // if loading, the item is being created or being updated to a version, then wait for item to finish loading
        waitingPromises.push(item.loadingPromise)
      } else if (item.updateInProgress) {
        // if updateInProgress, the item is being updated(request is sent) and waiting for the server to respond, then wait
        // for update operation to complete and also wait for all operations that were waiting to be put in the queue
        // to execute, then immediately execute it once they are all in the queue
        const promise = playbookServices.methods.waitAndExecuteAllOperations(item, opId)
        waitingPromises.push(promise)
      } else {
        // the operation is not yet executed because the it is waiting for 10 secs delay time so execute it immediately
        waitingPromises.push(baoDelayService.methods.executeDelayedOperation(opId))
      }
      // TODO: Order change on item never happens. It is currently not needed
      // Execute the order update promise immediately if in delayed queue and add it to list
      const updateOrderOpId = playbookServices.methods.getUpdateOrderOpId(item)
      waitingPromises.push(baoDelayService.methods.executeDelayedOperation(updateOrderOpId))
      return waitingPromises
    },
    waitAndExecuteAllOperations (item, opId) {
      return item.delayedUpdatePromise.then(() => {
        Promise.all(item.waitingUpdatePromises).then(() => {
          item.waitingUpdatePromises = []
          baoDelayService.methods.executeDelayedOperation(opId)
        })
      })
    },
    getAllPendingPromisesForPlaybook (playbook, parent, playbookItems) {
      /**
             * Returns all the pending promises for all the items and mainContainer in the playbook
             */
      const pendingPromises = []
      for (const item of playbookItems) {
        pendingPromises.push(...playbookServices.methods.getAllPendingPromisesForItem(item))
      }
      // Execute the parent's order update promise immediately if in delayed queue and add it to list
      const updateOrderOpId = playbookServices.methods.getUpdateOrderOpId(parent)
      pendingPromises.push(baoDelayService.methods.executeDelayedOperation(updateOrderOpId))

      // Execute the playbook name change promise immediately if in delayed queue and add it to list
      if (playbook) {
        const playbookOpId = playbookServices.methods.getSavePlaybookOpId(playbook)
        pendingPromises.push(baoDelayService.methods.executeDelayedOperation(playbookOpId))
      }
      return pendingPromises
    },
    executeAllOperationsForPlaybook (playbook, parent, playbookItems) {
      /**
             * Waits(if necessary in-case the request was already sent to backend) and executes all the operations
             * (delayed/waiting/in-progress) on all the playbook items and mainContainer of the playbook
             */
      const pendingPromisesForPlaybook = playbookServices.methods.getAllPendingPromisesForPlaybook(playbook, parent, playbookItems)
      return Promise.all(pendingPromisesForPlaybook)
    },
    deleteObjection (objection, item) {
      return new Promise((resolve, reject) => {
        playbookServices.httpRequestService.delete("/api/objectionchoices/" + objection.id + "?playbook_item=" + item.id).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    extractErrorMessage (error) {
      if (error && error.response) {
        if (error.response.status >= 500) return error
        if (error.response.data && error.response.data.detail) return error.response.data.detail
        return JSON.stringify(error.response.data)
      }
      return error
    },
    linkPlaybookItem (selectedLibraryItem, mainContainer, itemPosition) {
      return new Promise((resolve, reject) => {
        this.$set(selectedLibraryItem, "loading", true)
        playbookServices.httpRequestService.put(`${appInfo.apiUrl.playbookItem}/${mainContainer.id}/link_child`,
          { child: selectedLibraryItem.id, order: itemPosition + 1 }).then(response => {
          this.$set(selectedLibraryItem, "loading", false)
          resolve()
        }).catch(error => {
          this.$set(selectedLibraryItem, "loading", false)
          reject(error)
        })
      })
    }
  }

}
