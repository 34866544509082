<template>
  <div class="bao-widget-filter d-flex">
    <div class="filter-body w-100">
      <label
        id="label-duration-filter-202112211245"
        for="target-input"
        class="w-100 d-flex"
      >
        {{ staticText.durationLabel }}
        <span
          v-if="required"
          class="required-icon"
        >*</span>
      </label>
      <div class="d-flex align-items-end justify-content-center">
        <div class="w-50 p-1 inner-label-form">
          <label
            for="minutes-input-202207171246"
            class="inner-label"
          >
            {{staticText.minutes}}
          </label>
          <b-form-input
            id="minutes-input-202207171246"
            class="mr-1"
            v-model="durationMinutes"
            type="number"
            min=0
            oninput="validity.valid||(value=1)"
            @input="inputValue=>$emit('input', (+inputValue) * 60 + (+durationSeconds))"
          ></b-form-input>
        </div>

        <div class="w-50 p-1 inner-label-form">
          <label
            for="seconds-input-202207171246"
            class="inner-label"
          >
            {{staticText.seconds}}
          </label>
          <b-form-input
            id="seconds-input-202207171246"
            class="ml-1"
            v-model="durationSeconds"
            type="number"
            min=0
            oninput="validity.valid||(value=0)"
            @input="inputValue=>$emit('input', (+durationMinutes) * 60 + (+inputValue))"
          ></b-form-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "DurationClassificator",
  components: { },
  props: {
    value: { // duration in seconds
      type: Number,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        durationLabel: "Call Duration (minutes + seconds)",
        minutes: "Minutes",
        seconds: "Seconds"
      },
      durationMinutes: Math.floor(this.value / 60),
      durationSeconds: this.value % 60
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    validateFilter () {
      if (!this.durationMinutes) this.durationMinutes = 0
      if (!this.durationSeconds) this.durationSeconds = 0
      return true
    }
  }
}
</script>
