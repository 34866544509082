<template>
  <b-modal :ref="id"
           :id="id"
           v-model="showWarning"
           :ok-only="okOnly"
           :title="getTitle"
           :hide-header-close="hideHeaderClose"
           :no-close-on-esc="noCloseOnEsc"
           :no-close-on-backdrop="noCloseOnBackdrop"
           hide-footer
  >
    <div class="d-flex justify-content-center align-items-center mt-3 mb-5">
      <img id="alert-icon-202103210125"
           src="/img/icons/round-exclamation-btn.svg"
           height="50px"
           class="mr-3"
      />

      <div>
        <slot id="slot-202103210125"
              v-if="slotPassed"
        ></slot>
        <span id="local-warning-message-202103210125"
              v-else
        >
          {{staticText.warningMessage}}
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <b-btn id="modal-cancel-btn-202103051221"
             variant="primary"
             class="font-size-14 px-4 mr-3"
             @click="cancel"
      >
        {{ staticText.cancel }}
      </b-btn>

      <b-btn id="modal-delete-btn-202103051221"
             variant="secondary"
             class="font-size-14 px-4"
             @click="deleteItem"
      >
        {{ staticText.delete }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "BaoDeleteConfirmModal",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "",
      required: false
    },
    okOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    noCloseOnBackdrop: {
      type: Boolean,
      required: false,
      default: false
    },
    hideHeaderClose: {
      type: Boolean,
      required: false,
      default: false
    },
    noCloseOnEsc: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showWarning: false,
      staticTextDefault: {
        title: "Attention!",
        cancel: "Cancel",
        delete: "Delete",
        warningMessage: "This object and all of its data will be permanently deleted."
      }
    }
  },
  computed: {
    slotPassed () {
      return !!this.$slots.default
    },
    getTitle () {
      return this.title ? this.title : this.staticText.title
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    showWarning () {
      this.$emit("input", this.showWarning)
    },
    value () {
      this.showWarning = this.value
    }
  },
  mounted () {
    this.showWarning = this.value
  },
  methods: {
    cancel () {
      this.showWarning = false
      this.$emit("cancel")
    },
    deleteItem () {
      this.showWarning = false
      this.$emit("delete")
    }
  }
}
</script>

<style scoped lang="scss">

</style>
