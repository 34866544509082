import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  createdDateArray,
  getDefaultLineChartOptions
} from "@/apps/dashboard/chartUtils"
import moment from "moment"
import { defaultLineDatasetValues } from "@/apps/dashboard/chartConfig"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const LineChart = () => import(/* webpackChunkName: "LineChart" */ "../BaoWidgetsBaseComponents/LineChart")

export default {
  name: "BaoWidgetCallsPerDay",
  info: {
    title: "Call activity per day",
    description: "How many conversations did my team have per day this month? How actively did they pursue this month’s goals?"
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getDefaultLineChartOptions,
      createdDateArray,
      chartComponent: LineChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay
    }
  },
  methods: {
    mappingFunc (items) {
      const dateArray = this.createdDateArray(moment().startOf("month"), "day", moment().endOf("month"))
      items = dateArray.map(date => {
        const result = { text: date.toLocaleString("default", { month: "short", day: "numeric" }), count: 0 }
        const data = items.filter(item => item.created_at__date === moment(date).format("YYYY-MM-DD"))
        if (data && data.length > 0) result.count = data[0].count
        return result
      })
      const result = {}
      result.labels = items.map(item => item.text)
      result.datasets = [{
        ...defaultLineDatasetValues,
        data: items.map(item => Math.round(item.count * 100) / 100)
      }]
      return result
    },
    getChartOptions () {
      return this.getDefaultLineChartOptions(this.widget, {})
    },
    getConfigOptions () {
      return [{
        type: "filterOptions",
        blacklist: ["timeframe"] // TODO: decide on filters
      }]
    },
    getDisplayProps () {
      return { widget: this.widget }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
