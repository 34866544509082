import { debug } from "@/utils/utils"

export default {
  registerUser (userData) {
    try {
      if (!pendo) return
      pendo.initialize({
        visitor: {
          ...userData,
          id: userData.email, // Required if user is logged in
          email: userData.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: userData.full_name // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: userData.client_schema_name, // Required if using Pendo Feedback
          name: userData.client_name // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        }
      })
    } catch (e) {
      debug.log(e)
    }
  }
}
