import axios from "axios"

export const realTimeAssistantService = {
  transcriptUrl: "/api/transcripts/",
  callTranscriptUrl: "/api/calltranscripts/",
  callItemTranscriptURL: "/api/callitemtranscripts/",

  methods: {
    saveTranscripts (dataToSave) {
      /**
       * saves raw transcript data, calculated transcript data for call and call items to database
       * params: transcripts
       * optional params: call_item_transcript, call_transcript
       */
      return axios.post(realTimeAssistantService.transcriptUrl, dataToSave)
    },
    saveCallItemTranscripts (dataToSave) {
      /**
       * saves calculated transcript data for call items to database
       * params: call_item_transcript
       */
      return axios.post(realTimeAssistantService.callItemTranscriptURL, dataToSave)
    },
    getTranscripts (callId) {
      /**
       * retrieves raw transcripts data for call items of a call
       * params: call_id
       */
      return this._retrieveData(realTimeAssistantService.transcriptUrl, { call_item__call__id: callId })
    },
    getTranscriptSummary (callId) {
      /**
       * retrieves summary data for provided call and it's call items
       * params: call_id
       * return:  call_transcript, call_item_transcript for call items of call
       */
      return this._retrieveData(realTimeAssistantService.callTranscriptUrl, { call_id: callId })
    },
    _retrieveData (url, params) {
      return new Promise((resolve, reject) => {
        axios.get(url, { params }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
