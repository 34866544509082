import axios from "axios"

const state = {
  teamTreeForUser: [],
  errorMessage: null,
  successMessage: null,
  teamsToReload: null
}

const getters = {
  getErrorMessage (state) {
    return state.errorMessage
  },
  getSuccessMessage (state) {
    return state.successMessage
  },
  getTeamsToReload (state) {
    return state.teamsToReload
  },
  getTeamTreeForUser (state) {
    return state.teamTreeForUser
  },
  currentUser (state, getters, rootState, rootGetters) {
    return rootGetters["auth/user"]
  },
  multipleTeamsExist (state) {
    if (state.teamTreeForUser.length > 0) {
      return state.teamTreeForUser[0].children.length > 0
    }
    return false
  }

}

const mutations = {
  setErrorMessage (state, value) {
    state.errorMessage = value
  },
  setSuccessMessage (state, value) {
    state.successMessage = value
  },
  setError (state, value) {
    state.error = value
  },
  setTeamTreeForUser (state, value) {
    state.teamTreeForUser = value
  },
  setTeamsToReload (state, value) {
    // value expected array of team IDs
    state.teamsToReload = value
  }
}

const actions = {
  getTeamTreeForUser ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/teams/get_current_team_tree").then(response => {
        const teamTree = response.data
        teamTree[0].open = true
        commit("setTeamTreeForUser", teamTree)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  createNewTeam ({ dispatch }, payload) {
    /**
     *  payload: serializable tenant Object with valid attributes.
     *  - required attributes: name, parent
     *  - optional attributes: users, copy_user_settings
     *  returns: Serialized data Array of teams and corresponding Array of users nested within.
    * */
    return new Promise((resolve, reject) => {
      // this can take upto two minutes therefore timeout of 2 minutes is set.
      axios.post("/api/teams", payload).then(response => {
        dispatch("getTeamTreeForUser") // refresh team tree
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateTeam ({ dispatch }, team) {
    /**
     *  team: serializable tenant Object with valid attributes.
     *  - required attributes: id, name
     *  - optional attributes: users
     *  returns: Serialized data Array of teams and corresponding Array of users nested within.
    * */
    return new Promise((resolve, reject) => {
      axios.put(`/api/teams/${team.id}`, team).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteTeam ({ dispatch, commit }, team) {
    /**
     *  team: serializable tenant Object with valid attributes.
     *  - required attributes: id
     *  returns: Serialized data Array of teams and corresponding Array of users nested within.
    * */
    return new Promise((resolve, reject) => {
      axios.delete(`/api/teams/${team.id}`).then(response => {
        dispatch("getTeamTreeForUser") // refresh team tree
        resolve(response.data)
      }).catch(err => {
        commit("setError", err.error)
        reject(err)
      })
    })
  },
  getMembersForTeams ({ commit }, teams) {
    /**
     *  teams: Array of tenant ids.
     *  returns: Serialized data Array of teams and corresponding Array of users nested within.
    * */
    return new Promise((resolve, reject) => {
      axios.get("/api/teams/get_team_members", { params: { tenants: teams } }).then(response => {
        resolve(response.data)
      }).catch(err => {
        commit("setError", err.error)
        reject(err)
      })
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
