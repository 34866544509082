<template>
  <div class="w-100 h-100 d-flex flex-column">
    <div class="d-flex justify-content-between px-5 py-3 border-b-slate20">
      <div>
        <div class="d-flex align-items-center">
          <img src="/img/icons/vector.svg"/>
          <div class="font-size-18 ml-2">
            {{ widgetToConfigure.title }}
          </div>
        </div>
        <div class="h-32px pl-2">
          <div v-if="!renaming"
               :class="!widgetToConfigure.name?'opacity-50':''"
               class="subtitle"
               @click="renaming=true"
          >
            {{ getSubtitle }}
          </div>
          <b-form-input
            v-else
            v-model="widgetToConfigure.name"
            :placeholder="staticText.customNamePlaceholder"
            class="input-field-sm"
            autofocus
            @blur="renaming=false"
          ></b-form-input>
        </div>
      </div>
      <div class="d-flex p-2">
        <b-btn variant="secondary"
               @click="handleCloseAction"
               class="mr-3 px-4"
        >
          {{ staticText.cancel }}
        </b-btn>
        <b-btn variant="primary"
               @click="validateFiltersAndApplyChanges"
               class="px-4"
        >
          {{ staticText.saveLabel }}
        </b-btn>
      </div>
    </div>

    <div class="px-5 h-100 d-flex flex-column">
      <div class="row h-100">
        <div class="col-md-6 min-h-100 py-3 border-r-slate20">
          <b-form-group
            class="w-100"
          >
            <label
              :for="'widget-description-input'+ widgetToConfigure.id"
              class="d-flex align-items-center"
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:id="'widget-description-info-icon'" v-bind:class="'mr-2 info-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
              <b-tooltip
                target="widget-description-info-icon"
                :title="staticText.widgetDescriptionInfo"
                placement="bottom"
              ></b-tooltip>
              <span>{{ staticText.widgetDescription }}</span>
            </label>
            <b-form-textarea
              :id="'widget-description-input'+ widgetToConfigure.id"
              v-model="widgetToConfigure.notes"
              :placeholder="staticText.writeDescriptionLabel"
              class="border-slate20 overflow-hidden"
              max-rows="6"
              rows="3"
              maxlength="300"
            ></b-form-textarea>
          </b-form-group>

          <div class="d-flex justify-content-end mb-2">
            <b-btn
              variant="primary"
              :class="[
                'px-4 widget-refresh-button',
                { visible: showRefreshButton }
              ]"
              @click="validateFiltersAndPreviewWidget"
            >
              {{ staticText.refreshLabel }}
            </b-btn>
          </div>

          <component
            :is="widget.component"
            v-bind="{
              widget: widgetToConfigure,
              previewMode: true
            }"
          ></component>
        </div>

        <div class="col-md-6 py-3">
          <!--Forms for config component -->

          <!-- Form for settings -->
          <widget-settings
            :ref="`settingsConfigurations-${widget.id}`"
            :configuration="{
              widget,
              configOptions
            }"
            @changed="settingsChanged"
          >
          </widget-settings>

          <!--Form for filters -->
          <filter-configurations
            :ref="`filterConfigurations-${widget.id}`"
            :widget="widget"
            :config-options="configOptions"
            @input="filtersChanged"
          >
          </filter-configurations>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapActions, mapMutations } from "vuex"
import WidgetSettings from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BaoWidgetSettings"
import FilterConfigurations
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/FilterConfigurations"

export default {
  name: "BaoWidgetConfigurator",
  components: { WidgetSettings, FilterConfigurations },
  props: {
    widget: {
      type: Object,
      required: true
    },
    configOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        cancel: "Cancel",
        saveLabel: "Save",
        subtitle: "Click to set your custom name",
        customNamePlaceholder: "Custom Name",
        widgetDescription: "Description",
        addWidget: "Add Widget",
        writeDescriptionLabel: "Write a description",
        widgetDescriptionInfo: "The description will be shown on the dashboard when hovering the info-icon of a widget",
        refreshLabel: "Refresh",
        errorLabel: "Error",
        previewErrorMessage: "Could not refresh the preview.",
        saveErrorMessage: "Changes could not be saved."
      },
      renaming: false,
      widgetToConfigure: null,
      canSaveChanges: true,
      showRefreshButton: false
    }
  },
  computed: {
    getSubtitle () {
      return this.widgetToConfigure.name || this.staticText.subtitle
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  created () {
    this.widgetToConfigure = JSON.parse(JSON.stringify(this.widget))
  },
  mounted () {
    this.previewWidget(this.widgetToConfigure)
  },
  methods: {
    ...mapMutations({
      setWidgetToConfigure: "dashboardStore/setWidgetToConfigure"
    }),
    ...mapActions({
      closeWidgetSidebar: "dashboardStore/closeWidgetSidebar",
      previewWidget: "dashboardStore/previewWidget"
    }),
    validateFiltersAndApplyChanges () {
      this.hideGlobalToast()
      if (this.validateConfigurations()) {
        this.$emit("update", this.widgetToConfigure)
      } else {
        this.showErrorToast(this.staticText.saveErrorMessage)
      }
    },
    filtersChanged (newFilterConfig, requiredFieldsUnanswered) {
      const currentConfig = this.widgetToConfigure.config || {}
      currentConfig.filters = newFilterConfig.filters
      this.configChanged(currentConfig, requiredFieldsUnanswered)
    },
    settingsChanged (newSettings, requiredFieldsUnanswered) {
      const newConfig = { ...this.widgetToConfigure.config, ...newSettings }
      this.configChanged(newConfig, requiredFieldsUnanswered)
    },
    configChanged (config, requiredFieldsUnanswered) {
      this.canSaveChanges = !requiredFieldsUnanswered
      this.$set(this.widgetToConfigure, "config", config)
      this.showRefreshButton = true
    },
    validateFiltersAndPreviewWidget () {
      this.hideGlobalToast()
      if (this.validateConfigurations()) {
        this.previewWidget(this.widgetToConfigure)
      } else {
        this.showErrorToast(this.staticText.previewErrorMessage)
      }
      this.showRefreshButton = false
    },
    validateConfigurations () {
      const filterRefLabel = `filterConfigurations-${this.widget.id}`
      const settingsRefLabel = `settingsConfigurations-${this.widget.id}`
      const filtersValidated = this.$refs[filterRefLabel].validateAllFilters()
      const settingsValidated = this.$refs[settingsRefLabel].validateAllFilters()
      return filtersValidated && settingsValidated
    },
    handleCloseAction () {
      if (!this.widgetToConfigure.id) {
        this.setWidgetToConfigure(null)
      } else {
        this.closeWidgetSidebar()
      }
    },
    showErrorToast (description) {
      const errorMessage = {
        title: this.staticText.errorLabel,
        description
      }
      this.showGlobalToast({
        status: "error",
        message: errorMessage
      })
    }
  }
}

</script>

<style scoped lang="scss">
.h-32px {
  height: 32px;
}

.h-70vh {
  height: 70vh;
}

.h-80vh {
  height: 80vh;
}

.input-field-sm {
  max-width: 189px;
  background: $slate06;
  font-size: 14px;
  height: 28px;
}

.input-field-sm:focus {
  background: $slate06;
}

.subtitle {
  font-size: 14px;
  padding: 4px 16px;
}

.opacity-50 {
  opacity: 0.5;
}

.border-b-slate20 {
  border-bottom: 1px solid $slate20;
}

.border-r-slate20 {
  border-right: 1px solid $slate20;
}

.border-slate20 {
  border: 1px solid $slate20;
}

.info-icon {
  cursor: pointer;
  &:hover {
    path {
      transition: 0.5s ease-in;
      fill: $slate;
    }
  }
}

.min-h-100 {
  min-height: 100%;
}

.widget-refresh-button {
  visibility: hidden;
  opacity: 0;
  transition: 0.8s ease-in-out;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

</style>
