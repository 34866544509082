<template>
  <div>
    <!--click.stop will stop click event from propagating or "bubbling" up to the parent element.-->
    <b-button :id="id"
              :ref="id"
              :disabled="disabled"
              :size="size"
              :variant="variant"
              :class="getClass"
              @click.stop="handleDeleteButtonClicked"
    >
      <i
        v-if="!hideIcon"
        :id="'delete-icon-202103211433-'+id"
        :class=icon
      ></i>
      <span :id="'delete-label-202103211433-'+id"
            v-if="!!label"
      >
        {{ label }}
      </span>
    </b-button>

    <b-tooltip :id="'delete-tooltip-202103211433-'+id"
               :ref="'delete-tooltip-202103211433-'+id"
               v-if="!label"
               :target="id"
               :delay="tooltipDelay"
               :placement="tooltipPlacement"
               :noninteractive="noninteractiveTooltip"
    >
      {{ getTooltip }}
    </b-tooltip>

    <bao-delete-confirm-modal :id="'warning-modal-202012241138-'+id"
                              v-model="showDeleteWarningModal"
                              @delete="deleteItem"
    >
      <slot></slot>
    </bao-delete-confirm-modal>
  </div>
</template>

<script>
import { tooltipDelay } from "@/config"
import BaoDeleteConfirmModal from "@/apps/base/BaoDeleteConfirmModal"
import axios from "axios"
import { mapMutations } from "vuex"

export default {
  name: "BaoDeleteButton",
  components: {
    BaoDeleteConfirmModal
  },
  props: {
    id: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: "fa fa-trash",
      required: false
    },
    label: {
      type: String,
      default: "",
      required: false
    },
    tooltip: {
      type: String,
      default: "",
      required: false
    },
    tooltipPlacement: {
      type: String,
      default: "right",
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    size: {
      type: String,
      default: "md",
      required: false
    },
    url: {
      type: String,
      default: null,
      required: false
    },
    showConfirm: {
      // determines if the confirm modal is shown or not, if not the delete operation is executed immediately
      type: Boolean,
      default: true,
      required: false
    },
    variant: {
      type: String,
      default: "secondary",
      required: false
    },
    extraClass: {
      type: String,
      default: "action-button",
      required: false
    },
    noninteractiveTooltip: {
      type: Boolean,
      default: false,
      required: false
    },
    hideIcon: {
      type: Boolean
    }
  },
  data () {
    return {
      axios,
      tooltipDelay,
      showDeleteWarningModal: false,
      staticTextDefault: {
        tooltip: "Delete"
      }
    }
  },
  computed: {
    getClass () {
      return this.extraClass
    },
    getTooltip () {
      return this.tooltip ? this.tooltip : this.staticText.tooltip
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...mapMutations({
      setError: "globalError/setError"
    }),
    handleDeleteButtonClicked () {
      if (this.showConfirm) {
        this.showDeleteWarningModal = true
        this.$emit("modal-shown")
        return
      }
      this.deleteItem()
    },
    deleteItem () {
      this.$emit("delete")
      if (!this.url) return
      return new Promise((resolve, reject) => {
        this.axios.delete(this.url).then(() => {
          this.$emit("deleted")
          resolve()
        }).catch(error => {
          this.setError(error)
          this.$emit("error", error)
          this.showGlobalToast({
            status: "error",
            message: { title: "Something went wrong", description: "Error in deleting item." }
          })
          reject(error)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
