<template lang="html">
  <router-view />
</template>

<script>
import CallSetupMixin from "@/apps/call/CallSetupMixin"
import { mapActions } from "vuex"

export default {
  name: "App",
  mixins: [CallSetupMixin],
  mounted () {
    if (this.isApplicationInIFrame) {
      // This event listener is to listen to events from the browser extension
      window.addEventListener("message", (event) => this.handleBaoSwiftMessages(event))
    }
  },
  methods: {
    ...mapActions({
      assignCounterpartFromUrl: "callSummaryStore/assignCounterpartFromUrl",
      resetAndRefetchCounterpartFromUrl: "callSetUpStore/resetAndRefetchCounterpartFromUrl"
    }),
    handleBaoSwiftMessages (event) {
      const messageData = event.data
      const isDomainAllowed = this.checkIfParentDomainIsAllowed(event.origin)

      if (isDomainAllowed) {
        this.handleSpecificBaoSwiftMessages(messageData)
      }
    },
    handleSpecificBaoSwiftMessages (messageData) {
      switch (messageData.type) {
        case "quick_start_call":
          this.handleQuickStartTriggered(messageData)
          break

        case "reload_application":
          window.location.reload()
          break

        case "send_host_url":
          this.handleSwiftHostUrlMessage(messageData)
          break
      }
    },
    handleSwiftHostUrlMessage (messageData) {
      const counterpartParams = messageData.counterpartParams
      if (counterpartParams) {
        if (messageData.sender === "call_setup") {
          this.resetAndRefetchCounterpartFromUrl(counterpartParams)
        } else if (messageData.sender === "call_summary") {
          this.assignCounterpartFromUrl({ counterpartParams, callId: this.$route.params.callId })
        }
      }
    }
  },
  beforeDestroy () {
    if (this.isApplicationInIFrame) window.removeEventListener("message", (event) => this.handleBaoSwiftMessages(event))
  }
}
</script>
