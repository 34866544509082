import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetPlaybookConversionChart from "./BaoWidgetPlaybookConversionChart"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

export default {
  name: "BaoWidgetPlaybookConversion",
  info: {
    title: "Conversion",
    description: "Shows how many calls lead to success (conversion)."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      chartComponent: BaoWidgetPlaybookConversionChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      staticTextDefault: {
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showGoToConversationsButton () {
      return !!(this.chartData && this.chartData.success_calls && Number(this.chartData.success_calls) > 0)
    }
  },
  methods: {
    mappingFunc (data) {
      return data
    },
    getChartOptions () {
      return null
    },
    getConfigOptions () {
      return [
        {
          type: "criteriaOptions",
          value: { type: "callResult" },
          options: ["callResult", "callDuration", "answer"],
          fieldName: "successCriteria"
        }
      ]
    },
    getDisplayProps () {
      return { widget: this.widget, showConversationsView: this.showGoToConversationsButton }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
