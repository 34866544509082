import axios from "axios"
import { getBaoUserFromStorage } from "@/utils/authenticationManager"

const state = {
  i18n: []
}

const getters = {
  getI18n: (state, getters, rootState) => (view, staticText) => {
    const filteredDataByView = state.i18n.reduce(function (data, item) {
      if (item.view === view) {
        data[item.key] = item.text
      }
      return data
    }, {})

    const errors = {}
    const data = {}
    for (const key in staticText) {
      if (!filteredDataByView[key]) {
        // assign the default static value if not present in backend
        data[key] = staticText[key]
        errors[key] = staticText[key]
        continue
      }
      data[key] = filteredDataByView[key]
      delete filteredDataByView[key]
    }
    for (const key in filteredDataByView) {
      errors[key] = null
    }
    if (
      Object.keys(errors).length &&
      rootState.auth.user &&
      rootState.auth.user.language !== "en"
    ) {
      console.log("Unable to retrieve these fields from " + view + ":" + "\n")
      console.error(errors)
    }
    return data
  }
}

const mutations = {
  setI18n (state, data) {
    state.i18n = data
  }
}

const actions = {
  async getI18nData (context) {
    const i18nUrl = "/api/i18n/"
    if (getBaoUserFromStorage()) {
      return axios.get(i18nUrl).then(response => {
        context.commit("setI18n", response.data)
      })
    }
    return Promise.reject(new Error("Not logged in")).catch(() => {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
