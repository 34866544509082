<template>
  <base-filter
    :value="options.value"
    :label="displayLabel"
    :showLabel="false"
    :url="getUrl"
    :multiselect="options.multiselect"
    :required="options.required"
    :hasError="hasError"
    @input="handleValueChanged"
  ></base-filter>
</template>

<script>
import BaseFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BaseFilter"
import {
  WidgetConfigOptions,
  LIBRARY_ITEM_TYPE_LABEL,
  NON_LIBRARY_ITEM_TYPE_LABEL,
  HUBSPOT_CALL_OUTCOME_ITEM_TAG
} from "@/apps/dashboard/chartUtils"

export default {
  name: "PlaybookItemSelector",
  components: { BaseFilter },
  props: {
    options: {
      type: WidgetConfigOptions,
      required: true
    },
    selectedPlaybooks: { // playbooks selected for filtering
      type: Array,
      required: false,
      default: () => []
    },
    playbookItemType: {
      type: String,
      required: false,
      default: NON_LIBRARY_ITEM_TYPE_LABEL
    }
  },
  data () {
    return {
      selectedPlaybookItems: [],
      hasError: false,
      staticTextDefault: {
        selectPlaybookItems: "Playbook items"
      }
    }
  },
  mounted () {
    this.selectedPlaybookItems = this.options.value || []
  },
  computed: {
    displayLabel () {
      return this.options.label || this.staticText.selectPlaybookItems
    },
    getPlaybookFilter () {
      // hubspot call outcome library items are hidden from filter list for now
      // TODO: update this URL query params if we need to use filter for those items as well
      return this.playbookItemType === NON_LIBRARY_ITEM_TYPE_LABEL
        ? `&parents__talkscript__id__in=${this.selectedPlaybooks}&tags__name!=${HUBSPOT_CALL_OUTCOME_ITEM_TAG}`
        : ""
    },
    getUrlSuffix () {
      // hubspot call outcome library items are hidden from filter list for now
      // TODO: update this URL query params if we need to use filter for those items as well
      return this.playbookItemType === LIBRARY_ITEM_TYPE_LABEL
        ? `&is_library_item=true&tags__name!=${HUBSPOT_CALL_OUTCOME_ITEM_TAG}`
        : ""
    },
    getUrl () {
      return `/api/talkscriptitems/get_analytics_view?${this.getPlaybookFilter}${this.getUrlSuffix}`
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    handleValueChanged (newValue) {
      this.selectedPlaybookItems = newValue
      this.$emit("input", this.selectedPlaybookItems)
      if (this.selectedPlaybookItems.length > 0) this.validateFilter()
    },
    validateFilter () {
      this.hasError = !this.selectedPlaybookItems.length
      return !this.hasError
    }
  }
}
</script>

<style scoped>

</style>
