import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  getDefaultHorizontalBarChartOptions, getUsername
} from "@/apps/dashboard/chartUtils"
import { defaultHorizontalDatasetValues } from "@/apps/dashboard/chartConfig"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const HorizontalBarChart = () => import(/* webpackChunkName: "HorizontalBarChart" */ "../BaoWidgetsBaseComponents/HorizontalBarChart")

export default {
  name: "BaoWidgetCallsPerUser",
  info: {
    title: "Call activity per user",
    description: "How many conversations did each user in my team have this month? How active were the users in pursuing the team’s goals this month?"
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getDefaultHorizontalBarChartOptions,
      getUsername,
      chartComponent: HorizontalBarChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay
    }
  },
  methods: {
    mappingFunc (items) {
      items = items.sort((a, b) => b.count_id - a.count_id).splice(0, 10)
      const labels = []
      const data = []
      for (const item of items) {
        labels.push(this.getUsername(item, "created_by"))
        data.push(item.count_id)
      }
      return {
        labels,
        datasets: [{ ...defaultHorizontalDatasetValues, data }]
      }
    },
    getChartOptions () {
      return this.getDefaultHorizontalBarChartOptions(this.widget, this)
    },
    getConfigOptions () {
      return []
    },
    getDisplayProps () {
      return { widget: this.widget }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
