<template>
  <div v-if="chartData && chartOptions">

    <div v-if="widget.chartType === 'number'">
      <v-runtime-template :template="widget.component"></v-runtime-template>
    </div>

    <bar v-if="widget.chartType === 'bar'"
         :chart-data="chartData"
         :options="chartOptions"
         :chart="widget"
         :styles="chartStyle"
    ></bar>

    <horizontal-bar v-if="widget.chartType === 'horizontalbar'"
                    :chart-data="chartData"
                    :chart-options="chartOptions"
                    :styles="chartStyle"
    ></horizontal-bar>

    <line-chart v-if="widget.chartType === 'line'"
                :chart-data="chartData"
                :chart-options="chartOptions"
                :styles="chartStyle"
    ></line-chart>

    <b-card-footer v-if="chartFilterComponentsExist">
      <div v-for="(item, index) in chartFilterComponents"
           :key="index"
           class="row"
      >

        <div class="col-3">{{item.label}}</div>

        <div class="col">
          <v-runtime-template :template="item.component"></v-runtime-template>
        </div>

      </div>
    </b-card-footer>

  </div>
</template>

<script>
import axios from "axios"
import VRuntimeTemplate from "v-runtime-template"

export default {
  name: "BaoDashboardChart",
  components: {
    VRuntimeTemplate,
    LineChart: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "LineChart" */ "./LineChart"),
    Bar: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "BarChart" */ "./BarChart.js"),
    HorizontalBar: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "HorizontalBarChart" */ "./HorizontalBarChart.js")
  },
  props: {
    widget: Object
  },
  data () {
    return {
      axios,
      chartData: null,
      horizontChartData: null,
      chartOptions: null,
      filters: {},
      loading: false
    }
  },
  computed: {
    chartFilterComponents () {
      return Object.values(this.widget.filter).filter(item => !!item.component)
    },
    chartFilterComponentsExist () {
      return this.widget.filter && this.chartFilterComponents.length > 0
    },
    chartStyle () {
      return {
        height: `${window.innerHeight / 3}px`,
        position: "relative"
      }
    }
  },
  watch: {
    filters: {
      handler (val, oldVal) {
        if (val !== oldVal) this.calculateChartData()
      },
      deep: true
    },
    loading (val) {
      this.$emit("loading-changed", val)
    }
  },
  mounted () {
    if (this.widget.filter) {
      for (const i in this.widget.filter) {
        const filter = this.widget.filter[i]
        this.$set(this.filters, filter.field, filter.value)
      }
    }
    this.calculateChartData()
  },
  methods: {
    getCompleteUrl () {
      let completeUrl = this.widget.url
      if (this.widget.filter) {
        for (const i in this.filters) {
          const filterField = i
          let filterValue = this.filters[i]
          if (this.widget.filter[i].transform) filterValue = this.widget.filter[i].transform(filterValue)
          completeUrl += "&" + filterField + "=" + filterValue
        }
      }
      return completeUrl
    },
    calculateChartData () {
      if (this.widget.data) this.setChartData(this.widget.data)
      const completeUrl = this.getCompleteUrl()
      const that = this
      that.loading = true
      that.axios.get(completeUrl).then(async response => {
        that.setChartData({
          data: await this.widget.mappingFunc(response.data.data),
          options: this.widget.getOptions()
        })
      })
    },
    setChartData (data) {
      this.chartData = data.data
      this.chartOptions = data.options
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
