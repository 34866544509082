import { render, staticRenderFns } from "./BaoWidgetDisplay.vue?vue&type=template&id=1fb7a1f7&scoped=true&"
import script from "./BaoWidgetDisplay.vue?vue&type=script&lang=js&"
export * from "./BaoWidgetDisplay.vue?vue&type=script&lang=js&"
import style0 from "./BaoWidgetDisplay.vue?vue&type=style&index=0&id=1fb7a1f7&prod&lang=scss&"
import style1 from "./BaoWidgetDisplay.vue?vue&type=style&index=1&id=1fb7a1f7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb7a1f7",
  null
  
)

export default component.exports