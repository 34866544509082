import { render, staticRenderFns } from "./BaoDeleteConfirmModal.vue?vue&type=template&id=40cb03b3&scoped=true&"
import script from "./BaoDeleteConfirmModal.vue?vue&type=script&lang=js&"
export * from "./BaoDeleteConfirmModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40cb03b3",
  null
  
)

export default component.exports