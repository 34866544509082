<template>
  <div>
    <div class="d-flex mb-2 align-items-center">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.681 1.151C.821.814 1.118.6 1.444.6h9.114c.326 0 .622.214.761.551.141.337.097.735-.127 1.024l-3.841 5.39v3.064c0 .291-.144.559-.374.689a.606.606 0 01-.706-.072l-1.35-1.158a.808.808 0 01-.27-.617V7.564L.79 2.174a1.077 1.077 0 01-.11-1.023z" fill="#555775"/></svg>
      <span>{{ staticText.filtersLabel }}</span>
    </div>
    <!-- Filter Congfiguration -->
    <div v-for="(filter, index) in filters"
         :key="getNewKey(index)"
         class="w-100 mb-3"
    >
      <div v-if="!filter.type" class="bao-widget-filter d-flex w-100">
        <div class="w-100 filter-type-select">
          <vue-multiselect
            id="filter-type-202205241525"
            v-model="filter.type"
            :options="getFilterTypeOptions"
            :placeholder="staticText.selectFilterType"
            :show-labels="false"
            :allow-empty="false"
            @select="input => handleFilterTypeSelected(index, input)"
          >
            <template slot="singleLabel"
                      slot-scope="{option}"
            >
              <div>
                {{ getSelectedTypeLabel(option) }}
              </div>
            </template>

            <template slot="option"
                      slot-scope="{option}"
            >
              <div>
                {{ getLabel(option) }}
              </div>
            </template>
          </vue-multiselect>
        </div>
        <div class="ml-3 filter-delete"
             @click="deleteEmptyFilter(index)"
        >
          <i
            class="fa fa-trash my-auto"
          ></i>
        </div>
      </div>
      <div class="d-flex w-100" v-else>
        <component
          :ref="`filter-component-${index}`"
          :is="filter.component"
          :value="filter.value"
          :options="filter.configOptions"
          :hasError="filter.error"
          v-bind="filter.props"
          @input="value => handleFiltersChanged(filter, value)"
          @delete="handleFiltersChanged(filter, null)"
        ></component>
      </div>
    </div>

    <button
      v-if="filterOptions.length > filters.length"
      id="new-filter-202205241448"
      class="new-filter"
      @click="addFilter"
    >
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.853 6.353H8.647V1.147a1.147 1.147 0 00-2.294 0v5.206H1.147a1.147 1.147 0 000 2.294h5.206v5.206a1.147 1.147 0 002.294 0V8.647h5.206a1.147 1.147 0 000-2.294zM7.65.457a.708.708 0 00-.856.69v5.647H1.147h5.647V1.147a.706.706 0 01.856-.69z" fill="#fff"/></svg>
    </button>

    <b-tooltip
      id="new-filter-202205241448-tooltip"
      target="new-filter-202205241448"
      triggers="hover"
      placement="bottom"
      noninteractive
    >
      {{ staticText.newFilterLabel }}
    </b-tooltip>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"
import { mapGetters } from "vuex"
import TimeframeFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/TimeframeFilter"
import DurationFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/DurationFilter"
import CounterpartFilter
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/CounterpartFilter"
import UserFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/UserFilter"
import PlaybookFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/PlaybookSelector"
import PlaybookItemAnswerFilters
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/PlaybookItemAnswerFilters"
import TagsFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/TagsFilter"
import TranscriptSearchFilter
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/TranscriptSearchFilter"
import {
  getDefaultConfigOptions,
  FILTER_KEY_TIMEFRAME,
  FILTER_KEY_PLAYBOOK,
  FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER,
  FILTER_KEY_TAGS,
  FILTER_KEY_TEXT_MATCH,
  FILTER_KEY_TRANSCRIPT_SEARCH,
  FILTER_KEY_DURATION,
  FILTER_KEY_COUNTERPART,
  FILTER_KEY_USER,
  FILTER_KEY_OBJECTIONS,
  FILTER_KEY_VIDEO_AVAILABLE,
  FILTER_KEY_AUDIO_AVAILABLE,
  FILTER_KEY_CALLS_ENDED_WITH_QUICK_END
} from "@/apps/dashboard/chartUtils"
import ObjectionsFilter
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/ObjectionsFilter"
import PercentageFilter
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/PercentageFilter"
import AvailableFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/AvailableFilter"

export default {
  name: "FilterConfigurations",
  components: {
    VueMultiselect
  },
  props: {
    widget: {
      required: true
    },
    configOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => {
    return {
      staticTextDefault: {
        filtersLabel: "Filters",
        newFilterLabel: "Add a new filter",
        optional: "Optional",
        selectFilterType: "Select Filter type",
        dateRange: "Date range",
        playbookItemAnswer: "Playbook + Item + Answer",
        playbook: "Playbook",
        tags: "Labels",
        shortcuts: "Shortcuts",
        duration: "Call duration",
        counterpart: "Counterpart",
        user: "User",
        filterType: "Filter type",
        textMatch: "Text-Match",
        browseTranscript: "Browse Transcript",
        videoAvailable: "Video available",
        audioAvailable: "Audio available",
        callsEndedWithQuickEnd: "Calls ended with Quick Call End"
      },
      selectedFilterType: null,
      showFilterTypeSelection: false,
      filters: [],
      selectedFilterTypes: [],
      protectedFilters: []
    }
  },
  computed: {
    ...mapGetters({
      currentUserIsAdminOrSuperuser: "auth/isAdminOrSuperUser",
      showTextMatch: "auth/showTextMatch",
      canUseBaoAudio: "auth/canUseBaoAudio",
      canFilterOnUserBasis: "auth/canFilterOnUserBasis",
      canUseVideoIntegration: "auth/canUseVideoIntegration"
    }),
    baseOptions () {
      let baseOptions = {
        [FILTER_KEY_TIMEFRAME]: {
          name: this.staticText.dateRange,
          component: TimeframeFilter,
          configOptions: getDefaultConfigOptions({
            showDelete: !this.protectedFilters.includes(FILTER_KEY_TIMEFRAME)
          }, this.widget)
        },
        [FILTER_KEY_PLAYBOOK]: {
          name: this.staticText.playbook,
          component: PlaybookFilter,
          configOptions: getDefaultConfigOptions({
            multiselect: true
          }, this.widget)
        },
        [FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER]: {
          name: this.staticText.playbookItemAnswer,
          component: PlaybookItemAnswerFilters
        },
        [FILTER_KEY_OBJECTIONS]: { name: this.staticText.shortcuts, component: ObjectionsFilter },
        [FILTER_KEY_TAGS]: { name: this.staticText.tags, component: TagsFilter },
        [FILTER_KEY_DURATION]: { name: this.staticText.duration, component: DurationFilter },
        [FILTER_KEY_COUNTERPART]: { name: this.staticText.counterpart, component: CounterpartFilter },
        [FILTER_KEY_CALLS_ENDED_WITH_QUICK_END]: {
          name: this.staticText.callsEndedWithQuickEnd,
          component: AvailableFilter,
          props: {
            filterType: FILTER_KEY_CALLS_ENDED_WITH_QUICK_END,
            filterLabel: this.staticText.callsEndedWithQuickEnd
          }
        }
      }
      if (this.currentUserIsAdminOrSuperuser && this.canFilterOnUserBasis) {
        baseOptions[FILTER_KEY_USER] = { name: this.staticText.user, component: UserFilter }
      }
      if (this.showTextMatch) {
        baseOptions[FILTER_KEY_TEXT_MATCH] = { name: this.staticText.textMatch, component: PercentageFilter }
      }
      if (this.canUseBaoAudio) {
        baseOptions[FILTER_KEY_TRANSCRIPT_SEARCH] = {
          name: this.staticText.browseTranscript,
          component: TranscriptSearchFilter
        }
      }
      if (this.canUseBaoAudio) {
        baseOptions[FILTER_KEY_AUDIO_AVAILABLE] = {
          name: this.staticText.audioAvailable,
          component: AvailableFilter,
          props: {
            filterType: "audio",
            filterLabel: this.staticText.audioAvailable
          }
        }
      }

      if (this.canUseVideoIntegration) {
        baseOptions[FILTER_KEY_VIDEO_AVAILABLE] = {
          name: this.staticText.videoAvailable,
          component: AvailableFilter,
          props: {
            filterType: "video",
            filterLabel: this.staticText.videoAvailable
          }
        }
      }
      Object.keys(baseOptions).map(function (key, index) {
        // we add the "type" property for easier handling of the objects later on
        baseOptions[key].type = key
        return baseOptions[key]
      })
      baseOptions = this.checkFilterOptions(baseOptions)
      return baseOptions
    },
    filterOptions () {
      return Object.values(this.baseOptions)
    },
    filterOptionsToShow () {
      return this.filterOptions.filter(option => {
        // return those options that are not in the selectedFilterTypes
        return !this.selectedFilterTypes.includes(option.type)
      })
    },
    getFilterTypeOptions () {
      return this.filterOptionsToShow.map(filter => filter.type)
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    filters (newVal) {
      this.selectedFilterTypes = newVal.map(filter => filter.type)
    }
  },
  mounted () {
    this.transformToDisplay()
  },
  methods: {
    getNewKey (index) {
      return index + "-" + this.widget.type + (this.widget && this.widget.id ? "-" + this.widget.id : "")
    },
    /**
     * Returns a filtered filters options object based on black and whitelists present in configOptions
     * @param baseOptions
     * @returns {{}|*}
     */
    checkFilterOptions (baseOptions) {
      let filterOptions = this.configOptions.filter(option => option.type === "filterOptions")
      if (!filterOptions || filterOptions.length === 0) return baseOptions // we don't need to do anything here
      filterOptions = filterOptions[0]
      const blacklist = filterOptions.blacklist || []
      const whitelist = filterOptions.whitelist
      const result = {}
      for (const [key, value] of Object.entries(baseOptions)) {
        if (!blacklist.includes(key) && (!whitelist || whitelist.includes(key))) {
          result[key] = value
        }
      }
      return result
    },
    transformExistingFilters () {
      /* *
      if there are default filters, mark them as protected filters so that they can be protected from deletion.
      if already saved filters are available, select those otherwise default filters.
      * */
      let existingFilters = {}
      const savedFiltersExist = !!this.widget.config && !!this.widget.config.filters
      const configFilterOptions = this.configOptions.filter(option => option.type === "filterOptions")
      const defaultFiltersExist = !!configFilterOptions && configFilterOptions.length > 0 && !!configFilterOptions[0].defaultFilters
      if (defaultFiltersExist) {
        const defaultFilters = configFilterOptions[0].defaultFilters || {}
        this.protectedFilters = Object.keys(defaultFilters)
        existingFilters = savedFiltersExist ? this.widget.config.filters : defaultFilters
      } else if (savedFiltersExist) existingFilters = this.widget.config.filters || {}
      return existingFilters
    },
    transformToDisplay () {
      const existingFilters = this.transformExistingFilters()
      const newFilters = []
      for (const [key, value] of Object.entries(existingFilters)) {
        const addOptions = (key in this.baseOptions) ? this.baseOptions[key] : null
        if (addOptions) newFilters.push({ ...addOptions, type: key, value })
      }
      this.filters = newFilters

      // This transformation is important especially for default Filters
      this.transformFiltersForBackend()
    },
    handleFilterTypeSelected (index, type) {
      this.$set(this.filters, index, { ...this.baseOptions[type] })
    },
    addFilter () {
      this.filters.push({})
    },
    getLabel (type) {
      if (type in this.baseOptions) return this.baseOptions[type].name
      return "Type not found"
    },
    getSelectedTypeLabel (type) {
      return this.staticText.filterType + ": " + this.getLabel(type)
    },
    handleFiltersChanged (filter, value) {
      const key = filter.type
      // set the value of the filter
      const currentFilters = [...this.filters]
      const index = this.filters.findIndex(filter => filter.type === key)
      if (index < 0) return // this means the filter is not yet configured
      if (value === null) {
        currentFilters.splice(index, 1) // delete key if value is null
      } else {
        currentFilters[index].value = value
      }
      this.filters = currentFilters

      this.transformFiltersForBackend()
    },
    transformFiltersForBackend () {
      // we transform the internal this.filters into something we want to send to the backend/parent
      const filters = {}
      for (const filter of Object.values(this.filters)) {
        filters[filter.type] = filter.value
      }
      // we emit an input event to allow handling the new filters (saving to backend)
      this.$emit("input", { filters })
    },
    validateAllFilters () {
      const emptyIndexes = []
      let errorCount = 0
      for (const index in this.filters) {
        if (this.filters[index].type) {
          const refLabel = `filter-component-${index}`
          const validatedFilter = this.$refs[refLabel][0].validateFilter()
          if (!validatedFilter) errorCount++
        } else {
          emptyIndexes.push(index)
        }
      }
      this.removeEmptyFilters(emptyIndexes)
      return errorCount <= 0
    },
    removeEmptyFilters (emptyIndexes) {
      const finalIndex = emptyIndexes.length - 1
      // This is a reverse loop because I don't want the indexes at the end of the array affected
      for (let i = finalIndex; i >= 0; i--) {
        this.filters.splice(emptyIndexes[i], 1)
      }
    },
    deleteEmptyFilter (index) {
      this.filters.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">

.optional-text {
  font-size: 14px;
  color: $slate40;
}

.header-text {
  font-size: 20px;
  color: $black;
}

.new-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  background-color: $slate80;
  transition: 0.3s ease-in;
  &:hover {
    background-color: $slate;
  }
}
</style>

<style lang="scss">
.filter-type-select {
  cursor: pointer;
  .multiselect__tags {
    border: 1px solid $slate20 !important;
  }
}
</style>
