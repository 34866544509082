export const state = {
  error: null
}

export const getters = {
}

export const mutations = {
  setError (state, error) {
    state.error = error
  },
  clearError (state) {
    state.error = null
  }
}

export const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
