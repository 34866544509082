const CoPilotCreate = () => import(/* webpackChunkName: "CoPilotCreate" */ "./CoPilotCreate.vue")
const CoPilotHome = () => import(/* webpackChunkName: "CoPilotHome" */ "./CoPilotHome.vue")
const CoPilot = () => import(/* webpackChunkName: "CoPilot" */ "./CoPilot.vue")

const baseUrl = "/co-pilot"
export default {
  name: "Co-pilot",
  route: {
    path: baseUrl,
    component: CoPilot,
    children: [
      {
        path: "",
        component: CoPilotHome
      },
      {
        path: "create-playbook",
        component: CoPilotCreate
      }
    ]
  }
}
