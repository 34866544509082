<template>
  <base-filter
    v-model="selectedUser"
    :ref="baseFilterRef"
    :label="staticText.selectUserPlaceholder"
    :placeholder="staticText.selectUserPlaceholder"
    :show-delete="true"
    :showLabel="false"
    :hasError="hasError"
    :isOpenFromAdvancedFilters="isOpenFromAdvancedFilters"
    :internal-search="true"
    :postprocess-options="postprocessOptions"
    :url="getUrl"
    :multiselect="!isSingleSelect"
    group-values="values"
    group-label="label"
    key-to-extract-value="pk"
    @input="handleValueChanged"
    @delete="$emit('delete')"
  ></base-filter>
</template>

<script>
import BaseFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BaseFilter"
import { getTranslatedUserFilters } from "@/apps/dashboard/chartUtils"
import { USER_TYPE_FILTER_KEY, USER_PK_FILTER_LABEL } from "@/apps/call_history/CallListComponents/filtersUtils.js"

export default {
  name: "UserFilters",
  components: { BaseFilter },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: false
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {
      getTranslatedUserFilters,
      staticTextDefault: {
        selectUserPlaceholder: "Select a User",
        teamLabel: "Team",
        usersLabel: "Users"
      },
      selectedUser: null,
      hasError: false,
      userOptions: [],
      baseFilterRef: "base-filter-202404041136"
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getUrl () {
      return "/api/users/get_group_members?is_active=true"
    },
    isSingleSelect () {
      return !this.selectedUser || !this.selectedUser.length || (this.selectedUser.length === 1 && !!this.userOptions.find(option => option.pk === this.selectedUser[0]))
    }
  },
  mounted () {
    this.selectedUser = this.value && this.value.selectedUser ? this.value.selectedUser : []
    this.setUp()
  },
  methods: {
    handleValueChanged (selectedUser) {
      const userLength = selectedUser.length
      let filterKey = USER_PK_FILTER_LABEL
      if (userLength) {
        // If a userOption is selected, we have to clear the previously selected options,
        // and select just that one as it should automatically switch to a single=select dropdown
        const selectedOption = this.userOptions.find(option => option.pk === selectedUser[userLength - 1])
        if (selectedOption) {
          this.selectedUser = [selectedUser[userLength - 1]]
          filterKey = USER_TYPE_FILTER_KEY
          this.$refs[this.baseFilterRef].manuallySetValue(selectedOption)
        }
      }
      this.$emit("input", { filterKey, selectedUser: this.selectedUser })

      if (this.selectedUser.length > 0) this.validateFilter()
    },
    validateFilter () {
      this.hasError = !this.selectedUser.length
      return !this.hasError
    },
    setUp () {
      for (const [key, value] of Object.entries(this.getTranslatedUserFilters())) {
        this.userOptions.push({ name: value, pk: key })
      }
    },
    postprocessOptions (options) {
      return [
        {
          label: this.staticText.teamLabel,
          values: this.userOptions
        },
        {
          label: this.staticText.usersLabel,
          values: options.map(option => ({ ...option, name: option.full_name }))
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

</style>
