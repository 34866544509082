<template>
  <base-filter
    :value="selectedCounterparts"
    :label="staticText.selectCounterparts"
    :url="getUrl"
    key-to-extract-value="id"
    :required="false"
    :show-delete="true"
    :internal-search="true"
    :hasError="hasError"
    :isOpenFromAdvancedFilters="isOpenFromAdvancedFilters"
    @input="handleValueChanged"
    @delete="$emit('delete')"
  ></base-filter>
</template>

<script>
import BaseFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BaseFilter"

export default {
  name: "CounterpartFilter",
  components: { BaseFilter },
  props: {
    value: { // list of counterpart ids
      type: Array,
      required: false,
      default: () => { return [] }
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        selectCounterparts: "Select Counterparts"
      },
      selectedCounterparts: [],
      hasError: false
    }
  },
  computed: {
    getUrl () {
      return "/api/counterpartsnew/get_saved_counterparts"
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.selectedCounterparts = this.value || []
  },
  methods: {
    handleValueChanged (newValue) {
      this.selectedCounterparts = newValue
      this.$emit("input", this.selectedCounterparts)
      if (this.selectedCounterparts.length > 0) this.validateFilter()
    },
    validateFilter () {
      this.hasError = !this.selectedCounterparts.length
      return !this.hasError
    }
  }
}
</script>

<style scoped>

</style>
