import axios from "axios"
import "./counterpartIcons.css"
const CounterpartList = () => import(/* webpackChunkName: "CounterpartList" */ "./CounterpartList.vue")

// exports basic app infos required for other modules and components
const baseApiUrl = "/api/counterpartsnew/"
const basePath = "/counterparts"

export default {
  name: "Counterparts",
  apiUrl: baseApiUrl,
  route: {
    path: basePath,
    component: CounterpartList
  },
  getCounterpartItems (url, params) {
    const getUrl = url || baseApiUrl + "get_saved_counterparts"
    return axios.get(getUrl, params)
  },
  counterpartTypeIcons: {
    contact: "fas fa-address-card contact-card-color",
    contacts: "fas fa-address-card contact-card-color",
    account: "fas fa-building account-card-color",
    accounts: "fas fa-building account-card-color",
    company: "fas fa-building account-card-color",
    organization: "fas fa-building account-card-color",
    opportunity: "fas fa-crown opportunity-card-color",
    deal: "fas fa-crown opportunity-card-color",
    lead: "fas fa-search-dollar lead-card-color",
    leads: "fas fa-search-dollar lead-card-color",
    person: "fas fa-address-card contact-card-color",
    people: "fas fa-address-card contact-card-color"
  },
  getCounterpartIcon (counterpartType) {
    if (!counterpartType) return
    const type = counterpartType.toLowerCase()
    return this.counterpartTypeIcons[type]
  }
}
