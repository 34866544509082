import _debounce from "lodash/debounce"

/***
 * debounceService is used to debounce/delay a user action/function call, to avoid multiple requests being
 * sent to backend, hence, reducing the traffic
 */
export const debounceService = {
  delayQueue: {},
  methods: {
    debounceOperation (index, callback, data, time = 250) {
      /**
       * Delays/debounces the execution of callback method based on the provided time (default = 250ms)
       * */
      const delayQueue = debounceService.delayQueue
      if (delayQueue[index]) {
        // Previous operation is in the delay queue, hence, stop it from executing
        delayQueue[index].cancel()
      }
      const debounceMethod = _debounce(() => {
        delete (debounceService.delayQueue[index])
        return callback(data)
      }, time)

      delayQueue[index] = debounceMethod
      return debounceMethod()
    },
    executeDelayedOperation (index) {
      /**
       * Executes the debounced operation for the given index immediately and returns a promise
       * */
      if (debounceService.delayQueue[index]) {
        return debounceService.delayQueue[index].flush()
      }
      return Promise.resolve()
    },
    executeAllDelayedOperations () {
      /**
       * Executes all debounced operations immediately and returns a promise that waits for
       * all the operations to complete
       * */
      return new Promise((resolve, reject) => {
        const waitFor = []
        for (const index in debounceService.delayQueue) {
          waitFor.push(debounceService.methods.executeDelayedOperation(index))
        }
        Promise.all(waitFor).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
