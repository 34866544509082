import moment from "moment"
import store from "../../store"
import { getLanguage } from "./index"
import colors from "@/assets/scss/main.scss"

export const FILTER_KEY_DURATION = "duration"
export const FILTER_KEY_PLAYBOOK = "talkscript__id__in"
export const FILTER_KEY_OBJECTIONS = "playbook_and_objection"
export const FILTER_KEY_COUNTERPART = "counterparts__id__in"
export const FILTER_KEY_TAGS = "tags"
export const FILTER_KEY_TEXT_MATCH = "text_match"
export const FILTER_KEY_TRANSCRIPT_SEARCH = "transcript_search"
export const FILTER_KEY_TIMEFRAME = "timeframe"
export const FILTER_KEY_USER = "user"
export const FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER = "playbook_item_and_answer"
export const FILTER_KEY_OBJECTIONS__PLAYBOOK = "talkscript__id"
export const FILTER_KEY_OBJECTIONS__OBJECTIONS = "additional_data_for_call__selected_choice__id__contains"
export const FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__PLAYBOOKS = "call_items__talkscript__id__in"
export const FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__ITEMS = "call_items__talkscript_item__id__in"
export const FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__TEXT = "call_items__answers__text__in"
export const FILTER_KEY_COUNTERPART_REACHABILITY = "counterpartReachability"
export const FILTER_KEY_CALL_RESULT = "callResult"
export const FILTER_KEY_ANSWER = "answer"
export const FILTER_KEY_CALL_DURATION = "callDuration"
export const FILTER_KEY_CALLS_ENDED_WITH_QUICK_END = "ended_with_quick_call_end"
export const LIBRARY_ITEM_TYPE_LABEL = "libraryItem"
export const NON_LIBRARY_ITEM_TYPE_LABEL = "nonLibraryItem"
export const FILTER_KEY_VIDEO_AVAILABLE = "video_available"
export const FILTER_KEY_AUDIO_AVAILABLE = "audio_available"
export const HUBSPOT_CALL_OUTCOME_ITEM_TAG = "item_type/hubspot_call_outcome"

export const today = new Date()

export function sortByAlphaNumeric (data, key = "name") {
  /**
   * Sorts data by alphanumeric order
   */
  return data.sort((a, b) => {
    if (!a[key]) return false
    return a[key].localeCompare(b[key], undefined, {
      numeric: true, // numeric is to true to compare the numerical part of a and b
      sensitivity: "base" // sensitivity is set to 'base' to compare the case and the alphabet
    })
  })
}

export function getFormat (momentDate, f = "YYYY-MM-DD HH:mm") {
  return momentDate.format(f)
}

export function formatDateRange (startDate, endDate = moment()) {
  return getFormat(startDate) + "," + getFormat(endDate)
}

export function formatMillisecondsToHHMMSS (milliseconds) {
  // Get hours from milliseconds
  const hours = milliseconds / (1000 * 60 * 60)
  const absoluteHours = Math.floor(hours)
  const h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours

  // Get remainder from hours and convert to minutes
  const minutes = (hours - absoluteHours) * 60
  const absoluteMinutes = Math.floor(minutes)
  const m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes

  // Get remainder from minutes and convert to seconds
  const seconds = (minutes - absoluteMinutes) * 60
  const absoluteSeconds = Math.floor(seconds)
  const s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds
  return h + ":" + m + ":" + s
}

export function getThisMonth () {
  return {
    start: moment().startOf("month").toISOString(),
    end: moment().endOf("month").toISOString()
  }
}

export function getLocale () {
  const locale = {
    en: "en-GB"
  }
  return locale[getLanguage()] || "default"
}

export function populateDataByDay (dateArray, items) {
  return dateArray.map(date => {
    let result = { text: date.toLocaleString(getLocale(), { month: "short", day: "numeric" }) }
    const entry = items.find(item => item.created_at__date === moment(date).format("YYYY-MM-DD"))
    if (entry) result = { ...result, ...entry }
    else result = { ...result, count: 0 }
    return result
  })
}

export function populateDataByWeek (dateArray, items) {
  return dateArray.map(date => {
    let result = { text: (getLanguage() === "en" ? "Week " : "Woche ") + moment(date).week() + " " + moment(date).year() }
    const entry = items.find(item =>
      item.created_at__week === moment(date).week() && item.created_at__year === moment(date).year()
    )
    if (entry) result = { ...result, ...entry }
    else result = { ...result, count: 0 }
    return result
  })
}

export function populateDataByMonth (dateArray, items) {
  return dateArray.map(date => {
    let result = { text: date.toLocaleString(getLocale(), { month: "short", year: "numeric" }) }
    const entry = items.find(item =>
      item.created_at__year === moment(date).year() &&
      item.created_at__month === (moment(date).month() + 1)
    )
    if (entry) result = { ...result, ...entry }
    else result = { ...result, count: 0 }
    return result
  })
}

export function getDateFromWeekAndYear (weekNumber, year) {
  return moment().day("Monday").week(weekNumber).year(year).set({ second: 0, minute: 0, hour: 0, millisecond: 0 })
}

export function getStartAndEndDates (data, timeframeFilter) {
  const items = data.data
  let startDate = new Date(data.start_date)// get start date from backend(null if timeframeFilter = noFilter)
  let endDate = new Date(data.end_date) // get end date from backend(null if timeframeFilter = noFilter)
  if (timeframeFilter === "noFilter") {
    if (items.length > 0) {
      // get start date and end date from the data
      startDate = getDateFromWeekAndYear(items[0].created_at__week, items[0].created_at__year)
      endDate = getDateFromWeekAndYear(items[items.length - 1].created_at__week, items[items.length - 1].created_at__year)
    } else {
      // if no data available get start of the year until today
      startDate = moment().startOf("year")
      endDate = moment().endOf("month")
    }
  }
  return { startDate, endDate }
}

export function getStartAndEndDatesIncludingFuture (data, timeframeFilter) {
  // Note: for now timeframeFilter is never "noFilter". Update this code if timeframeFilter==="noFilter"
  const startDate = new Date(data.start_date)
  let endDate = new Date(data.end_date)
  const includeFutureDates = { today: "day", thisWeek: "week", thisMonth: "month", thisYear: "year" }
  if (timeframeFilter in includeFutureDates) {
    endDate = moment().endOf(includeFutureDates[timeframeFilter])
  }
  return { startDate, endDate }
}

export const populateDateArray = {
  month: populateDataByMonth,
  week: populateDataByWeek,
  day: populateDataByDay
}

export function computeDateLabelForData (data, widget, includeFuture = false) {
  const dataset = data.data || data // we want to be able to handle both cases
  let timeframeFilter
  if (widget.config) {
    timeframeFilter = widget.config.filters ? widget.config.filters.timeframe : widget.config.timeframeFilter
  }
  timeframeFilter = timeframeFilter || "noFilter"
  const { startDate, endDate } = includeFuture
    ? getStartAndEndDatesIncludingFuture(data, timeframeFilter)
    : getStartAndEndDates(data, timeframeFilter)
  let timeframe = "day"
  if (dataset && dataset.length > 0) {
    if ("created_at__week" in dataset[0]) timeframe = "week"
    else if ("created_at__month" in dataset[0]) timeframe = "month"
  } else {
    const dateRangeInDays = moment(endDate).diff(moment(startDate), "days")
    if (dateRangeInDays > 30) timeframe = "month"
  }
  const dateArray = createdDateArray(startDate, timeframe, endDate)
  return populateDateArray[timeframe](dateArray, dataset)
}

export function createdDateArray (startDate, timeframe, endDate = null, increment = 1) {
  const dateList = []
  const today = moment().endOf("day").toDate()
  const dateMove = new Date(startDate)
  let convertedStartDate = new Date(startDate)
  let convertedEndDate = endDate
  if (!convertedEndDate) {
    const mapping = {
      day: today,
      week: moment().endOf("week"),
      noFilter: moment().endOf("week")
    }
    if (timeframe in mapping) convertedEndDate = mapping[timeframe]
    else convertedEndDate = moment().endOf("month").toDate()
  }
  const dateRangeInDays = moment(convertedEndDate).diff(moment(convertedStartDate), "days")
  while (convertedStartDate <= convertedEndDate) {
    // starting from convertedStartDate until we reach end date, we add the dates to the list and
    // loop(and skip some dates) based on timeframe and the increment value passed
    convertedStartDate = dateMove
    if (timeframe === "day") {
      // Below condition is used to exclude saturday and sunday from dates
      // Note: if timeframe value is 'today' or 'yesterday' and if this date falls on saturday or sunday, don't exclude.
      if ((dateMove.getDay() !== 6 && dateMove.getDay() !== 0) || dateRangeInDays <= 1) {
        dateList.push(new Date(dateMove.toISOString()))
      }
      dateMove.setDate(dateMove.getDate() + increment) // days are incremented by increment number passed
    } else {
      dateList.push(new Date(dateMove.toISOString()))
      if (timeframe === "week") {
        dateMove.setDate(dateMove.getDate() + 7) // days are incremented by a week
      } else {
        dateMove.setMonth(dateMove.getMonth() + increment) // months are incremented by increment number passed
      }
    }
  }
  return dateList
}

export function transformDateRange (value) {
  const startDate = value && value.start ? moment(value.start) : moment("1970-01-01")
  const endDate = value && value.end ? moment(value.end) : moment().endOf("month")
  return formatDateRange(startDate, endDate)
}

export function getUsername (item, baseField = "user") {
  return item[baseField + "__first_name"] + " " + item[baseField + "__last_name"]
}

export function wordWrap (s, w) {
  if (!s) {
    return ""
  }
  const splitChar = "{{cut}}"
  return ("" + s).replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, "g"), "$1" + splitChar).split(splitChar)
}

const wordSlice = (word, fontSize) => {
  const estimatedYAxesWidth = 300
  const maxWordLength = Math.round(estimatedYAxesWidth / fontSize)

  return word.length > maxWordLength ? `${word.slice(0, maxWordLength)}...` : word
}

export function getDefaultLineChartOptions (widget, config = {}) {
  return {
    addLabel: config.addLabel,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 4,
          max: config.maxCount
        }
      }],
      xAxes: [{
        categoryPercentage: 3,
        barPercentage: 0.8,
        maxBarThickness: 60,
        minBarLength: 2,
        gridLines: {
          display: false
        },
        ticks: {
          callback: function (label) {
            return wordWrap(label, 25)
          }
        }
      }]
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: config.legend ? config.legend : false
    },
    tooltips: {
      callbacks: {
        title: function (tooltipitem) {
          tooltipitem = tooltipitem[0]
          return tooltipitem.xLabel
        }
      },
      backgroundColor: colors.white,
      cornerRadius: 12,
      titleFontColor: colors.slate80,
      bodyFontColor: colors.slate80
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  }
}

const showDataLabelOutside = (context) => {
  for (const contextMeta of Object.values(context.dataset._meta)) {
    const dataWidth = contextMeta.data[context.dataIndex]._model.x
    if (dataWidth < 215) {
      return true
    }
  }
  return false
}

export function getDefaultHorizontalBarChartOptions (config, vueComponent, configOptions = {}) {
  const chartData = vueComponent.chartData
  let fontSize = 12
  if (chartData) {
    const totalDataLength = chartData.labels.length
    // The font size is dynamic with the no. of labels we display, but to a max value of 12px
    fontSize = Math.min(Math.floor(400 / totalDataLength), 12)
  }

  const disableChartClickBehavior = configOptions.disableChartClickBehavior || false

  const labelAdd = config && config.addLabel ? config.addLabel : ""
  return {
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label + labelAdd
          },
          maxTicksLimit: 8
        },
        gridLines: {
          // display: false
        },
        barPercentage: 1.0
      }],
      yAxes: [{
        maxBarThickness: 60,
        gridLines: {
          display: false
        },
        afterFit: function (scaleInstance) {
          scaleInstance.width = 180 // sets the width to 100px
        },
        ticks: {
          fontSize,
          lineHeight: 1,
          callback: function (label) {
            return wordSlice(label, fontSize)
          }
        }
      }]
    },
    tooltips: {
      enabled: configOptions.showTooltip || false,
      callbacks: {
        title () {
        },
        label: function (tooltipItem, data) {
          let result = tooltipItem.yLabel + ": " + tooltipItem.value + labelAdd
          try {
            const dataset = data.datasets[tooltipItem.datasetIndex]
            if (dataset.absolut && dataset.absolut[tooltipItem.index]) {
              result += " ("
              result += dataset.absolut[tooltipItem.index]
              result += ")"
            }
          } catch (e) {
            console.error(e)
          }
          return result
        }
      },
      backgroundColor: colors.white,
      cornerRadius: 12,
      titleFontColor: colors.slate80,
      bodyFontColor: colors.slate80,
      displayColors: colors.bricklight
    },
    elements: {
      rectangle: {
        borderWidth: 2
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        borderRadius: 12,
        padding: {
          bottom: 2
        },
        font: {
          family: "'Helvetica', 'Arial', sans-serif, 'Font Awesome 5 Free'",
          weight: 540,
          size: fontSize
        },
        color: (context) => showDataLabelOutside(context) ? colors.slate80 : colors.white,
        backgroundColor: (context) => {
          if (showDataLabelOutside(context)) {
            return colors.slate10
          }
          return context.hovered ? colors.white20 : ""
        },
        anchor: (context) => showDataLabelOutside(context) ? "end" : "center",
        align: (context) => showDataLabelOutside(context) ? "right" : "center",
        formatter: (value, context) => {
          value = `${value}${labelAdd}`
          return (context.hovered || context.active) && !disableChartClickBehavior ? `${value}  \uf054` : value
        },

        listeners: {
          click: (context) => {
            if (!disableChartClickBehavior) configOptions.dataLabelClickCallBack(context.dataIndex)
          },
          enter: (context) => {
            context.hovered = true
            context.chart.canvas.style.cursor = "pointer"
            return true
          },
          leave: (context) => {
            context.hovered = false
            context.chart.canvas.style.cursor = "default"
            return true
          }
        }
      }
    }
  }
}

export const timeframes = {
  static: "Custom",
  today: "today",
  yesterday: "yesterday",
  last7Days: "since last 7 days",
  thisWeek: "in this week",
  lastWeek: "in last week",
  last14Days: "since last 14 days",
  thisMonth: "in this month",
  lastMonth: "in last month",
  last30Days: "since last 30 days",
  thisYear: "in this year",
  lastYear: "in last year",
  last12Months: "since last 12 months"
}

export function getTranslatedTimeframes () {
  return store.getters["I18nStore/getI18n"]("Timeframes", timeframes)
}

export const userFilters = {
  me: "Me",
  team: "Team (excl. me)"
}

export function getTranslatedUserFilters () {
  return store.getters["I18nStore/getI18n"]("UserFilterOptions", userFilters)
}

export function getBurntUpChartData (data, goal) {
  /***
   * This function computes the data series for actual and ideal growth
   * Actual growth is computed as incremental values(like step)
   * Ideal growth is computed based on the goal and the length of the data
   */
  const idealDataPerDay = goal / data.length
  let previousCount = 0
  const idealSeries = []
  const actualSeries = []
  const labels = []
  for (const index in data) {
    idealSeries.push((idealDataPerDay * (parseInt(index) + 1)).toFixed(2))
    const actualData = data[index].actualData !== null ? previousCount + data[index].actualData : data[index].actualData
    actualSeries.push(actualData)
    labels.push(data[index].text)
    previousCount = actualData
  }
  return { idealSeries, actualSeries, labels }
}

export const getDefaultConfigOptions = (options = {}) => {
  return new WidgetConfigOptions({
    value: null,
    showLabel: true,
    multiselect: false,
    required: false,
    showDelete: true,
    ...options
  })
}

export class WidgetConfigOptions {
  constructor (newConfigOptions = null, widget = null) {
    this.handleNewConfig(newConfigOptions)
    this.updateWidget(widget)
  }

  handleNewConfig (newConfigOptions) {
    if (newConfigOptions instanceof WidgetConfigOptions) {
      // we might get a WidgetConfigOptions itself, then we transform it accordingly
      this.internalConfig = newConfigOptions.internalConfig
    } else {
      this.internalConfig = newConfigOptions
    }
    if (!newConfigOptions) {
      this.internalConfig = new WidgetConfigOptions(getDefaultConfigOptions())
    }
  }

  get fieldName () {
    return this.internalConfig.fieldName || null
  }

  get label () {
    return this.internalConfig.label || null
  }

  get value () {
    return this.internalConfig.value
  }

  set value (newVal) {
    this.internalConfig.value = newVal
  }

  get type () {
    return this.internalConfig.type || null
  }

  isType (type) {
    return !!this.internalConfig.type && !!type && this.internalConfig.type === type
  }

  get rawConfig () {
    return this.internalConfig
  }

  get multiselect () {
    return this.internalConfig.multiselect || null
  }

  get required () {
    return !!this.internalConfig.required
  }

  get showDelete () {
    return "showDelete" in this.internalConfig ? this.internalConfig.showDelete : true
  }

  get showLabel () {
    return "showLabel" in this.internalConfig ? this.internalConfig.showLabel : true
  }

  get options () {
    return "options" in this.internalConfig ? this.internalConfig.options : []
  }

  updateWidget (widget) {
    this.widget = widget
    if (!widget) return // dont parse anything if nothing is there
    this.parseWidgetConfig()
  }

  parseWidgetConfig () {
    const existingConfig = this.widget.config || {}
    if (this.internalConfig.fieldName && this.internalConfig.fieldName in existingConfig) {
      this.internalConfig.value = existingConfig[this.internalConfig.fieldName]
    }
  }

  get isCriteriaOptions () {
    return this.type === "criteriaOptions"
  }

  get isText () {
    return this.type === "text"
  }

  get isNumber () {
    return this.type === "number"
  }

  get isTextArea () {
    return this.type === "textarea"
  }

  get isPlaybookFilter () {
    return this.type === "playbookFilter"
  }

  get isPlaybookItemFilter () {
    return this.type === "playbookItemFilter"
  }

  get isAnswerFilter () {
    return this.type === "answerFilter"
  }

  get isTimeframeFilter () {
    return this.type === "timeframeFilter"
  }

  get enableGrouping () {
    return "enableGrouping" in this.internalConfig ? this.internalConfig.enableGrouping : false
  }

  get isPlaybookAndPlaybookItemFilter () {
    return this.type === "playbookAndPlaybookItemFilter"
  }
}
