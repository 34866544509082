import axios from "axios"
import { playbookServices } from "@/apps/talkscript/PlaybookServices"
import appInfo from "@/apps/talkscript"

const state = {
  playbook: {
    name: "",
    updated_at: "Never"
  },
  rootContainer: {},
  playbookItems: [],
  currentPlaybookItem: null,
  playbookLoading: false,
  errors: null,
  loadingLibraryItems: false,
  libraryItems: [],
  activeItem: {}
}

const getters = {
  getPlaybook (state) {
    return state.playbook
  },
  getRootContainer (state) {
    return state.rootContainer
  },
  getPlaybookItems (state) {
    return state.playbookItems
  },
  getErrors (state) {
    return state.errors
  },
  getPlaybookLoading (state) {
    return state.playbookLoading
  },
  getLibraryItems (state) {
    return state.libraryItems
  },
  getLoadingLibraryItems (state) {
    return state.loadingLibraryItems
  },
  getActiveItem (state) {
    return state.activeItem
  }
}

const mutations = {
  setPlaybookLoading (state, value) {
    state.playbookLoading = value
  },
  setErrors (state, value) {
    state.errors = value
  },
  setPlaybook (state, value) {
    state.playbook = value
  },
  setRootContainer (state, value) {
    state.rootContainer = value
  },
  setPlaybookItems (state, value) {
    state.playbookItems = value
  },
  setLoadingLibraryItems (state, value) {
    state.loadingLibraryItems = value
  },
  setLibraryItems (state, value) {
    state.libraryItems = value
  },
  updatePlaybookItem (state, { item, index }) {
    state.playbookItems[index] = item
  },
  addItemToPlaybookList (state, { item, index }) {
    state.playbookItems.splice(index, 0, item)
  },
  removeItemFromPlaybookList (state, { index }) {
    state.playbookItems.splice(index, 1)
  },
  setActiveItem (state, item) {
    state.activeItem = item
  }
}

const actions = {
  fetchPlaybook ({ commit }, playbookId) {
    commit("setPlaybookLoading", true)
    return new Promise((resolve, reject) => {
      axios.get(appInfo.apiUrl.playbook + "/" + playbookId)
        .then(response => {
          const playbook = response.data
          playbook.main_container.children = playbookServices.methods.setImportance(playbook.main_container.children || []).map(item => {
            if (item.objections && item.objections.length > 0) item.objectionFlag = true
            return item
          })
          commit("setPlaybook", playbook)
          commit("setRootContainer", playbook.main_container)
          commit("setPlaybookItems", playbook.main_container.children)
          commit("setPlaybookLoading", false)
          resolve(playbook)
        }).catch(error => {
          commit("setPlaybookLoading", false)
          const errorMessage = playbookServices.methods.extractErrorMessage(error)
          commit("setErrors", errorMessage)
          reject(errorMessage)
        })
    })
  },

  getLibraryItems ({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingLibraryItems", true)
      axios.get(appInfo.apiUrl.libraryItems).then(response => {
        commit("setLibraryItems", response.data.results)
        resolve()
      }).catch(error => {
        reject(error)
      }).finally(() => {
        commit("setLoadingLibraryItems", false)
      })
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
