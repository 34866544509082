<template>
  <div :class="['bao-widget-filter w-100 d-flex', { 'advanced-filters' : isOpenFromAdvancedFilters }]">
    <bao-widget-filter-container
      :label="label"
      label-tag="base-filter-202112211245"
      :required="required"
      :hasError="hasError"
    >
      <vue-multiselect
        id="base-filter-202112211245"
        v-model="selectedValues"
        :options="options"
        :show-labels="false"
        :multiple="multiselect"
        :placeholder="placeholder ? placeholder : label"
        :internal-search="internalSearch"
        :hide-selected="multiselect"
        :loading="isLoading"
        :clear-on-select="!multiselect"
        :close-on-select="!multiselect"
        :show-no-results="false"
        :custom-label="item => item.name"
        :track-by="keyToExtractValue"
        :group-values="groupValues"
        :group-label="groupLabel"
        :group-select="false"
        @search-change="searchTerm=>search(searchTerm, internalSearch)"
        @input="handleValueSelected"
      >
        <template slot="tag"
                  slot-scope="{ option, remove }"
        >
          <span class="custom__tag d-flex justify-content-between my-1">
            <span class="w-100">
              <span class="option__small">
                {{ option.name }}
              </span>
            </span>

            <span class="custom__remove"
                  @click.stop="remove(option)"
            >
              <i class="far fa-times-circle"></i>
            </span>
          </span>
        </template>
        <span slot="noOptions">
          {{ staticText.listIsEmpty }}
        </span>
      </vue-multiselect>
    </bao-widget-filter-container>
    <div v-if="showDelete"
         class="ml-3 filter-delete"
         @click.prevent="$emit('delete')"
    >
      <i
        v-if="!isOpenFromAdvancedFilters"
        class="fa fa-trash my-auto"
      ></i>
      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"
import axios from "axios"
import BaoWidgetFilterContainer from "./Filter/BaoWidgetFilterContainer.vue"

export default {
  name: "BaseFilter",
  components: { VueMultiselect, BaoWidgetFilterContainer },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    showDelete: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    url: {
      type: String,
      required: true
    },
    multiselect: {
      type: Boolean,
      required: false,
      default: true
    },
    keyToExtractValue: { // The key used to extract the value from the backend data
      type: String,
      required: false,
      default: "id"
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    internalSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    postprocessOptions: {
      type: Function,
      required: false,
      default: (options) => options
    },
    groupValues: {
      type: String,
      required: false,
      default: ""
    },
    groupLabel: {
      type: String,
      required: false,
      default: ""
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      axios,
      isLoading: false,
      options: [],
      selectedValues: [],
      staticTextDefault: {
        listIsEmpty: "List is empty"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    url () {
      this.setUp()
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    setUp () {
      return this.search().then(() => {
        this.setSelectedValues()
      })
    },
    setSelectedValues () {
      const extractFunc = this.multiselect ? "filter" : "find"
      // Extract function must return,
      //  - In case of single select => an object(if value found in options) or undefined (if value not found in options)
      //  - In case of multiselect => an array
      if (this.value) {
        let optionsToFilter = this.options
        // if vue-multiselect has grouping, need to extract all values first
        if (this.options.some(option => option.values)) { optionsToFilter = this.options.flatMap(option => option.values) }
        this.selectedValues = optionsToFilter[extractFunc](
          option => this.value.indexOf(option[this.keyToExtractValue]) > -1
        )
      }
      // if no options available, then previously selected values must not be overridden to empty(undefined)
      if (this.options.length > 0) {
        this.handleValueSelected()
      }
    },
    search (searchTerm, searchLocally = false) {
      if (searchLocally) return
      this.isLoading = true
      let nameFilter = ""
      if (searchTerm) {
        nameFilter = `&name__icontains=${searchTerm}`
      }
      return this.axios.get(this.getUrl(nameFilter)).then(response => {
        this.options = this.postprocessOptions(
          response.data && response.data.results
            ? response.data.results
            : response.data
        )
        this.isLoading = false
      })
    },
    getUrl (nameFilter) {
      return `${this.url}${nameFilter}`
    },
    handleValueSelected () {
      let data = []
      // If only single selection allowed, then
      // 1. this.selectedValues becomes null when you deselect
      // 2.this.selectedValues becomes undefined  when setSelectedValues is called and the already existing
      // value(in config) is not found in option list
      if (this.selectedValues !== null && this.selectedValues !== undefined) {
        data = !this.multiselect ? [this.selectedValues] : this.selectedValues
      }
      this.$emit("input", this.getValuesBasedOnKey(data))
    },
    getValuesBasedOnKey (data) {
      return data.map(item => item[this.keyToExtractValue])
    },
    manuallySetValue (value) {
      this.selectedValues = value
    }
  }
}
</script>

<style scoped>

.red {
  color: red;
}
</style>
