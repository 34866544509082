const UserProfileDetails = () => import(/* webpackChunkName: "UserProfileDetails" */ "./profile/UserProfileView")
const IntegrationsModule = () => import(/* webpackChunkName: "IntegrationsModule" */ "./integrations/IntegrationList")
const TeamModule = () => import(/* webpackChunkName: "TeamModule" */ "./TeamList.vue")

// exports basic app infos required for other modules and CallViewItem_components

// think of a better solution than to having both routes and url separated!
// -> use navbar item

const baseUrl = "/users"

export const profileDetailView = {
  name: "Profile",
  apiUrl: "/api/users/profile",
  route: { path: baseUrl + "/profile", component: UserProfileDetails }
}

export const integrationView = {
  name: "Integrations",
  route: { path: baseUrl + "/integrations", component: IntegrationsModule }
}

export const teamView = {
  name: "Team",
  apiUrl: "/api/users/team",
  route: { path: baseUrl + "/team", component: TeamModule }
}

export default {
  name: "User",
  apiUrl: "/api" + baseUrl,
  route: {
    path: baseUrl, component: UserProfileDetails
  },
  routes: [
    profileDetailView.route,
    integrationView.route,
    teamView.route
  ]
}
