import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  getDefaultLineChartOptions,
  getUsername,
  computeDateLabelForData,
  formatMillisecondsToHHMMSS
} from "@/apps/dashboard/chartUtils"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"
import { defaultLineDatasetValues } from "@/apps/dashboard/chartConfig"

const LineChart = () => import(/* webpackChunkName: "LineChart" */ "@/apps/dashboard/BaoWidgetsBaseComponents/LineChart")
export default {
  name: "BaoWidgetTimeGraph",
  info: {
    title: "Activity Over Time",
    description: "Displays the total number of dial attempts and calls over time."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getUsername,
      computeDateLabelForData,
      getDefaultLineChartOptions,
      formatMillisecondsToHHMMSS,
      chartComponent: LineChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      totalCalls: 0
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showGoToConversationsButton () {
      return this.totalCalls > 0
    }
  },
  methods: {
    mappingFunc (data) {
      const transformedData = this.computeDateLabelForData(data, this.widget)
      const { labels, dataset } = this.getDatasets(transformedData)
      this.totalCalls = this.getTotalCount(data.data)
      return {
        labels,
        datasets: [{
          label: this.staticText.avg,
          data: dataset,
          ...defaultLineDatasetValues
        }]
      }
    },
    getDatasets (transformedData) {
      const labels = []
      const dataset = []
      for (const item of transformedData) {
        labels.push(item.text)
        dataset.push(Math.round(item.count * 100) / 100)
      }
      return { labels, dataset }
    },
    getChartOptions () {
      return this.getDefaultLineChartOptions(this.widget)
    },
    getConfigOptions () {
      return []
    },
    getDisplayProps () {
      return { widget: this.widget, totalItems: this.totalCalls, showConversationsView: this.showGoToConversationsButton }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
