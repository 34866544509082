import axios from "axios"
import Vue from "vue"

const state = {
  objectDefinitions: [],
  fieldDefinitions: {},
  loadingFieldDefinitions: [],
  objectDefinitionError: null
}

const getters = {
  getObjectDefinitions (state) {
    return state.objectDefinitions
  },
  getFieldDefinitions (state) {
    return state.fieldDefinitions
  },
  getLoadingFieldDefinitions (state) {
    return state.loadingFieldDefinitions
  },
  getObjectDefinitionError (state) {
    return state.objectDefinitionError
  },
  getCrmObjectId (state) {
    const val = state.objectDefinitions.find(val => val.service_key === "salesforce" && val.object_type === "Task")
    if (!val || !val.id) return null
    return val.id
  }
}

const mutations = {
  setObjectDefinitions (state, objectDefinitions) {
    state.objectDefinitions = objectDefinitions
  },
  setObjectDefinitionError (state, { error, label }) {
    state.objectDefinitionError = { error, label }
  }
}

const actions = {
  getCRMObjectLink ({ commit }, val = null) {
    /* Retrieves CRMObjectLinks as a list (without an id) or with the fieldDefinitions if an id is provided */
    return new Promise((resolve) => {
      let url = "/api/crmobjectlinks"
      if (val !== null) {
        url += "/" + val.id
      }
      axios.get(url).then(response => {
        if (val === null) {
          const objectDefinitions = response.data.results ? response.data.results : []
          commit("setObjectDefinitions", objectDefinitions)
        } else {
          resolve(response.data.field_definitions)
        }
      }).catch(error => {
        commit("setObjectDefinitionError", { error, label: val.label })
      }).finally(resolve)
    })
  },
  retrieveFieldDefinitions ({ commit, state, dispatch }, array) {
    /* Retrieves one or more field definitions for the provided CRM Object Links */
    const diff = {}
    for (const val of array) {
      if (!state.fieldDefinitions[val.id]) diff[val.id] = val
    }
    if (Object.keys(diff).length > 0) {
      state.loadingFieldDefinitions = []
      const promiseArray = []
      for (const id in diff) {
        state.loadingFieldDefinitions.push(id)
        promiseArray.push(dispatch("getCRMObjectLink", diff[id]).then(fieldDefinitions => {
          Vue.set(state.fieldDefinitions, id, fieldDefinitions)
        }))
      }
      Promise.all(promiseArray).then(() => {
        state.loadingFieldDefinitions = []
      })
    }
  },

  getTaskFieldDefinitions ({ commit, getters, dispatch, state }) {
    // Only loads salesforce task fields for now
    const getAndTransformData = (resolve) => {
      const crmObjectId = getters.getCrmObjectId
      const url = "/api/crmobjectlinks/" + crmObjectId
      if (url != null) {
        axios.get(url).then(response => {
          const data = response.data.field_definitions.filter(fieldDefinition => {
            return !fieldDefinition.readonly && fieldDefinition.supported
          }).map(fieldDefinition => {
            return {
              label: fieldDefinition.label,
              raw_definition: fieldDefinition,
              crm_object_link: { id: crmObjectId, key: response.data.service.key },
              logo: response.data.service.extra_options.logo
            }
          })
          resolve(data)
        })
      }
    }
    // If object definitions weren't loaded previously, load them first
    if (state.objectDefinitions.length === 0) {
      return new Promise((resolve, reject) => {
        dispatch("getCRMObjectLink").then(() => {
          getAndTransformData(resolve)
        }).catch((error) => {
          commit("setObjectDefinitionError", { error, label: "Task" })
          reject(error)
        })
      })
    }
    return new Promise((resolve) => {
      getAndTransformData(resolve)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
