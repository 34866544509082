import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  getUsername,
  timeframes,
  FILTER_KEY_TIMEFRAME
} from "@/apps/dashboard/chartUtils"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const ArcPieChart = () => import(/* webpackChunkName: "ArcPieChart" */ "../BaoWidgetsBaseComponents/ArcPieChart")

export default {
  name: "BaoWidgetCallAttemptsGoal",
  info: {
    title: "Call Attempts Goal",
    description: "Number of dialing attempts in a given time period, no matter if a counterpart was reached or not."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getUsername,
      chartComponent: ArcPieChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      staticTextDefault: {
        target: "Target",
        dialingAttempts: "Dialing Attempts"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showGoToConversationsButton () {
      return !!(this.chartData && this.chartData.value && Number(this.chartData.value) > 0)
    }
  },
  methods: {
    mappingFunc (data) {
      return {
        color: this.defaultChartColor,
        label: this.staticText.dialingAttempts,
        value: data.calls_count,
        total: data.goal
      }
    },
    getChartOptions () {
      return null
    },
    getConfigOptions () {
      return [
        {
          type: "filterOptions",
          defaultFilters: { [FILTER_KEY_TIMEFRAME]: timeframes.today }
        },
        {
          label: this.staticText.target,
          type: "number",
          value: 20, // default
          fieldName: "goal"
        }
      ]
    },
    getDisplayProps () {
      return { widget: this.widget, showConversationsView: this.showGoToConversationsButton }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
