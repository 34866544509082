import axios from "axios"

function getDefaultQueryState () {
  return {
    query: null,
    queryFields: [],
    queryFilters: [],
    queryAggregates: [],
    queryData: null,
    querySettings: {}
  }
}

function getDefaultState () {
  return {
    defaultURL: "/api/squaapi/genericquery/",
    saveURL: "/api/squaapi/queries/",
    loadedQueries: [],
    queries: [],
    model: null,
    modelFields: [],

    ...getDefaultQueryState()
  }
}

const state = getDefaultState()

const getters = {
  getModelFields: state => state.modelFields,
  getModel: state => state.model,
  getQueryFields: state => state.queryFields,
  getQueryFilters: state => state.queryFilters,
  getQueryAggregates: state => state.queryAggregates,
  getQueryData: state => state.queryData,
  getSavedQueries: state => state.queries,
  getQuerySettings: state => state.querySettings,
  getQuery: state => state.query,
  getFilterFunctions: state => (field, includeAll = false) => {
    let lookups = field.lookups
    const defaultLookups = [
      { text: "Equals", value: "=", type: field.type },
      { text: "Not Equals", value: "!=", type: field.type }
    ]
    const favoritesObj = {
      gt: { text: "Greater Than", value: "__gt", type: field.type },
      gte: { text: "Greater Than or Equal", value: "__gte", type: field.type },
      lt: { text: "Lesser Than", value: "__lt", type: field.type },
      lte: { text: "Lesser Than or Equal", value: "__lte", type: field.type },
      icontains: { text: "Contains (case-insensitive)", value: "__icontains", type: "text" },
      istartwith: { text: "Starts with (case-insensitive)", value: "__istartwith", type: "text" },
      iendswith: { text: "Ends with (case-insensitive)", value: "__iendswith", type: "text" },
      isnull: { text: "Is Null (No Value)", value: "__isnull", type: "boolean" },
      in: { text: "Contained In", value: "__in", type: "text" },
      regex: { text: "Regex", value: "__iendswith", type: "text" }
    }
    const additional = [
      { text: "IRegex", value: "__iregex" }
      // TODO
      // 0: "contained_by": {text: "Contained By", value:"__contained_by"} // unnecessary
      // 1: "contains": {text: "Contains", value:"__contains"} // declined in favor of icontains
      // 2: "endswith": {text: "Ends With", value:"__endswith"}
      // 3: "exact": {text: "Exact", value:"__exact"}
      // 7: "iendswith": {text: "Ends with (case-insensitive)", value:"__iendswith"}
      // 8: "iexact": {text: "Exact (case-insensitive)", value:"__iexact"}
      // 10: "iregex"
      // 15: "range"
      // 17: "startswith"
    ]
    lookups = lookups.map(lookup => {
      if (!includeAll && !favoritesObj[lookup]) return null
      return favoritesObj[lookup] ? favoritesObj[lookup] : additional[lookup]
    }).filter(l => l !== null)
    return defaultLookups.concat(lookups)
  },
  getLoadedQueries: state => state.loadedQueries
}

const mutations = {
  setDefaultURL (state, newUrl) {
    state.defaultURL = newUrl
  },
  setModelFields (state, newFields) {
    state.modelFields = newFields
  },
  setModel (state, newModel) {
    state.model = newModel
  },
  setFields (state, newFields) {
    state.queryFields = newFields
  },
  setFilters (state, newFilters) {
    state.queryFilters = newFilters
  },
  setAggregates (state, newAggregates) {
    state.queryAggregates = newAggregates
  },
  setQueryData (state, newData) {
    state.queryData = newData
  },
  setQueries (state, newList) {
    state.queries = newList
  },
  setSettings (state, newSettings) {
    state.querySettings = newSettings
  },
  setQuery (state, newQuery) {
    state.query = newQuery
  },
  resetState (state) {
    const newStore = { ...state, ...getDefaultQueryState() }
    Object.assign(state, newStore)
  }
}

/**
 * Returns a new list without the provided item
 * */
function without (item, list) {
  list.splice(list.indexOf(item), 1)
  return list
}

/**
 * Returns only items that have a field active set to a truthy value
 * @param list
 */
function getActive (list) {
  return list.filter(item => !!item.active)
}

const actions = {
  retrieveQueries ({ state, commit }) {
    axios.get(state.saveURL).then(response => {
      console.log(response.data)
      commit("setQueries", response.data.results)
    })
  },
  activateQuery ({ state, commit, dispatch }, newQuery) {
    const { queryFields, queryFilters, queryAggregates, querySettings, model } = newQuery.q_data
    commit("setQuery", newQuery)
    commit("setAggregates", queryAggregates)
    commit("setFilters", queryFilters)
    commit("setFields", queryFields)
    commit("setSettings", querySettings)
    commit("setModel", model)
    dispatch("doQuery")
  },
  addLoadedQuery ({ state, commit, dispatch }, query) {
    state.loadedQueries.push(query)
    dispatch("activateQuery", query)
  },
  loadQuery ({ state, commit, dispatch }, query) {
    axios.get(state.saveURL + query.id).then(response => {
      dispatch("addLoadedQuery", response.data)
    })
  },
  saveQuery ({ state, dispatch }, { name, model }) {
    const { queryFields, queryFilters, queryAggregates, querySettings } = state
    const q_data = {
      queryFields,
      queryFilters,
      queryAggregates,
      querySettings,
      model
    }

    axios.post(state.saveURL, { name, q_data }).then(response => {
      // TODO: show save successful to user
      dispatch("loadQuery", response.data)
    })
  },
  doQuery ({ state, commit }) {
    // TODO: move into separate utils function
    const groupByQuery = getActive(state.queryFields).map(activeField => `group_by[${activeField.field}]`)
    const filterQuery = getActive(state.queryFilters).map(
      filter => `${filter.field.field + filter.filterFunction.replace("=", "")}=${filter.filterValue}`
    )
    const aggregateQuery = getActive(state.queryAggregates).map(
      aggregate => `aggregate[${aggregate.aggregateFunction}]=${aggregate.field.field}`
    )

    // TODO: add error handling -> especially if the user want to load a new query
    const baseurl = state.defaultURL + state.model.model
    axios.get(baseurl + "/?" + (groupByQuery.concat(filterQuery).concat(aggregateQuery)).join("&")).then(
      response => {
        commit("setQueryData", response.data)
      })
  },
  loadModelFields ({ state, commit }, app_model_name) {
    const fullurl = state.defaultURL + app_model_name + "/fields"
    axios.get(fullurl).then(response => {
      commit("setModelFields", response.data)
    })
  },
  addField ({ state, commit, dispatch }, newField) {
    if (state.queryFields.some(item => item.field === newField.field)) {
      // TODO: show error message
      return
    }
    commit("setFields", [...state.queryFields, { field: newField.field, active: true }])
    dispatch("doQuery")
  },
  removeField ({ state, commit, dispatch }, fieldToRemove) {
    commit("setFields", without(fieldToRemove, state.queryFields))
    dispatch("doQuery")
  },
  addFilter ({ state, commit, dispatch }, newFilter) {
    // TODO: Validation - does the filter make sense logically -> very difficult because of the vast possibilities of
    //  combinations
    commit("setFilters", [...state.queryFilters, newFilter])
    dispatch("doQuery")
  },
  removeFilter ({ state, commit, dispatch }, filterToRemove) {
    commit("setFilters", without(filterToRemove, state.queryFilters))
    dispatch("doQuery")
  },
  modifyFilter ({ state, commit, dispatch }, oldFilter, newFilter) {
    oldFilter = { ...newFilter }
    dispatch("doQuery")
  },
  modifyAggregate ({ state, commit, dispatch }, newAggregate) {
    dispatch("doQuery")
  },
  addAggregate ({ state, commit, dispatch }, newAggregate) {
    commit("setAggregates", [...state.queryAggregates, newAggregate])
    dispatch("doQuery")
  },
  removeAggregate ({ state, commit, dispatch }, aggregateToRemove) {
    commit("setAggregates", without(aggregateToRemove, state.queryAggregates))
    dispatch("doQuery")
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
