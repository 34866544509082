<template>
  <b-card class="rounded-border w-100" :class="{ 'h-100': !previewMode }">
    <template v-if="!previewMode" v-slot:header>
      <div class="w-100 d-flex justify-content-between cursor-pointer">
        <div :id="'chartTitle-'+widget.id"
             class="mb-0 d-flex align-items-center slate-80"
        >
          {{ getWidgetLabel }}
          <img
            :id="`chartInfo-${widget.id}`"
            class="ml-1 action-icon cursor-pointer"
            src="/img/icons/info-icon.svg"
          />

          <b-tooltip v-if="widgetDescription"
                     :id="'chartDescription-' + widget.id"
                     :target="`chartInfo-${widget.id}`"
          >
            <div class="text-left tooltip-custom-heading">
              {{ widget.title }}
            </div>
            <div class="text-left tooltip-custom-body">
              {{ widgetDescription }}
            </div>
          </b-tooltip>
        </div>

        <div class="d-flex justify-content-between my-auto">
          <b-alert
            v-model="widget.alertCountDown"
            variant="success"
            size="sm"
            class="my-auto alert-class p-0"
            fade
          >
            {{ getAlertLabel }}
          </b-alert>
          <b-dropdown :id="'widget-action-dropdown-202112031303-'+widget.id"
                      v-if="!isNewConversationPage && canModifyWidgetPage()"
                      class="my-auto px-1 bao-widget-display-dropdown-202112101416"
                      toggle-class="text-decoration-none"
                      variant="link"
                      size="sm"
                      no-caret
                      right
          >
            <template #button-content>
              <img :id="'ellipsisIcon-' + widget.id"
                   src="/img/icons/points-icon.svg"
                   class="action-icon"
              />
              <b-tooltip :target="'ellipsisIcon-' + widget.id">
                {{ staticText.tooltipMoreText }}
              </b-tooltip>
            </template>
            <b-dropdown-item>
              <b-btn
                variant="btn-outline"
                class="p-0 w-100"
                @click="handleWidgetCloned(widget)"
              >
                <i class="fas fa-solid fa-clone my-auto text-slate"/>
                <span>
                  {{ staticText.duplicate }}
                </span>
              </b-btn>
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item>
              <bao-delete-button
                :id="'widget-delete-btn-' + widget.id"
                :label="staticText.delete"
                variant="btn-outline"
                extra-class="p-0 w-100 text-slate"
                icon="fa fa-trash text-slate"
                class="w-100"
                :url="'/api/widgets/'+widget.id"
                @deleted="handleWidgetDeleted(widget.id)"
              >
              </bao-delete-button>
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item>
              <b-btn
                variant="btn-outline"
                class="p-0 w-100"
                @click="downloadWidgetAsPNG(widget)"
              >
                <i class="fas fa-solid fa-download my-auto text-slate"/>
                <span>
                  {{ staticText.download }}
                </span>
              </b-btn>
            </b-dropdown-item>
          </b-dropdown>
          <b-btn
            v-if="!isNewConversationPage && canModifyWidgetPage()"
            variant="btn-outline"
            class="p-0 w-100"
            @click="openWidgetConfigurator(widget)"
          >
            <img :id="'edit-icon-202208040043'+widget.id"
                 src="/img/icons/edit-icon.svg"
                 class="action-icon"
            />
            <b-tooltip :target="'edit-icon-202208040043'+widget.id">
              {{ staticText.tooltipTextEdit }}
            </b-tooltip>
          </b-btn>
        </div>
      </div>
    </template>

    <div :id="'chart-'+widget.id" class="w-100 h-100 my-auto mx-auto">
      <slot v-if="!previewMode" name="filters">
        <bao-widget-criteria-display class="pb-2" :widget="widget"></bao-widget-criteria-display>
        <bao-widget-filters-display class="pb-2" :widget="widget"></bao-widget-filters-display>
      </slot>

      <slot name="additional-info">
        <div
          v-if="shouldShowTotalTime"
          class="w-100 d-flex justify-content-end legends font-size-16 slate-80"
        >
          {{ staticText.total }} {{ totalItems }}
        </div>
      </slot>

      <slot name="chart"></slot>

      <slot
        v-if="showGoToConversationsButton"
        name="callHistory"
      >
        <button
          class="mt-3 float-right btn btn-secondary py-2 px-3"
          @click="showConversations"
        >
          {{ staticText.showConversationsLabel }}
        </button>
      </slot>
    </div>
  </b-card>
</template>

<script>

import { mapActions, mapGetters } from "vuex"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import BaoWidgetFiltersDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetFiltersDisplay"
import BaoWidgetCriteriaDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetCriteriaDisplay"
import {
  OBJECT_DATA_TYPE_LABEL,
  transformFilters,
  transformSuccessCriteria
} from "@/apps/call_history/CallListComponents/filtersUtils.js"

export default {
  name: "BaoWidgetDisplay",
  components: { BaoDeleteButton, BaoWidgetFiltersDisplay, BaoWidgetCriteriaDisplay },
  props: {
    widget: {
      type: Object,
      required: true
    },
    totalItems: {
      type: [Number, String],
      required: false,
      default: -1
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false
    },
    showConversationsView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        tooltipMoreText: "More actions",
        tooltipTextEdit: "Edit widget",
        delete: "Delete",
        updated: "Updated",
        added: "Added",
        duplicate: "Duplicate",
        download: "Download",
        total: "Total:",
        showConversationsLabel: "Show Conversations"
      }
    }
  },
  computed: {
    ...mapGetters({
      isNewConversationPage: "callSetUpStore/getIsCallSetUpPage",
      canModifyWidgetPage: "dashboardStore/canModifyWidgetPage"
    }),
    widgetDescription () {
      if (this.widget.notes) {
        return this.widget.notes
      }
      return this.widget.description
    },
    getAlertLabel () {
      if (this.widget.added) {
        return this.staticText.added
      }
      return this.staticText.updated
    },
    getWidgetLabel () {
      if (this.widget.name) {
        return this.widget.name
      }
      return this.widget.title
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showGoToConversationsButton () {
      return this.showConversationsView && !this.previewMode
    },
    shouldShowTotalTime () {
      return typeof this.totalItems !== "number" || this.totalItems >= 0
    }
  },
  methods: {
    ...mapActions({
      handleWidgetDeleted: "dashboardStore/handleWidgetDeleted",
      openWidgetConfigurator: "dashboardStore/openWidgetConfigurator",
      handleWidgetCloned: "dashboardStore/handleWidgetCloned",
      downloadWidgetAsPNG: "dashboardStore/downloadWidgetAsPNG"
    }),
    showConversations () {
      const widgetConfig = this.widget.config
      const transformedFilters = widgetConfig.filters ? transformFilters({ dataType: OBJECT_DATA_TYPE_LABEL, activeFilters: widgetConfig.filters }) : {}
      const transformedSuccessCriterias = widgetConfig.successCriteria ? transformSuccessCriteria({ successCriteria: widgetConfig.successCriteria, transformedFilters }) : transformedFilters
      this.$router.push({ path: "/calls", query: transformedSuccessCriterias })
    }
  }
}
</script>
<style lang="scss">
.bao-widget-display-dropdown-202112101416 {
  button {
    padding: 0 !important;
  }
}
</style>

<style scoped lang="scss">

.rounded-border {
  border-radius: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  line-height: 19px;
  background: $grey;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  color: $slate80;
}

.alert-class {
  color: $green80;
  border-radius: 6px;
}

.dropdown-toggle.btn {
  padding: 0 !important;
}

.tooltip-custom-heading {
  color: $slate80;
  font-size: 14px;
  padding: 0.25rem;
}

.tooltip-custom-body {
  color: $black50;
  font-size: 14px;
  padding: 0.25rem;
}

.slate-80 {
  color: $slate80;
}

.action-icon {
  color: $slate;
  opacity: 0.7;
  height: 15px;
  width: 15px;
  &:hover {
    opacity: 1.0;
  }
}

.font-size-16 {
  font-size: 16px;
}

</style>
