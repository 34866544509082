const IS_NAVBAR_OPEN_KEY = "bao_is_navbar_open"

const state = {
  mainMenuOpen: !!JSON.parse(localStorage.getItem(IS_NAVBAR_OPEN_KEY)) || false,
  closeBtnShown: true
}

export const getters = {
  isMainMenuOpen: state => state.mainMenuOpen,
  isCloseBtnShown: state => state.closeBtnShown
}

export const mutations = {
  changeMainMenu: (state, open) => {
    state.mainMenuOpen = open
    localStorage.setItem(IS_NAVBAR_OPEN_KEY, open)
  },
  changeCloseBtnState: (state, show) => {
    state.closeBtnShown = show
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
