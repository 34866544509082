export const tooltipDelay = {
  show: 250,
  hide: 50
}

export const colors = {
  bao: {
    orange: "#ef702d",
    darkblue: "#1B1D35",
    lightblue: "#3B3D85",
    statsblue: "#59a4ff"
  }
}
