<template>
  <div :class="['bao-widget-filter w-100 d-flex', { 'advanced-filters' : isOpenFromAdvancedFilters }]">
    <div class="filter-body w-100">
      <label
        id="label-percentage-filter-202436956"
        for="percentage-filter-202436956"
        class="w-100 d-flex justify-content-between filter-title"
      >
        {{ percentageFilterLabel }}
      </label>
      <b-form-row class="mb-1 w-100 d-flex">
        <div class="w-50 p-1 inner-label-form" :class="{ 'error-class': errorMessage }">
          <label
            for="percentage-min-id-202205261600"
            class="my-auto inner-label"
          >
            {{ staticText.minPercentage }}
          </label>
          <b-form-input
            id="percentage-min-id-202205261600"
            v-model="minValue"
            :placeholder="staticText.minPercentage"
            type="number"
            min=0
            max="100"
            oninput="validity.valid||(maxValue=100)"
            @change="handleFilterChanged"
          ></b-form-input>
        </div>
        <div class="w-50 p-1 inner-label-form" :class="{ 'error-class': errorMessage }">
          <label
            for="percentage-max-id-202205261600"
            class="my-auto inner-label"
          >
            {{ staticText.maxPercentage }}
          </label>
          <b-form-input
            id="percentage-max-id-202205261600"
            v-model="maxValue"
            :placeholder="staticText.maxPercentage"
            type="number"
            min=0
            max="100"
            oninput="validity.valid||(maxValue=100)"
            @change="handleFilterChanged"
          ></b-form-input>
        </div>
      </b-form-row>
      <p
        v-if="errorMessage"
        class="filter-error-text"
      >
        {{ errorMessage }}
      </p>
    </div>
    <div @click.prevent="$emit('delete')"
         class="ml-3 filter-delete"
    >
      <i
        v-if="!isOpenFromAdvancedFilters"
        class="fa fa-trash my-auto"
      ></i>
      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
    </div>
  </div>
</template>

<script>

export default {
  name: "PercentageFilter",
  components: {},
  props: {
    value: { // Format { max:<number> min: <number>} between 0 to 100.
      type: Object,
      required: false
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    filterLabel: {
      type: String,
      required: false
    }
  },
  data: () => {
    return {
      staticTextDefault: {
        label: "Text-Match",
        maxPercentage: "Max",
        minPercentage: "Min",
        negativeValueMessage: "Please don’t insert negative values.",
        minMaxErrorMessage: "Minimum value cannot be more than maximum.",
        noValueErrorMessage: "Please, input values before saving changes.",
        maxLimitErrorMessage: "Please, input values between 0 and 100."
      },
      maxValue: 100,
      minValue: 0,
      errorMessage: null
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    percentageFilterLabel () {
      return this.filterLabel ? `${this.filterLabel} (%)` : `${this.staticText.label} (%)`
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    setUp () {
      if (this.value) {
        this.minValue = this.numberExists(this.value.min_value) ? this.value.min_value : null
        this.maxValue = this.numberExists(this.value.max_value) ? this.value.max_value : null
      }
    },
    handleFilterChanged () {
      const data = {}
      if (this.numberExists(this.minValue)) data.min_value = this.minValue
      if (this.numberExists(this.maxValue)) data.max_value = this.maxValue
      this.validateFilter()
      if (this.errorMessage) return
      this.$emit("input", data)
    },
    validateFilter () {
      this.errorMessage = null
      if (!this.numberExists(this.minValue) && !this.numberExists(this.maxValue)) {
        this.errorMessage = this.staticText.noValueErrorMessage
      } else if (Number(this.maxValue) < 0 || Number(this.minValue) < 0) {
        this.errorMessage = this.staticText.negativeValueMessage
      } else if (this.numberExists(this.minValue) && this.numberExists(this.maxValue)) {
        if (Number(this.maxValue) < Number(this.minValue)) {
          this.errorMessage = this.staticText.minMaxErrorMessage
        }
        if (Number(this.maxValue) > 100 || Number(this.minValue) > 100) {
          this.errorMessage = this.staticText.maxLimitErrorMessage
        }
      }

      return !this.errorMessage
    },
    numberExists (number) {
      return number || number === 0
    }
  }
}
</script>

<style scoped lang="scss">

.optional-text {
  font-size: 14px;
  color: $slate40;
}

.header-text {
  font-size: 20px;
  color: $black;
}
</style>
