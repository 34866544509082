<template>
  <div class="bao-widget-filter w-100 d-flex">
    <bao-widget-filter-container
      :label="staticText.selectTags"
      label-tag="tags-filter"
      :hasError="hasError"
    >
      <vue-multiselect
        id="tags-filter"
        v-model="selectedTags"
        :options="options"
        :show-labels="false"
        :multiple="true"
        :placeholder="staticText.selectTags"
        :internal-search="true"
        :hide-selected="true"
        :clear-on-select="false"
        :close-on-select="false"
        :show-no-results="false"
        :group-select="false"
        :loading="isLoading"
        :custom-label="item => item.tag"
        group-label="group"
        group-values="tags"
        track-by="name"
        @input="handleNewTagSelected"
      >
        <template slot="tag"
                  slot-scope="{ option, remove }"
        >
          <span class="custom__tag d-flex justify-content-between my-1">
            <span class="w-100">
              <span class="option__small">
                {{ option.label }}
              </span>
            </span>

            <span class="custom__remove"
                  @click.stop="remove(option)"
            >
              <i class="far fa-times-circle"></i>
            </span>
          </span>
        </template>
        <span slot="noOptions">
          {{ staticText.listIsEmpty }}
        </span>
      </vue-multiselect>
    </bao-widget-filter-container>
    <div @click.prevent="$emit('delete')"
         class="ml-3 filter-delete"
    >
      <i
        class="fa fa-trash my-auto"
      ></i>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import VueMultiselect from "vue-multiselect"
import BaoWidgetFilterContainer from "./BaoWidgetFilterContainer.vue"

export default {
  name: "TagsFilter",
  components: { VueMultiselect, BaoWidgetFilterContainer },
  props: {
    value: { // list of tags text
      required: false,
      default: function () { return [] }
    }
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        selectTags: "Select Tags"
      },
      selectedTags: [],
      isLoading: false,
      options: [],
      hasError: false
    }
  },
  computed: {
    getUrl () {
      return "/api/tags/get_non_system_tags"
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    return this.search().then(() => {
      this.setSelectedTags()
    })
  },
  methods: {
    handleNewTagSelected () {
      this.$emit("input", this.selectedTags.map(tag => tag.name))
      if (this.selectedTags.length > 0) this.validateFilter()
    },
    search (searchTerm) {
      this.isLoading = true
      let url = this.getUrl
      if (searchTerm) {
        url = url + `&name__icontains=${searchTerm}`
      }
      return this.axios.get(url).then(response => {
        this.options = this.transformToDisplay(response.data)
        this.isLoading = false
      })
    },
    transformToDisplay (data) {
      // get rid of group tags (like item_type)
      const filteredData = data.filter(option => option.children.length === 0)
      // add group, tag and label to the options
      // label is for displaying
      // option.label = Group: SubGroup: Tag
      const options = filteredData.map(option => {
        const group = this.getGroup(option.name)
        const tagLabel = this.toPascalCase(option.label)
        const label = this.getDisplayTag(group, tagLabel)
        return { ...option, group, tag: tagLabel, label }
      })
      return this.groupOptions(options)
    },
    groupOptions (options) {
      // transform options from this structure:
      // [ { name1, group1, tag1, ... }, { name2, group2, tag2, ... } ]
      // to this:
      // [ { group: "group1", tags: [ { name1, group1, tag1, ... }, { ... } ] },
      //   { group: "group2", tags: [ { name2, group2, tag2, ... }, { ... } ] }]
      const groupedOptions = {}
      for (const option of options) {
        if (!(option.group in groupedOptions)) groupedOptions[option.group] = { group: option.group, tags: [] }
        groupedOptions[option.group].tags.push(option)
      }
      return Object.values(groupedOptions)
    },
    getDisplayTag (group, tag) {
      // returns tag if group is empty, "group: tag" otherwise
      if (group === null || group === undefined || group.length === 0) return tag
      return `${group}: ${tag}`
    },
    getGroup (name) {
      // returns group in pascal case
      // group_number_1/group_number_2/my_tag => Group Number 1: Group Number 2
      return name.split("/").slice(0, -1).map(str => this.toPascalCase(str)).join(": ")
    },
    setSelectedTags () {
      if (this.value) {
        this.selectedTags = this.options
          .flatMap(option => option.tags)
          .filter(
            option => this.value.indexOf(option.name) > -1
          )
      }
    },
    validateFilter () {
      this.hasError = !this.selectedTags.length
      return !this.hasError
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
