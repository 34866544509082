export const USER_DATA_TITLE = { en: "User's Data", de: "Userdaten" }
export const CRM_DATA_TITLE = { en: "CRM Data", de: "CRM-Daten" }
export const OTHER_ITEMS_DATA_TITLE = { en: "Other Item's Data", de: "Daten von anderen Items" }

export const FIRST_NAME_KEY = { en: "First Name", de: "Vorname" }
export const LAST_NAME_KEY = { en: "Last Name", de: "Nachname" }
export const CURRENT_DATE_KEY = { en: "Current Date", de: "Aktuelles Datum" }
export const CURRENT_TIME_KEY = { en: "Current Time", de: "Aktuelle Uhrzeit" }
export const COUNTERPART_NAME_KEY = { en: "Counterpart", de: "Gesprächspartner" }
export const COUNTERPART_FIRST_NAME_KEY = { en: "Counterpart's First Name", de: "Vorname des Gesprächspartners" }
export const COUNTERPART_LAST_NAME_KEY = { en: "Counterpart's Last Name", de: "Nachname des Gesprächspartners" }
export const CRM_NAME_KEY = { en: "CRM", de: "CRM" }
export const ANSWER_KEY = { en: "Answer", de: "Antwort" }
export const NOTE_KEY = { en: "Note", de: "Notiz" }
export const ANSWER_FROM_KEY = { en: "Answer from", de: "Antwort von" }
export const NOTE_FROM_KEY = { en: "Note from", de: "Notiz von" }

export const GROUP_TYPE = {
  USER_DATA: USER_DATA_TITLE.en,
  CRM_DATA: CRM_DATA_TITLE.en,
  OTHER_ITEM_DATA: OTHER_ITEMS_DATA_TITLE.en
}

export const OTHER_ITEM_DATA_TYPE = {
  ANSWER: ANSWER_KEY.en,
  NOTE: NOTE_KEY.en
}
