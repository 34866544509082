<template>
  <div>
    <div class="inner-label-form p-1" :class="{ 'error-class': hasError }">
      <label class="my-auto inner-label">
        {{ configOption.label }}
      </label>
      <b-form-input
        v-if="configOption.isText"
        v-model="configOption.value"
        :type="configOption.type"
        @change="configChanged(configOption.value)"
      ></b-form-input>

      <b-form-input
        v-if="configOption.isNumber"
        v-model="configOption.value"
        :type="configOption.type"
        min="1"
        oninput="validity.valid||(value=1)"
        @change="configChanged(configOption.value)"
      ></b-form-input>

      <b-form-textarea
        v-else-if="configOption.isTextArea"
        v-model="configOption.value"
        rows="3"
        max-rows="3"
        @change="configChanged(configOption.value)"
      ></b-form-textarea>
    </div>
    <p
      v-if="hasError"
      class="filter-error-text"
    >
      {{ staticText.filterErrorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BasicTypeFilter",
  data () {
    return {
      configOption: {},
      hasError: false,
      staticTextDefault: {
        filterErrorMessage: "Please, select a value before saving changes."
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  mounted () {
    this.configOption = this.value
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    configChanged (configValue) {
      this.$emit("change", configValue)
      this.validateFilter()
    },
    validateFilter () {
      this.hasError = !this.configOption.value
      return !this.hasError
    }
  }
}

</script>

<style></style>
