<template>
  <div id="loading-screen-202101082117"
       class="loading-screen-color progress-indicator"
       :class="{'small': small}"
  >
    <div class="d-flex justify-content-center">
      <slot name="before" v-bind:component="this"></slot>
      <b-spinner id="redirecting-spinner-202101081826"
                 :small="small"
                 class="my-auto"
      ></b-spinner>
      <slot name="label" v-bind:component="this">
        <h4 id="redirecting-label-202101081826"
            class="ml-4 my-auto"
            v-if="showLoadingLabel"
        >
          {{ finalLoadingLabel }}
        </h4>
      </slot>
      <slot
        v-if="tooltip"
        name="tooltip"
        v-bind:component="this"
      >
        <b-tooltip target="redirecting-spinner-202101081826">
          {{ finalLoadingLabel }}
        </b-tooltip>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressIndicator",
  props: {
    showLoadingLabel: {
      type: Boolean,
      default: true,
      required: false
    },
    loadingLabel: {
      type: String,
      default: null,
      required: false
    },
    small: {
      type: Boolean,
      default: false,
      required: false
    },
    tooltip: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        loadingLabel: "Loading..."
      }
    }
  },
  computed: {
    finalLoadingLabel () {
      if (this.loadingLabel) return this.loadingLabel
      return this.staticText.loadingLabel
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">
.loading-screen-color {
  color: #ef702d
}

.progress-indicator {
  margin: 48px auto;
  &.small {
    margin: 0 auto;
  }
}
</style>
