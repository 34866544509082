import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import { getApexLineChartDefaultOptions } from "@/apps/dashboard/chartConfig"
import {
  computeDateLabelForData,
  createdDateArray,
  FILTER_KEY_TIMEFRAME,
  getBurntUpChartData,
  timeframes,
  today
} from "@/apps/dashboard/chartUtils"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const ApexLineChart = () => import(/* webpackChunkName: "ApexLineChart" */ "../BaoWidgetsBaseComponents/ApexCharts/ApexLineChart")

export default {
  name: "BaoWidgetSuccessGoal",
  info: {
    title: "Success Goal",
    description: "Target number and status quo of successful calls for a given time period."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getApexLineChartDefaultOptions,
      createdDateArray,
      getBurntUpChartData,
      today,
      chartComponent: ApexLineChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      transformedData: {},
      computeDateLabelForData,
      staticTextDefault: {
        actualGrowth: "Actual Growth",
        expectedGrowth: "Expected Growth",
        target: "Target",
        today: "Today"
      },
      chartStyle: { "aspect-ratio": 1.5 }
    }
  },
  computed: {
    todayInString () {
      return this.today.toLocaleString("default", { month: "short", day: "numeric" })
    },
    getTodayCallsCount () {
      const index = this.transformedData.labels.findIndex(item => item === this.todayInString)
      if (index > -1) {
        return this.transformedData.actualSeries[index]
      }
      return 0
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    mappingFunc (items) {
      const computedDateLabels = this.computeDateLabelForData(items, this.widget, true)
      const data = computedDateLabels.map(item => {
        return { text: item.text, actualData: item.count }
      })
      this.transformedData = this.getBurntUpChartData(data, items.goal)
      return [
        {
          name: this.staticText.actualGrowth,
          data: this.transformedData.actualSeries
        },
        {
          name: this.staticText.expectedGrowth,
          data: this.transformedData.idealSeries
        }
      ]
    },
    getChartOptions () {
      return { ...this.getApexLineChartDefaultOptions(this.widget, this.transformedData.labels), ...this.getAnnotations() }
    },
    getConfigOptions () {
      return [
        {
          type: "filterOptions",
          whitelist: ["tags", "user", "duration", "timeframe"],
          defaultFilters: { [FILTER_KEY_TIMEFRAME]: timeframes.thisMonth }
        },
        {
          type: "criteriaOptions",
          value: { type: "callResult" },
          options: ["callResult", "callDuration", "answer"],
          fieldName: "successCriteria"
        },
        {
          label: this.staticText.target,
          type: "number",
          value: 20, // default
          fieldName: "goal"
        }
      ]
    },
    getAnnotations () {
      return {
        annotations: {
          xaxis: [{
            x: this.todayInString,
            strokeDashArray: 1,
            borderColor: "rgba(0, 0, 0, 0.1)",
            label: {
              offsetY: 15,
              orientation: "horizontal",
              borderColor: "#ffffff",
              text: this.staticText.today
            }
          }
          ],
          points: [{
            x: this.transformedData.labels.at(-1),
            y: this.widget.config.goal,
            marker: {
              size: 6,
              colors: "#ffffff",
              strokeColor: "rgba(31, 179, 9, 1)",
              shape: "circle",
              radius: 2
            },
            image: {
              path: "/img/euro-sign-solid.svg"
            }
          },
          {
            x: this.todayInString,
            marker: {
              size: 4,
              colors: "#ffffff",
              strokeColor: "rgba(255, 86, 50, 0.4)",
              shape: "circle",
              radius: 2
            }
          },
          {
            x: this.todayInString,
            y: this.getTodayCallsCount,
            marker: {
              size: 4,
              colors: "#ffffff",
              strokeColor: "rgba(255, 86, 50, 0.4)",
              shape: "circle",
              radius: 2
            }
          }]
        }
      }
    },
    getDisplayProps () {
      return { widget: this.widget }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
