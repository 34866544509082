const BaoValueConfigurator = () => import(/* webpackChunkName: "BaoValueConfigurator" */ "./BaoValueList.vue")

// exports basic app infos required for other modules and CallViewItem_components
const baseUrl = "/baovalue"

export default {
  name: "BaoValue",
  apiUrl: "/api/baovaluemapping",
  route: {
    path: baseUrl,
    component: BaoValueConfigurator
  }
}
