<template>
  <bao-widget-display :widget="widget" :previewMode="previewMode" :showConversationsView="showConversationsView">
    <template v-slot:chart>
      <div>
        <slot name="chart"></slot>
      </div>
    </template>
  </bao-widget-display>
</template>

<script>

import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

export default {
  name: "BaoWidgetConversationGoalDisplay",
  components: { BaoWidgetDisplay },
  props: {
    widget: {
      type: Object,
      required: true
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false
    },
    showConversationsView: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.message{
  font-size: 16px;
  font-weight: bold;
  color: $slate;
}
</style>
