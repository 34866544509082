import Vue from "vue"
import VueRouter from "vue-router"
import store from "./store"

import playbookApp from "./apps/talkscript"
import counterpartApp from "./apps/counterpart"
import newCallApp, { callMainApp } from "./apps/call"
import callHistoryApp from "./apps/call_history"
import mainApp from "./apps/base"
import userApp from "./apps/user"
import dashboardApp from "./apps/dashboard"
import BaoValue from "./apps/baovalue"
import CallResult from "./apps/callresult"
import CoPilot from "./apps/co_pilot"
import { externalShareComponentUrl } from "@/apps/call_sharing"

const OauthLoginHandler = () => import(/* webpackChunkName: "OauthLoginHandler" */ "./apps/IntegrationHandlers/OauthLoginHandler.vue")
const DRFCharts = () => import(/* webpackChunkName: "DRFCharts" */ "./apps/DRFCharts/DRFCharts.vue")
const MainContainer = () => import(/* webpackChunkName: "MainContainer" */ "./apps/MainApp.vue")
const printCallSummary = () => import(/* webpackChunkName: "printCallSummary" */ "./apps/call_history/CallPrintView.vue")
const LoginView = () => import(/* webpackChunkName: "LoginView" */ "./apps/base/LoginPage.vue")
const PasswordReset = () => import(/* webpackChunkName: "PasswordReset" */ "./apps/base/PasswordResetPage.vue")
const PlentyMarketsLogin = () => import(/* webpackChunkName: "PlentyMarketsLogin" */ "./apps/IntegrationHandlers/PlentyMarketsLogin.vue")
const Dynamics365ResourceSelector = () => import(/* webpackChunkName: "Dynamics365ResourceSelector" */ "./apps/IntegrationHandlers/Dynamics365ResourceSelector.vue")
const SugarCrmLogin = () => import(/* webpackChunkName: "Dynamics365ResourceSelector" */ "./apps/IntegrationHandlers/SugarCrmLogin.vue")
const SharedCall = () => import(/* webpackChunkName: "SharedCall" */ "./apps/call_sharing/SharedCall.vue")

export const registeredApps = [
  mainApp, newCallApp, callHistoryApp, playbookApp, counterpartApp, userApp, dashboardApp, BaoValue, CallResult, CoPilot
]

let mainContainerChildren = []
for (const index in registeredApps) {
  if (registeredApps[index].route) {
    mainContainerChildren.push(registeredApps[index].route)
  }
  if (registeredApps[index].routes && Array.isArray(registeredApps[index].routes)) {
    mainContainerChildren = mainContainerChildren.concat(registeredApps[index].routes)
  }
}

const ifNotAuthenticated = (to, from, next) => {
  const userLoggedIn = store.getters["auth/isUserAuthenticated"]
  if (!userLoggedIn) {
    // The route has to be replaced when the application is in an iFrame which would be true if window.self !== window.top
    const nextData = { path: "/login", replace: window.self !== window.top }
    if (to.fullPath && to.fullPath.length > 1) {
      nextData.query = { redirect: to.fullPath }
    }
    next(nextData)
  } else {
    const user = store.getters["auth/user"]
    window.sendinblue.identify(user.email, user)
    next()
  }
}

mainContainerChildren.push({
  name: callMainApp.name,
  path: callMainApp.path,
  component: callMainApp.component,
  beforeEnter: ifNotAuthenticated
})

const baseRoutes = [
  {
    path: "/login",
    component: LoginView
  },
  {
    path: "/password_change/:token",
    component: PasswordReset
  },
  {
    path: "/",
    component: MainContainer,
    children: mainContainerChildren,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/print-summary/:callId",
    component: printCallSummary,
    props: true,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/oauthlogin/:service",
    component: OauthLoginHandler,
    props: true
  },
  {
    path: "/plentymarketslogin",
    component: PlentyMarketsLogin
  },
  {
    path: "/sugarcrmlogin",
    component: SugarCrmLogin
  },
  {
    path: "/dynamics365Resource",
    component: Dynamics365ResourceSelector
  },
  {
    path: "/bao-qe",
    component: DRFCharts,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: `${externalShareComponentUrl}/:id`,
    component: SharedCall
  },
  {
    path: "*",
    redirect: "/"
  }
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: baseRoutes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition
    if (to.hash) return { selector: to.hash }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  window.sendinblue.page(to.path, { full_path: to.fullPath })
  next()
})

export default router
