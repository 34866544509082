const PlaybookConfigurator = () => import(/* webpackChunkName: "PlaybookConfigurator" */ "./PlaybookConfigurator.vue")
const PlaybookList = () => import(/* webpackChunkName: "PlaybookList" */ "./PlaybookList.vue")
const PlaybookItemLibrary = () => import(/* webpackChunkName: "PlaybookItemList" */ "./PlaybookItemLibrary.vue")

// exports basic app infos required for other modules and CallViewItem_components

// think of a better solution than to having both routes and url separated!
const baseUrl = "/templates"
export default {
  name: "Playbook",
  apiUrl: {
    playbook: "/api/talkscripts",
    playbookItem: "/api/talkscriptitems",
    objectionChoice: "/api/objectionchoices",
    playbookExtend: "/api/talkscriptextend",
    pdfdemofile: "/api/pdfdemofile",
    libraryItems: "/api/libraryitems",
    playbookClone: "/api/talkscripts/clone_talkscript"
  },
  route: {
    path: baseUrl,
    component: PlaybookList
  },
  routes: [
    {
      path: "/itemlibrary",
      component: PlaybookItemLibrary
    },
    {
      path: baseUrl + "/edit/:id",
      component: PlaybookConfigurator
    }
  ]
}
