import debounce from "lodash/debounce"
import axios from "axios"
import { findCRMLookupFieldValueById } from "@/store/utils"

export const updateCounterpartsWithLoadedDataWithBaoRecord = async (counterpartsWithLoadedData) => {
  const crmService = "dynamics365"

  for (const [key, counterpart] of Object.entries(counterpartsWithLoadedData)) {
    if (key.startsWith(`${crmService}-`)) {
      const externalResource = counterpart.external_resource
      if (!externalResource) continue

      let baoRecordCrmId, baoRecordType, baoRecordData

      for (const resourceKey in externalResource) {
        const match = resourceKey.match(/^(.*)_baorecord_value$/)
        if (match) {
          baoRecordType = `${match[1].split("_")[1]}_baorecord`
          baoRecordCrmId = externalResource[resourceKey]
          break
        }
      }
      // Note: All counterparts of various types can have a linked bao record,
      // but we can only display data for one bao record at a time
      if (!baoRecordCrmId) break // CRM Id value can be null or undefined

      if (baoRecordCrmId && baoRecordType) {
        baoRecordData = await findCRMLookupFieldValueById(crmService, baoRecordType, baoRecordCrmId)
        baoRecordData.crmDataLoaded = counterpart.crmDataLoaded
        counterpartsWithLoadedData[`${crmService}-${baoRecordType}`] = baoRecordData

        break // Update only for the first counterpart with the bao record
      }
    }
  }

  return counterpartsWithLoadedData
}

const state = {
  crmsSupportingLoadingOfData: { hubspot: true, salesforce: true, pipedrive: true, dynamics365: true, zendesk: true },
  loading: false,
  errors: null,
  searchRequest: null,
  triggerLoading: false,
  counterpartOptions: [],
  counterpartsWithSameCrmTypeAndService: null,
  counterpartsWithLoadedData: null,
  maxCrmServiceOfSameTypeCount: Number.NEGATIVE_INFINITY
}

const getters = {
  CRMSupportsLoadingOfData: (state) => (counterpart) => {
    if (counterpart && counterpart.crm_service) {
      const counterpartCrmService = counterpart.crm_service
      return !!state.crmsSupportingLoadingOfData[counterpartCrmService]
    }
    return false
  },
  getLoading (state) {
    return state.loading
  },
  getErrors (state) {
    return state.errors
  },
  getCounterpartOptions (state) {
    return state.counterpartOptions
  },
  getCounterpartsWithSameCrmTypeAndService (state) {
    return state.counterpartsWithSameCrmTypeAndService
  },
  getMaxCrmServiceOfSameTypeCount (state) {
    return state.maxCrmServiceOfSameTypeCount
  },
  getCounterpartsWithLoadedData (state) {
    return state.counterpartsWithLoadedData
  }
}

const mutations = {
  setLoading (state, value) {
    state.loading = value
  },
  setTriggerLoading (state, value) {
    state.triggerLoading = value
  },
  setErrors (state, value) {
    state.errors = value
  },
  setCounterpartOptions (state, value) {
    state.counterpartOptions = value
  },
  setSearchRequest (state, value) {
    state.searchRequest = value
  },
  setCounterpartsWithSameCrmTypeAndService (state, value) {
    state.counterpartsWithSameCrmTypeAndService = value
  },
  setMaxCrmServiceOfSameTypeCount (state, value) {
    state.maxCrmServiceOfSameTypeCount = value
  },
  setCounterpartsWithLoadedData (state, value) {
    state.counterpartsWithLoadedData = value
  }
}

const actions = {
  asyncSearch: debounce(function ({ dispatch }, query) {
    dispatch("searchTerm", query)
  }, 300),
  async updateCounterpartsWithLoadedData ({ commit }, value) {
    const updatedValue = await updateCounterpartsWithLoadedDataWithBaoRecord(value)
    commit("setCounterpartsWithLoadedData", updatedValue)
  },
  searchTerm ({ state, dispatch, commit }, query) {
    // we cancel the current searchRequest in favor of a new one (either searching or retrieving all contacts)
    if (state.searchRequest && state.searchRequest.cancel) state.searchRequest.cancel()

    // In most of the CRM services, search results are returned only if more than one query characters are provided.
    if (!query || query.length < 3) {
      if (!query) {
        // Reset counterparts list
        commit("setCounterpartOptions", [])
      }
      commit("setLoading", false)
      commit("setSearchRequest", null)
      return
    }

    commit("setLoading", true)
    commit("setTriggerLoading", true)
    commit("setSearchRequest", axios.CancelToken.source())

    return axios.get(
      "/api/counterpartsnew/search?q=" + query,
      { cancelToken: state.searchRequest.token } // allows us to cancel the request in case there is a newer one
    ).then(response => {
      commit("setCounterpartOptions", response.data.records)
      commit("setErrors", null)
      if (response.data.errors && Object.keys(response.data.errors).length > 0) {
        commit("setErrors", response.data.errors)
      }
      commit("setLoading", false)
      commit("setSearchRequest", null) // searchRequest was completed, we reset the variable
    })
  },

  getNewCounterparts ({ state, commit }) {
    /**
     * retrieves first page with five counterparts per page.
     * */
    commit("setErrors", null)
    commit("setLoading", true)
    return axios.get("/api/counterpartsnew?page=1&page_size=5"
    ).then(response => {
      if (response.data.results && response.data.results.length > 0) {
        commit("setCounterpartOptions", response.data.results)
      }
      commit("setLoading", false)
      commit("setTriggerLoading", false)
    }).catch(errors => {
      commit("setErrors", { ...state.errors, ...errors.response.data })
    })
  },
  sortCounterparts ({ state, getters, commit, dispatch }, { counterparts }) {
    const sortedCounterparts = {}
    const counterpartsWithSameCrmTypeAndService = {}
    const separatedCounterparts = {}
    let maxCrmServiceOfSameTypeCount = Number.NEGATIVE_INFINITY
    for (let [index, counterpart] of counterparts.entries()) {
      const counterpartCRM = counterpart.crm_service
      const counterpartType = counterpart.type
      counterpart = { ...counterpart, index }

      if (getters.CRMSupportsLoadingOfData(counterpart)) {
        if (!sortedCounterparts[counterpartCRM]) {
          sortedCounterparts[counterpartCRM] = {}
          sortedCounterparts[counterpartCRM][counterpartType] = [counterpart]
          separatedCounterparts[`${counterpartCRM}-${counterpartType}`] = counterpart
        } else {
          if (!sortedCounterparts[counterpartCRM][counterpartType]) {
            sortedCounterparts[counterpartCRM][counterpartType] = [counterpart]
            separatedCounterparts[`${counterpartCRM}-${counterpartType}`] = counterpart
          } else {
            sortedCounterparts[counterpartCRM][counterpartType].push(counterpart)
            separatedCounterparts[`${counterpartCRM}-${counterpartType}`] = null
            if (!counterpartsWithSameCrmTypeAndService[counterpartCRM]) counterpartsWithSameCrmTypeAndService[counterpartCRM] = {}
            counterpartsWithSameCrmTypeAndService[counterpartCRM][counterpartType] = [...sortedCounterparts[counterpartCRM][counterpartType]]
          }
        }

        const counterpartTypeLength = sortedCounterparts[counterpartCRM][counterpartType].length
        if (counterpartTypeLength > maxCrmServiceOfSameTypeCount) maxCrmServiceOfSameTypeCount = counterpartTypeLength
      }
    }
    commit("setCounterpartsWithSameCrmTypeAndService", counterpartsWithSameCrmTypeAndService)
    dispatch("updateCounterpartsWithLoadedData", separatedCounterparts)
    commit("setMaxCrmServiceOfSameTypeCount", maxCrmServiceOfSameTypeCount)
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
