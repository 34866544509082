import axios from "axios"

export const state = {
  isCallSetUpPage: false,
  counterparts: [],
  counterpartRefetching: false
}

export const getters = {
  getIsCallSetUpPage (state) {
    return state.isCallSetUpPage
  },
  getCounterparts (state) {
    return state.counterparts
  },
  getCounterpartRefetching (state) {
    return state.counterpartRefetching
  }
}

export const mutations = {
  setIsCallSetUpPage (state, booleanVal) {
    state.isCallSetUpPage = booleanVal
  },
  setCounterparts (state, counterparts) {
    state.counterparts = counterparts
  },
  setCounterpartRefetching (state, counterpartRefetching) {
    state.counterpartRefetching = counterpartRefetching
  },
  addCounterpart (state, newCounterpart) {
    state.counterparts.push(newCounterpart)
  },
  removeCounterpart (state, index) {
    state.counterparts.splice(index, 1)
  }
}

export const actions = {
  async getCounterpartsFromUrl ({ state, commit }, { counterpartParams }) {
    try {
      const crmService = counterpartParams.crm_service
      if (!crmService) {
        return
      }
      const url = "/api/counterpartsnew/get_counterpart?service=" + crmService
      const { data } = await axios.post(url, counterpartParams)
      const newCounterpart = data
      const counterpartIndex = state.counterparts.findIndex(counterpart => counterpart.id === newCounterpart.id)
      if (counterpartIndex === -1) commit("addCounterpart", newCounterpart)
    } catch (error) {
      console.error(error)
    }
  },
  async resetAndRefetchCounterpartFromUrl ({ commit, dispatch }, counterpartParams) {
    commit("setCounterpartRefetching", true)
    commit("setCounterparts", [])
    await dispatch("getCounterpartsFromUrl", { counterpartParams })
    commit("setCounterpartRefetching", false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
