<template>
  <div :class="['bao-widget-filter w-100 d-flex', { 'advanced-filters' : isOpenFromAdvancedFilters }]">
    <div class="filter-body w-100">
      <label
        :id="`label-available-filter-${filterType}`"
        :for="`available-filter-${filterType}`"
        class="w-100 d-flex justify-content-between filter-title"
      >
        {{ filterLabel }}
      </label>
      <b-form-row class="mb-1 w-100 d-flex">
        <div class="w-50 p-1 inner-label-form">
          <input
            type="radio"
            :id="`${filterType}-yes`"
            :name="`${filterType}-available`"
            :value="true"
            v-model="filterAvailable"
            @change="handleFilterChanged"
            class="inner-radio"
          >
          <label
            :for="`${filterType}-yes`"
            class="my-auto inner-label"
          >
            {{ staticText.yesLabel }}
          </label>
        </div>
        <div class="w-50 p-1 inner-label-form">
          <input
            class="inner-radio"
            type="radio"
            :id="`${filterType}-no`"
            :name="`${filterType}-available`"
            :value="false"
            v-model="filterAvailable"
            @change="handleFilterChanged"
          >
          <label
            :for="`${filterType}-no`"
            class="my-auto inner-label"
          >
            {{ staticText.noLabel }}
          </label>
        </div>
      </b-form-row>
      <p
        v-if="hasError"
        class="filter-error-text"
      >
        {{ staticText.filterErrorMessage }}
      </p>
    </div>
    <div @click.prevent="$emit('delete')"
         class="ml-3 filter-delete"
    >
      <i
        class="fa fa-trash my-auto"
      ></i>
    </div>
  </div>
</template>

<script>

export default {
  name: "AvailableFilter",
  props: {
    value: {
      type: Boolean,
      required: false
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    filterType: {
      type: String,
      required: true
    },
    filterLabel: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filterAvailable: this.value,
      hasError: false,
      staticTextDefault: {
        yesLabel: "Yes",
        noLabel: "No",
        filterErrorMessage: "Please select a value before saving changes."
      }
    }
  },
  computed: {
    staticText () {
      return {
        ...this.staticTextDefault,
        ...this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
      }
    }
  },
  methods: {
    handleFilterChanged () {
      this.$emit("input", this.filterAvailable)
      this.validateFilter()
    },
    validateFilter () {
      this.hasError = this.filterAvailable === null
      return !this.hasError
    }
  }
}
</script>

<style scoped>
    .inner-label-form label.inner-label{
        top: 9px;
    }
</style>
