<template>
  <component
    v-if="getComponentToDisplay"
    :is="getComponentToDisplay"
    v-bind="getProps"
    @update="handleUpdate"
  >
    <template v-slot:chart>
      <div class="w-100 h-100 my-auto mx-auto">
        <div v-if="!showChart">
          <progress-indicator></progress-indicator>
        </div>
        <div v-else
             class="my-auto mx-auto"
        >
          <component
            v-if="chartComponent"
            :is="chartComponent"
            v-bind="getChartProps"
            :style="chartStyle"
          ></component>
        </div>
      </div>
    </template>
  </component>
</template>

<script>
import { getDataForWidget } from "@/apps/dashboard/chartConfig"
import axios from "axios"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { mapActions, mapGetters } from "vuex"
import _isEqual from "lodash/isEqual"

export default {
  name: "BaoWidgetBasicMixin",
  components: {
    ProgressIndicator
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    configView: {
      type: Boolean,
      required: false,
      default: false
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      axios,
      getDataForWidget,
      staticTextDefault: {},
      defaultChartColor: "#C5805D",
      chartData: null,
      chartOptions: null,
      componentForRender: null,
      loading: false,
      chartStyle: {
        minHeight: "200px" // at least some value for min-height should be provided for charts (Line charts) to render properly
      } // can be overwritten by extending component
    }
  },
  computed: {
    ...mapGetters({
      previewChartData: "dashboardStore/getPreviewChartData"
    }),
    getComponentToDisplay () {
      if (this.configView) {
        return this.configuratorComponent
      }
      return this.displayComponent
    },
    getProps () {
      if (this.configView) {
        return this.getConfigProps()
      }
      return { ...this.getDisplayProps(), previewMode: this.previewMode }
    },
    showChart () {
      return !this.loading && !!this.chartData
    },
    getChartProps () {
      return {
        chartData: this.chartData,
        chartOptions: this.chartOptions,
        widget: this.widget,
        previewMode: this.previewMode
      }
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    updateMethod () {
      return this.update || this.basicUpdateMethod
    }
  },
  watch: {
    widget () {
      this.setUp()
    },
    previewChartData (val, oldVal) {
      if (val && !_isEqual(oldVal, val)) {
        this.setPreviewData(val)
      }
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    ...mapActions({
      updateWidgetsList: "dashboardStore/updateWidgetsList",
      basicUpdateMethod: "dashboardStore/updateWidgetAndClose"
    }),
    setUp () {
      if (!this.configView) {
        if (this.previewMode) {
          this.setPreviewData(this.previewChartData)
        } else {
          this.setupChart(this.widget)
        }
      }
    },
    setLoading (loading) {
      this.loading = loading
    },
    setupChart (widget) {
      if (!widget.id) return
      this.loading = true
      // this.mappingFunc needs to be provided by the extending component
      this.getDataForWidget(widget).then(async response => {
        this.chartData = await this.mappingFunc(response.data.data)
        this.loading = false
        // this.getChartOptions needs to be provided by the extending component
        this.chartOptions = this.getChartOptions(widget, this, {})
      })
    },
    handleUpdate (widget) {
      return new Promise((resolve, reject) => {
        this.updateMethod(widget).then(response => {
          this.updateWidgetsList(response)
          resolve(response)
        }).catch(error => {
          console.error(error)
        })
      })
    },
    getTotalCount (data, countAttr = "count") {
      return data.reduce(function (sum, item) {
        return item[countAttr] + sum
      }, 0)
    },
    async setPreviewData (previewData) {
      if (previewData && this.previewMode) {
        this.loading = true
        this.chartData = await this.mappingFunc(previewData)
        this.loading = false
        this.chartOptions = this.getChartOptions(this.widget, this, {})
      }
    },
    showConversations (dataIndex) {
      this.$router.push({ path: "/calls", query: { id__in: this.chartData.callIds[dataIndex].join() } })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
