<template>
  <div :class="['bao-widget-filter w-100 d-flex', { 'advanced-filters' : isOpenFromAdvancedFilters }]">
    <bao-widget-filter-container
      :label="staticText.browseTranscript"
      label-tag="base-filter-202309081357"
      :hasError="hasError"
      :error-message="staticText.errorMessage"
    >
      <div class="browse-transcript">
        <input
          id="transcript_text"
          type="text"
          v-model="transcript"
          :placeholder="staticText.browseTranscript"
          @keyup.enter="handleTranscriptAdded"
        >
        <ul
          v-if="!!transcriptData && transcriptData.transcript_texts.length"
          class="transcript_texts"
        >
          <li :key="index" v-for="(transcript, index) in transcriptData.transcript_texts">
            <span
              @click="editTranscriptText(index, transcript)"
            >
              {{ transcript }}
            </span>
            <div
              role="button"
              class="delete-btn"
              @click="removeTranscriptText(index)"
            >
              <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
            </div>
          </li>
        </ul>
        <!-- This would be revisited when it works properly from bao-audio; please don't delete -->
        <!-- <div class="similarity">
          <bao-toggle-button
            id="similarity"
            :is-active="transcriptData.includeSimilar"
            :label="staticText.includeSimilar"
            @toggle="searchSimilarityChanged"
          ></bao-toggle-button>
        </div> -->
      </div>
    </bao-widget-filter-container>
    <div
      @click.prevent="$emit('delete')"
      class="ml-3 filter-delete"
    >
      <i
        v-if="!isOpenFromAdvancedFilters"
        class="fa fa-trash my-auto"
      ></i>
      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
    </div>
  </div>
</template>

<script>
import BaoWidgetFilterContainer from "./BaoWidgetFilterContainer.vue"
// import BaoToggleButton from "@/apps/base/BaoToggleButton"

export default {
  name: "TranscriptSearchFilter",
  components: {
    BaoWidgetFilterContainer
    // BaoToggleButton
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      hasError: false,
      staticTextDefault: {
        browseTranscript: "Browse Transcript",
        includeSimilar: "Including similar",
        errorMessage: "The field for the search must not be empty."
      },
      transcript: "",
      transcriptData: {
        transcript_texts: [],
        includeSimilar: false
      }
    }
  },
  mounted () {
    if (this.value) {
      this.transcriptData = this.value
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    validateFilter () {
      this.handleTranscriptAdded()
      this.hasError = !(this.transcriptData && this.transcriptData.transcript_texts.length)
      return !this.hasError
    },
    handleTranscriptAdded () {
      if (this.transcript) {
        this.transcriptData.transcript_texts.unshift(this.transcript)
        this.transcript = ""
        this.handleFilterChanged()
      }
    },
    removeTranscriptText (index) {
      this.transcriptData.transcript_texts.splice(index, 1)
      this.handleFilterChanged()
    },
    searchSimilarityChanged () {
      this.transcriptData.includeSimilar = !this.transcriptData.includeSimilar
      this.handleFilterChanged()
    },
    handleFilterChanged () {
      this.$emit("input", this.transcriptData)
      if (this.hasError) this.validateFilter()
    },
    editTranscriptText (index, transcript) {
      this.transcript = transcript
      document.getElementById("transcript_text").focus()
      this.transcriptData.transcript_texts.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">

.browse-transcript {
  border-radius: 20px;
  border: 1px solid $slate20;
  background-color: $white;
  overflow: hidden;
  input {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    border-radius: 0;
  }
  ul {
    margin: 0;
    border-top: 1px solid $slate06;
    padding: 6px 16px;
    list-style: none;
    li {
      padding: 6px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        // 28px is width of delete btn + 8px margin
        max-width: calc(100% - 28px);
        cursor: pointer;
      }
      .delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        border: 1px solid $slate;
        border-radius: 50%;
        svg {
          path {
            stroke: $slate;
          }
        }
      }
    }
  }
  .similarity {
    border-top: 1px solid $slate06;
    padding: 12px 16px;
  }
}

</style>
