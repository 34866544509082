<template>
  <div class="w-100 p-1 d-flex justify-content-center flex-column align-items-center">
    <div class="chart">
      <span>{{ successRatio }} %</span>
    </div>
    <div class="pt-2 text-center">
      <span class="font-size-14 slate-60">
        {{this.chartData.success_calls }} {{ this.staticText.success }} / {{ this.chartData.total_calls }} {{ this.getConversationLabel }}
      </span>
    </div>
  </div>
</template>

<script>
import { getTranslatedTimeframes } from "@/apps/dashboard/chartUtils"
export default {
  name: "BaoWidgetPlaybookConversionChart",
  props: {
    widget: {
      type: Object,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      getTranslatedTimeframes,
      staticTextDefault: {
        conversion: "CONVERSION",
        success: "Success",
        conversations: "Conversations",
        conversation: "Conversation",
        timeframe: "Timeframe"
      },
      translatedTimeFrames: {}
    }
  },
  computed: {
    successRatio () {
      if (this.chartData.total_calls > 0) {
        const percentage = (this.chartData.success_calls / this.chartData.total_calls) * 100
        if (percentage % 1 !== 0) {
          return percentage.toFixed(1)
        }
        return percentage
      }
      return 0
    },
    getConversationLabel () {
      return this.chartData.total_calls.toString() === "1" ? this.staticText.conversation : this.staticText.conversations
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    setUp () {
      this.translatedTimeFrames = this.getTranslatedTimeframes()
    }
  }
}
</script>

<style scoped lang="scss">

.content-box {
  border: 1px solid $slate06;
  box-sizing: border-box;
  border-radius: 16px;
}

.tooltip-text {
  font-size: 12px;
  color: $black50;
}

.slate-60 {
  color: $slate60;
}

.message{
  font-size: 20px;
  font-weight: bold;
  color: $slate;
}

.chart{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $slate06;
  height: 146px;
  width: 146px;
  border-radius: 50%;
  color:$slate;
  font-size: 36px;
  font-weight: bold;
}
</style>
