import store from "../../store"

const BaoDashboard = () => import(/* webpackChunkName: "BaoDashboard" */ "./BaoDashboard.vue")

export const baseUrl = "/dashboard"

export default {
  name: "dashboard",
  apiUrl: "/api" + baseUrl,
  routes: [
    {
      path: `${baseUrl}/:page?`,
      component: BaoDashboard
    }
  ]
}

export const getLanguage = () => {
  return store.getters["auth/user"].language
}

export const dateFormatingOptions = {
  days: (date) => {
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" }
    let language = getLanguage()
    if (language === "en") language = "en-GB"
    return date.toLocaleDateString(language, options)
  },
  weeks: (date) => {
    let start = date
    let end = new Date(date)
    end.setDate(date.getDate() + 7)
    const options = { year: "numeric", month: "short", day: "numeric" }
    let localeStr = getLanguage()
    if (localeStr === "en") localeStr = "en-GB"
    start = start.toLocaleDateString(localeStr, options)
    end = end.toLocaleDateString(localeStr, options)
    return start + " - " + end
  },
  months: (date) => {
    const options = { year: "numeric", month: "long" }
    let localeStr = getLanguage()
    if (localeStr === "en") localeStr = "en-GB"
    return date.toLocaleDateString(localeStr, options)
  }
}

export const getTranslation = (component) => {
  return store.getters["I18nStore/getI18n"](component.name, component.staticTextDefault)
}

export const dashboardStoreUtils = {
  name: "DashboardStore",
  staticTextDefault: {
    copyOf: "Copy of",
    defaultPageTitle: "New Dashboard"
  },
  _getNumericSuffixInfo (title) {
    // get any numeric suffix from end of title and title without numeric suffix
    const titleSuffixArray = title.match(/[0-9]+$/)
    const titleWithoutSuffix = titleSuffixArray ? title.slice(0, titleSuffixArray.index).trim() : title
    return [titleWithoutSuffix, titleSuffixArray]
  },
  _getCurrentTitleNumericSuffix (newTitleWithoutSuffix, currentPageTitle) {
    const [currentPageTitleWithoutSuffix, currentPageSuffixArray] = dashboardStoreUtils._getNumericSuffixInfo(currentPageTitle)
    // if numeric suffix exists and both titles without suffix match exactly, get the suffix from existing title
    if (newTitleWithoutSuffix === currentPageTitleWithoutSuffix) {
      return currentPageSuffixArray ? Number(currentPageSuffixArray[0]) : 0
    }
    return Number.NEGATIVE_INFINITY
  },
  getNewDashboardTitle (dashboards, title = "") {
    const newTitle = title || getTranslation(dashboardStoreUtils).defaultPageTitle
    const [newTitleWithoutSuffix] = dashboardStoreUtils._getNumericSuffixInfo(newTitle)
    const newTitleRegex = new RegExp(newTitleWithoutSuffix, "i")
    let currentHighestIndex = Number.NEGATIVE_INFINITY

    for (const dashboard of dashboards) {
      if (newTitleRegex.test(dashboard.name)) {
        const currentIndex = dashboardStoreUtils._getCurrentTitleNumericSuffix(newTitleWithoutSuffix, dashboard.name)
        if (currentIndex > currentHighestIndex) {
          currentHighestIndex = currentIndex
        }
      }
    }

    if (currentHighestIndex < 0) return newTitle
    return `${newTitleWithoutSuffix} ${currentHighestIndex + 1}`
  }
}
