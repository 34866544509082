<template>
  <div :class="['bao-widget-filter w-100 d-flex', { 'advanced-filters' : isOpenFromAdvancedFilters }]">
    <div class="w-100 filter-body">
      <label
        id="label-timeframe-filter-202112211245"
        for="timeframe-filter-202112211245"
        class="mb-2 filter-title"
      >
        {{ staticText.selectTimeframe }}
      </label>
      <bao-widget-filter-container
        :label="staticText.selectTimeframe"
        label-tag="timeframe-filter-202112211245"
        :hasError="noValueError"
      >
        <vue-multiselect
          id="timeframe-filter-202112211245"
          v-model="selectedTimeframe"
          :options="timeframeOptions"
          :show-labels="false"
          :placeholder="staticText.selectTimeframe"
          :show-no-results="false"
          :allow-empty="false"
          track-by="value"
          label="label"
          @input="handleTimeframeSelected"
        >
          <span slot="noResult">{{ staticText.noResultMessage }}</span>
        </vue-multiselect>
      </bao-widget-filter-container>
      <div v-if="isStatic(selectedTimeframe)">
        <div class="d-flex w-100 mt-2">
          <div class="w-50 p-1 inner-label-form" :class="{ 'error-class': customDurationError }">
            <label class="inner-label"> {{ staticText.startLabel }} </label>
            <b-datepicker v-model="startDate"
                          :placeholder="staticText.noDateSelected"
            ></b-datepicker>
          </div>
          <div class="w-50 p-1 inner-label-form" :class="{ 'error-class': customDurationError }">
            <label class="inner-label"> {{ staticText.endLabel }} </label>
            <b-datepicker v-model="endDate"
                          :placeholder="staticText.noDateSelected"
            ></b-datepicker>
          </div>
        </div>
        <p v-if="customDurationError" class="filter-error-text">{{ customDurationError }}</p>
      </div>
    </div>
    <div class="ml-3 filter-delete"
         :class="{ hidden: !options.showDelete }"
         @click.prevent="$emit('delete')"
    >
      <i
        v-if="!isOpenFromAdvancedFilters"
        class="fa fa-trash my-auto"
      ></i>
      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"
import axios from "axios"
import { getTranslatedTimeframes, WidgetConfigOptions } from "@/apps/dashboard/chartUtils"
import BaoWidgetFilterContainer from "./Filter/BaoWidgetFilterContainer.vue"

export default {
  name: "TimeframeFilter",
  components: { VueMultiselect, BaoWidgetFilterContainer },
  props: {
    value: { // timeframe value selected for filtering
      type: [String, Object],
      required: false,
      default: null
    },
    options: {
      type: WidgetConfigOptions,
      required: false,
      default: () => new WidgetConfigOptions()
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      axios,
      getTranslatedTimeframes,
      staticTextDefault: {
        selectTimeframe: "Date range",
        startLabel: "Start",
        endLabel: "End",
        noDateSelected: "No date selected",
        noResultMessage: "Oops! No elements found. Consider changing the search query.",
        minMaxErrorMessage: "Start date cannot be after end date.",
        noValueErrorMessage: "Please, input values before saving changes."
      },
      selectedTimeframe: {},
      timeframeOptions: [],
      startDate: null,
      endDate: null,
      noValueError: false,
      customDurationError: null
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.setUp()
  },
  watch: {
    startDate (newVal) {
      this.handleTimeframeSelected()
    },
    endDate (newVal) {
      this.handleTimeframeSelected()
    }
  },
  methods: {
    setUp () {
      this.timeframeOptions = []
      for (const [key, value] of Object.entries(this.getTranslatedTimeframes())) {
        this.timeframeOptions.push({ label: value, value: key })
      }
      if (this.isObject(this.value)) {
        this.startDate = this.value.created_at__gte
        this.endDate = this.value.created_at__lte
        this.selectTimeframe("static")
      } else {
        this.selectTimeframe(this.value)
      }
      if (!this.selectedTimeframe) {
        this.selectTimeframe("thisMonth")
        this.handleTimeframeSelected()
      }
    },
    selectTimeframe (timeframeToSelect) {
      this.selectedTimeframe = this.timeframeOptions.find(item => item.value === timeframeToSelect)
    },
    isObject (thingToCheck) {
      return typeof thingToCheck === "object" && thingToCheck !== null
    },
    isStatic (timeframe) {
      return timeframe === "static" || timeframe.value === "static"
    },
    handleTimeframeSelected () {
      let inputVal = this.selectedTimeframe.value
      if (this.isStatic(this.selectedTimeframe)) {
        inputVal = {}
        if (this.startDate) inputVal.created_at__gte = this.startDate
        if (this.endDate) inputVal.created_at__lte = this.endDate
        if (this.startDate || this.endDate) this.validateFilter()
      }
      this.$emit("input", inputVal)
    },
    validateFilter () {
      this.customDurationError = null
      this.noValueError = false
      if (!this.isStatic(this.selectedTimeframe) && !this.selectedTimeframe.value) {
        this.noValueError = true
      } else if (this.isStatic(this.selectedTimeframe)) {
        if (!this.startDate && !this.endDate) {
          this.customDurationError = this.staticText.noValueErrorMessage
        } else if (this.startDate && this.endDate) {
          const startDate = new Date(this.startDate)
          const endDate = new Date(this.endDate)
          if (startDate - endDate > 0) this.customDurationError = this.staticText.minMaxErrorMessage
        }
      }
      return !this.noValueError && !this.customDurationError
    }
  }
}
</script>

<style lang="scss" scoped>

.hidden {
  visibility: hidden;
}

</style>
