<template>
  <div class="bao-widget-filter w-100 d-flex">
    <div class="w-100 filter-body">
      <label
      >
        {{ staticText.shortcutsLabel }}
      </label>
      <playbook-selector
        :ref="filterRefs[0]"
        :options="playbookConfigOptions"
        @input="playbookSelected"
        @delete="$emit('delete')"
      ></playbook-selector>

      <objection-selector
        class="mt-2"
        v-if="showObjectionsSelection"
        :ref="filterRefs[1]"
        :options="objectionConfigOption"
        :selected-playbook-id="selectedPlaybookId"
        @input="objectionSelected"
        @delete="objectionSelected([])"
      ></objection-selector>

    </div>
    <div @click.prevent="$emit('delete')"
         class="ml-3 filter-delete"
    >
      <i
        class="fa fa-trash my-auto"
      ></i>
    </div>
  </div>
</template>

<script>

import PlaybookSelector from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/PlaybookSelector"
import {
  getDefaultConfigOptions,
  FILTER_KEY_OBJECTIONS__PLAYBOOK,
  FILTER_KEY_OBJECTIONS__OBJECTIONS
} from "@/apps/dashboard/chartUtils"
import ObjectionSelector
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/ObjectionSelector"

export default {
  name: "ObjectionsFilter",
  components: { PlaybookSelector, ObjectionSelector },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return { selectedPlaybookId: null, selectedObjections: [] }
      }
    }
  },
  data () {
    return {
      staticTextDefault: {
        shortcutsLabel: "Shortcuts"
      },
      configurations: [],
      selectedPlaybookId: null,
      selectedObjections: [],
      filterRefs: ["playbook-filter", "objection-filter"]
    }
  },
  created () {
    this.setUp()
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showObjectionsSelection () {
      return this.selectedPlaybookId
    },
    playbookConfigOptions () {
      return getDefaultConfigOptions({
        value: [this.selectedPlaybookId],
        labelForPlaybookItemAnswer: true,
        showDelete: false
      })
    },
    internalDefaultConfig () {
      return {
        showLabel: false,
        multiselect: true
      }
    },
    objectionConfigOption () {
      return getDefaultConfigOptions({
        value: this.selectedObjections,
        ...this.internalDefaultConfig
      })
    }
  },
  methods: {
    setUp () {
      this.selectedPlaybookId = this.value[FILTER_KEY_OBJECTIONS__PLAYBOOK] || this.value.selectedPlaybookId || null
      this.selectedObjections = this.value[FILTER_KEY_OBJECTIONS__OBJECTIONS] || this.value.selectedObjections || []
    },
    emitData () {
      const data = {
        [FILTER_KEY_OBJECTIONS__PLAYBOOK]: this.selectedPlaybookId,
        [FILTER_KEY_OBJECTIONS__OBJECTIONS]: this.selectedObjections
      }
      this.$emit("input", data)
    },
    objectionSelected (selectedObjections) {
      this.selectedObjections = selectedObjections
      if (!selectedObjections || selectedObjections.length === 0) return
      this.emitData() // now the configuration is complete and we send it via event
    },
    playbookSelected (selectedPlaybook) {
      this.selectedPlaybookId = selectedPlaybook && selectedPlaybook.length > 0 ? selectedPlaybook[0] : null
    },
    validateFilter () {
      return this.validateChildrenFilters()
    },
    validateChildrenFilters () {
      let errorCount = 0
      for (const filterRef of this.filterRefs) {
        if (this.$refs[filterRef]) {
          const validatedFilter = this.$refs[filterRef].validateFilter()
          if (!validatedFilter) errorCount++
        }
      }
      return errorCount <= 0
    }
  }
}

</script>
