<template>
  <div v-if="getCriterion" class="d-inline-block mw-100">
    <div class="font-size-12 grey-div d-flex align-items-center">
      <img src="/img/icons/settings-icon.svg"/>

      <div :id="'playbook-criteria-filter-20220329-'+widget.id"
           v-if="getCriterion === 'answer' || getCriterion === 'playbookItemCriteria'"
           class="d-flex text-overflow-ellipsis"
      >
        <div class="text-overflow-ellipsis">{{ playbooks }}</div>
        <div class="text-overflow-ellipsis mx-2">{{ staticText.item }}: {{ playbookItems }}</div>
        <div v-if="getCriterion === 'answer'" class="text-overflow-ellipsis ml-2">{{ staticText.answer }}: {{ getAnswerFilter }}</div>
        <b-tooltip
          :target="'playbook-criteria-filter-20220329-'+widget.id"
          placement="top"
          custom-class="long-tooltip"
        >
          <div class="tooltip-text">
            <div v-if="playbooks.length > 0" class="pb-3">{{ playbooks }}</div>
            <div class="w-100 d-flex">
              <div class="w-30">{{ staticText.item }}:</div>
              <div class="w-70 main-slate">{{ playbookItems }}</div>
            </div>
            <div
              v-if="getCriterion === 'answer'"
              class="w-100 d-flex"
            >
              <div class="w-30">{{ staticText.answer }}:</div>
              <div class="w-70 main-slate">{{ getAnswerFilter }}</div>
            </div>
          </div>
        </b-tooltip>
      </div>

      <div v-else-if="getCriterion === 'callDuration'"
           class="text-overflow-ellipsis"
      >
        {{ staticText.duration }} ≥ {{ formatMinutes(getDurationFilter) }}
      </div>

      <div v-else
           :id="'reachability-item-filter-20220329-'+widget.id"
           class="text-overflow-ellipsis"
      >
        {{ getTextToDisplay(getCriterion) }}
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from "vuex"

export default {
  name: "BaoWidgetCriteriaDisplay",
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        item: "Item",
        answer: "Answer",
        duration: "Call Duration",
        counterpartReachability: "Counterpart Reachability",
        callResult: "Positive Call Result"
      },
      playbooks: "",
      playbookItems: ""
    }
  },
  computed: {
    criteriaConfig () {
      if (!(this.widget && this.widget.config && (this.widget.config.successCriteria || this.widget.config.playbookAndPlaybookItemFilter))) return {}
      return this.widget.config.successCriteria || this.widget.config.playbookAndPlaybookItemFilter
    },
    getAnswerFilter () {
      if (this.criteriaConfig && this.criteriaConfig.answers &&
        Array.isArray(this.criteriaConfig.answers) && this.criteriaConfig.answers.length > 0) {
        return this.criteriaConfig.answers.join(", ")
      }
      return ""
    },
    getDurationFilter () {
      if (this.criteriaConfig && this.criteriaConfig.callDuration) {
        return this.criteriaConfig.callDuration
      }
      return 0
    },
    getCriterion () {
      if (this.criteriaConfig && this.criteriaConfig.type) {
        return this.criteriaConfig.type
      }
      return ""
    },
    criteriaDisplayText () {
      return {
        counterpartReachability: this.staticText.counterpartReachability,
        callResult: this.staticText.callResult
      }
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.setUp()
  },
  watch: {
    criteriaConfig: {
      deep: true,
      handler (val, oldVal) {
        this.transformToDisplay()
      }
    }
  },
  methods: {
    ...mapActions({
      getPlaybookNames: "dashboardStore/getPlaybookNames",
      getPlaybookItemNames: "dashboardStore/getPlaybookItemNames"
    }),
    setUp () {
      this.transformToDisplay()
    },
    transformToDisplay () {
      const promises = []
      promises.push(this.getPlaybookNames(this.criteriaConfig.playbooks).then((response) => {
        this.playbooks = response.join(", ")
      }))
      promises.push(this.getPlaybookItemNames(this.criteriaConfig.playbookItems).then((response) => {
        this.playbookItems = response.join(", ")
      }))
      return Promise.all(promises)
    },
    formatMinutes (total) {
      if (total < 60) return total + " s."
      const minutes = Math.floor(total / 60)
      const seconds = total % 60
      let result = minutes + " m."
      if (seconds > 0) result += " " + seconds + " s."
      return result
    },
    getTextToDisplay (criteria) {
      return this.criteriaDisplayText[criteria] || "Invalid Criteria"
    }
  }
}
</script>

<style scoped lang="scss">
.grey-div {
  padding: 0.5rem;
  background-color: $slate06;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 32px;
  white-space: nowrap;
  border-radius: 8px;
  color: $slate;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.tooltip-text {
  font-size: 12px;
  color: $black50;
}

.tooltip.b-tooltip {
  opacity: 1 !important;
}

img {
  height: 15px;
  width: 15px;
  margin-right: 0.25rem;
}

</style>

<style lang="scss">

.long-tooltip > .tooltip-inner {
  text-align: left !important;
  width: 250px !important;
}
</style>
