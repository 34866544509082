<template>
  <div v-if="configurations.length > 0" class="w-100 mb-4">
    <div class="d-flex mb-2">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.324 7.23A8.98 8.98 0 013.22 3.97a.533.533 0 01.573-.143l2.04.726a1.065 1.065 0 001.403-.807l.39-2.125a.531.531 0 01.411-.423 9.11 9.11 0 013.78 0 .53.53 0 01.412.423l.39 2.125a1.063 1.063 0 001.402.807l2.04-.726a.533.533 0 01.574.142c.853.94 1.5 2.051 1.893 3.26a.528.528 0 01-.162.567l-1.653 1.397a1.054 1.054 0 000 1.614l1.653 1.397c.165.14.228.363.162.567a8.98 8.98 0 01-1.893 3.26.532.532 0 01-.573.142l-2.04-.726a1.065 1.065 0 00-1.403.807l-.39 2.125a.53.53 0 01-.413.423 9.101 9.101 0 01-3.779 0 .531.531 0 01-.412-.423l-.389-2.125a1.061 1.061 0 00-1.403-.807l-2.04.726a.532.532 0 01-.573-.142 8.98 8.98 0 01-1.893-3.26.528.528 0 01.162-.567l1.653-1.397a1.056 1.056 0 000-1.614L1.488 7.796a.528.528 0 01-.162-.567h-.002zM7.3 10a2.625 2.625 0 105.251 0 2.625 2.625 0 00-5.25 0z" fill="#35375B" opacity=".8"/></svg>
      <span>{{ staticText.settingsLabel }}</span>
    </div>
    <div v-for="(configOption, index) in configurations"
         :key="''+index + widget.id"
         class="w-100 mb-3"
    >
      <bao-widget-criteria-configurator
        v-if="configOption.isCriteriaOptions"
        :ref="`${configOption.fieldName}-${index}`"
        :configuration="{ options: configOption.options, value: configOption.value }"
        @change="value => successCriteriaChanged(value, configOption)"
      ></bao-widget-criteria-configurator>

      <basic-type-filter
        v-if="configOption.isText || configOption.isNumber || configOption.isTextArea"
        :ref="`${configOption.fieldName}-${index}`"
        :value="configOption"
        @change="(value) => basicTypeFilterChanged(value, configOption)"
      ></basic-type-filter>

      <timeframe-filter
        v-if="configOption.isTimeframeFilter"
        v-model="configOption.value"
        :ref="`${configOption.fieldName}-${index}`"
        :multiselect="configOption.multiselect"
        @input="configChanged"
      ></timeframe-filter>

      <playbook-selector
        v-if="configOption.isPlaybookFilter"
        :ref="`${configOption.fieldName}-${index}`"
        :options="configOption"
        @input="value => playbookFilterChanged(value, configOption)"
      ></playbook-selector>

      <playbook-item-selector
        v-if="configOption.isPlaybookItemFilter && selectedPlaybooks && selectedPlaybooks.length > 0"
        :ref="`${configOption.fieldName}-${index}`"
        :options="configOption"
        :selected-playbooks="selectedPlaybooks"
        @input="value => playbookItemFilterChanged(value, configOption)"
      ></playbook-item-selector>

      <answer-selector
        v-if="configOption.isAnswerFilter && selectedPlaybookItems && selectedPlaybookItems.length > 0"
        :ref="`${configOption.fieldName}-${index}`"
        :options="configOption"
        :selected-playbook-items="selectedPlaybookItems"
        @input="value => answerChanged(value, configOption)"
      ></answer-selector>

      <playbook-item-answer-filters
        v-if="configOption.isPlaybookAndPlaybookItemFilter"
        :ref="`${configOption.fieldName}-${index}`"
        :value="{
          selectedPlaybooks: configOption.value.playbooks,
          selectedPlaybookItems: configOption.value.playbookItems,
        }"
        :criteria-configurator="true"
        :no-answer-required="true"
        @input="value => playbookAndPlaybookItemSelected(value, configOption)"
      ></playbook-item-answer-filters>
    </div>
  </div>
</template>

<script>

import PlaybookSelector from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/PlaybookSelector"
import PlaybookItemSelector from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/PlaybookItemSelector"
import AnswerSelector from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/AnswerSelector"
import TimeframeFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/TimeframeFilter"
import BasicTypeFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BasicTypeFilter"
import { WidgetConfigOptions } from "@/apps/dashboard/chartUtils"
import BaoWidgetCriteriaConfigurator
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BaoWidgetCriteriaConfigurator"
import PlaybookItemAnswerFilters
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/PlaybookItemAnswerFilters"

export default {
  name: "BaoWidgetSettings",
  components: { TimeframeFilter, PlaybookSelector, PlaybookItemSelector, AnswerSelector, BaoWidgetCriteriaConfigurator, BasicTypeFilter, PlaybookItemAnswerFilters },
  props: {
    configuration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      configurations: [],
      selectedPlaybooks: [],
      selectedPlaybookItems: [],
      widget: this.configuration.widget,
      configOptions: this.configuration.configOptions,
      selectedAnswers: [],
      staticTextDefault: {
        settingsLabel: "Settings"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  created () {
    this.setUp()
  },
  methods: {
    setUp () {
      this.transformToDisplay()
      this.configChanged()
    },
    transformToDisplay () {
      this.configurations = this.configOptions.map(configItem => {
        return new WidgetConfigOptions(configItem, this.widget)
      })
    },
    transformToSave (configurations) {
      const configToSave = {}
      for (const option of configurations) {
        if (option.value) {
          configToSave[option.fieldName] = option.value
        }
      }
      return configToSave
    },
    configChanged () {
      if (this.checkRequiredFieldsUnanswered()) return
      const data = this.transformToSave(this.configurations)
      this.$emit("changed", data)
    },
    successCriteriaChanged (criteriaFilters, configOption) {
      configOption.value = criteriaFilters
      this.configChanged()
    },
    playbookFilterChanged (selectedPlaybooks, configOption) {
      this.selectedPlaybooks = selectedPlaybooks
      configOption.value = selectedPlaybooks
      this.configChanged()
    },
    playbookItemFilterChanged (selectedPlaybookItems, configOption) {
      this.selectedPlaybookItems = selectedPlaybookItems
      configOption.value = selectedPlaybookItems
      this.configChanged()
    },
    answerChanged (selectedAnswers, configOption) {
      this.selectedAnswers = selectedAnswers
      configOption.value = selectedAnswers
      this.configChanged()
    },
    playbookAndPlaybookItemSelected (value, configOption) {
      const transformedValue = {
        type: "playbookItemCriteria",
        playbooks: value.selectedPlaybooks,
        playbookItems: value.selectedPlaybookItems
      }
      configOption.value = transformedValue
      this.configChanged()
    },
    basicTypeFilterChanged (value, configOption) {
      configOption.value = value
      this.configChanged()
    },
    checkRequiredFieldsUnanswered () {
      return this.configurations &&
        this.configurations.some(item => {
          if (!item.required) return false
          return this.isEmpty(item.value)
        })
    },
    isEmpty (value) {
      if (value === undefined || value === null || value === "") return true
      return Array.isArray(value) && (value.length <= 0)
    },
    validateAllFilters () {
      let errorCount = 0
      for (const index in this.configurations) {
        const fieldName = this.configurations[index].fieldName
        const refLabel = `${fieldName}-${index}`
        if (fieldName && this.$refs[refLabel]) {
          const validatedFilter = this.$refs[refLabel][0].validateFilter()
          if (!validatedFilter) errorCount++
        }
      }
      return errorCount <= 0
    }
  }
}

</script>

<style scoped>

</style>
