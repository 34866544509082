import axios from "axios"

export const findCRMLookupFieldValueById = async (crmService, lookupFieldType, crmId) => {
  try {
    const url = `/api/oauthservices/${crmService}/get_lookup_value_by_id/`
    const { data } = await axios.get(url, {
      params: {
        type: lookupFieldType,
        crm_id: crmId
      }
    })
    return data
  } catch (error) {
    console.debug(error)
  }
}
