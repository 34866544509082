<template>
  <base-filter
    :value="value || options.value"
    :show-label="options.showLabel"
    :label="displayLabel"
    :placeholder="staticText.selectPlaybooks"
    :show-delete="options.showDelete"
    :url="getUrl"
    :multiselect="options.multiselect"
    :required="options.required"
    :hasError="hasError"
    :group-values="options.enableGrouping ? 'values' : ''"
    :group-label="options.enableGrouping ? 'source' : ''"
    :postprocess-options="postprocessOptions"
    :isOpenFromAdvancedFilters="isOpenFromAdvancedFilters"
    @input="handleValueChanged"
    @delete="$emit('delete')"
  ></base-filter>
</template>

<script>
import BaseFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BaseFilter"
import axios from "axios"
import { WidgetConfigOptions } from "@/apps/dashboard/chartUtils"

export default {
  name: "PlaybookSelector",
  components: { BaseFilter },
  props: {
    options: {
      type: WidgetConfigOptions,
      required: false,
      default: () => new WidgetConfigOptions()
    },
    value: { // TODO: refactor this - we should move to WidgetConfigOptions entirely
      type: Array,
      default: null
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      axios,
      selectedPlaybooks: this.value,
      staticTextDefault: {
        selectPlaybooks: "Select Playbooks",
        playbookLabel: "Playbook",
        library: "Library"
      },
      hasError: false
    }
  },
  computed: {
    displayLabel () {
      if (this.options.rawConfig && this.options.rawConfig.labelForPlaybookItemAnswer) {
        return this.staticText.playbookLabel
      }
      return this.options.label || this.staticText.selectPlaybooks
    },
    getUrl () {
      return "/api/talkscripts/get_analytics_view?type=default"
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.selectedPlaybooks = this.value || this.options.value || []
  },
  methods: {
    handleValueChanged (newValue) {
      this.selectedPlaybooks = newValue
      this.$emit("input", this.selectedPlaybooks)
      if (this.selectedPlaybooks.length > 0) this.validateFilter()
    },
    validateFilter () {
      this.hasError = !this.selectedPlaybooks || !this.selectedPlaybooks.length
      return !this.hasError
    },
    postprocessOptions (options) {
      if (!this.options.enableGrouping) return options
      return [
        { source: this.staticText.library, values: [{ id: null, name: this.staticText.library }] },
        { source: this.staticText.playbookLabel, values: options }
      ]
    }
  }
}
</script>

<style scoped>

</style>
