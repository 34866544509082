import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  getDefaultLineChartOptions,
  getUsername,
  computeDateLabelForData,
  formatMillisecondsToHHMMSS
} from "@/apps/dashboard/chartUtils"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const LineChart = () => import(/* webpackChunkName: "LineChart" */ "@/apps/dashboard/BaoWidgetsBaseComponents/LineChart")

export default {
  name: "BaoWidgetCallDurationStats",
  info: {
    title: "Call Duration Stats",
    description: "Shows how long the minimum, maximum, average and median values of the calls are."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getUsername,
      computeDateLabelForData,
      getDefaultLineChartOptions,
      formatMillisecondsToHHMMSS,
      chartComponent: LineChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      staticTextDefault: {
        avg: "Average duration",
        median: "Median duration",
        max: "Maximum duration",
        min: "Minimum duration"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showGoToConversationsButton () {
      return !!(this.chartData && this.chartData.labels && this.chartData.labels.length > 0)
    }
  },
  methods: {
    mappingFunc (data) {
      const transformedData = this.computeDateLabelForData(data, this.widget)
      const { labels, avgDataset, minDataset, maxDataset, medianDataset } = this.getDatasets(transformedData)
      const defaultConfig = {
        fill: false,
        tension: 0.1,
        borderWidth: 0.9
      }
      return {
        labels,
        datasets: [{
          label: this.staticText.avg,
          data: avgDataset,
          borderColor: "rgba(177, 82, 36, 1)",
          ...defaultConfig
        },
        {
          label: this.staticText.max,
          data: maxDataset,
          borderColor: "rgba(42, 45, 82, 0.8)",
          ...defaultConfig
        },
        {
          label: this.staticText.min,
          data: minDataset,
          borderColor: "rgba(0, 0, 0, 0.5)",
          ...defaultConfig
        },
        {
          label: this.staticText.median,
          data: medianDataset,
          borderColor: "rgba(177, 82, 36, 0.4)",
          ...defaultConfig
        }]
      }
    },
    getDatasets (transformedData) {
      const labels = []
      const avgDataset = []
      const minDataset = []
      const maxDataset = []
      const medianDataset = []
      for (const item of transformedData) {
        labels.push(item.text)
        if (!item.avg_duration) {
          avgDataset.push(0.0)
          minDataset.push(0)
          maxDataset.push(0)
          medianDataset.push(0.0)
        } else {
          avgDataset.push(item.avg_duration)
          minDataset.push(item.min_duration)
          maxDataset.push(item.max_duration)
          medianDataset.push(item.median_duration)
        }
      }
      return { labels, avgDataset, minDataset, maxDataset, medianDataset }
    },
    getChartOptions () {
      const chartOptions = this.getDefaultLineChartOptions(this.widget, { legend: true })
      chartOptions.scales.yAxes[0].ticks.callback = (milliseconds) => this.formatMillisecondsToHHMMSS(milliseconds)
      chartOptions.scales.yAxes[0].scaleLabel = { display: true, labelString: "HH:MM:SS", fontSize: 10, padding: 0 }
      chartOptions.tooltips.callbacks.label = (value) => this.formatMillisecondsToHHMMSS(value.yLabel)
      return chartOptions
    },
    getConfigOptions () {
      return []
    },
    getDisplayProps () {
      return { widget: this.widget, showConversationsView: this.showGoToConversationsButton }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
