import axios from "axios"

const state = {
  nonSystemLabels: []
}

const getters = {
  getAllNonSystemLabels (state) {
    return state.nonSystemLabels
  }
}

const mutations = {
  setAllNonSystemLabels (state, nonSystemLabels) {
    state.nonSystemLabels = nonSystemLabels
  }
}

const actions = {
  async fetchAllNonSystemLabels ({ commit }) {
    try {
      const { data } = await axios.get("/api/tags/get_non_system_tags")
      commit("setAllNonSystemLabels", data)
    } catch (error) {

    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
