import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  getDefaultHorizontalBarChartOptions
} from "@/apps/dashboard/chartUtils"
import { defaultHorizontalDatasetValues } from "@/apps/dashboard/chartConfig"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const HorizontalBarChart = () => import(/* webpackChunkName: "HorizontalBarChart" */ "../BaoWidgetsBaseComponents/HorizontalBarChart")

export default {
  name: "BaoWidgetCallsPerPlaybook",
  info: {
    title: "Most-Used Playbooks",
    description: "Shows which playbooks are used and how often."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getDefaultHorizontalBarChartOptions,
      chartComponent: HorizontalBarChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      totalCalls: 0
    }
  },
  methods: {
    mappingFunc (items) {
      items = items.sort((a, b) => b.countdistinct_id - a.countdistinct_id)
        .filter(item => item.countdistinct_id > 0)
        .splice(0, 10)
      const labels = []
      const data = []
      const callIds = []
      this.totalCalls = this.getTotalCount(items, "countdistinct_id")
      for (const item of items) {
        labels.push(item.talkscript__name)
        data.push(item.countdistinct_id)
        callIds.push(item.arrayagg_id)
      }
      return {
        labels,
        datasets: [{ ...defaultHorizontalDatasetValues, data }],
        callIds
      }
    },
    getChartOptions () {
      const dataLabelClickCallBack = (dataIndex) => {
        this.showConversations(dataIndex)
      }
      return this.getDefaultHorizontalBarChartOptions(
        this.widget,
        this,
        {
          dataLabelClickCallBack
        }
      )
    },
    getConfigOptions () {
      return []
    },
    getDisplayProps () {
      return { widget: this.widget, totalItems: this.totalCalls }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
