<template>
  <div class="w-100">
    <!-- Criteria Filter  -->
    <criteria-selector
      v-model="selectedCriteria"
      :options="criteriaOptions"
      :multiselect="false"
      :required="true"
      @input="configChanged"
    ></criteria-selector>

    <!-- Playbook + playbook item + answer filters    -->
    <playbook-item-answer-filters
      v-if="isPlaybookFilterVisible"
      class="mt-3"
      :ref="filterRefs['isPlaybookFilterVisible']"
      :value="{
        selectedPlaybooks,
        selectedPlaybookItems,
        selectedAnswers
      }"
      :criteria-configurator="true"
      @input="playbookItemAnswerSelected"
    ></playbook-item-answer-filters>

    <!-- Duration Filter    -->
    <duration-classificator v-if="isDurationFilterVisible"
                            v-model=callDuration
                            class="mt-3"
                            :ref="filterRefs['isDurationFilterVisible']"
                            :required="true"
                            @input="configChanged"
    ></duration-classificator>
  </div>
</template>

<script>

import CriteriaSelector from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/CriteriaSelector"
import DurationClassificator
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/DurationClassificator"
import PlaybookItemAnswerFilters
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/PlaybookItemAnswerFilters"

export default {
  name: "BaoWidgetCriteriaConfigurator",
  components: { DurationClassificator, CriteriaSelector, PlaybookItemAnswerFilters },
  props: {
    configuration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedCriteria: [],
      selectedPlaybooks: [],
      selectedPlaybookItems: [],
      selectedAnswers: [],
      callDuration: 60,
      options: this.configuration.options,
      staticTextDefault: {
        counterpartReachability: "Counterpart Reachability",
        callResult: "Positive Call Result",
        duration: "Call Duration",
        answer: "Playbook + Item + Answer"
      },
      filterRefs: {
        isPlaybookFilterVisible: "playbookItemAnswerFilter",
        isDurationFilterVisible: "durationClassificator"
      }
    }
  },
  created () {
    this.setUp()
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    criteriaOptions () {
      let options = []
      for (const item of this.options) {
        if (item === "counterpartReachability") {
          options = [...options, { label: this.staticText.counterpartReachability, type: "counterpartReachability" }]
        } else if (item === "callResult") {
          options = [...options, { label: this.staticText.callResult, type: "callResult" }]
        } else if (item === "callDuration") {
          options = [...options, { label: this.staticText.duration, type: "callDuration" }]
        } else if (item === "answer") {
          options = [...options, { label: this.staticText.answer, type: "answer" }]
        }
      }
      return options
    },
    isPlaybookFilterVisible () {
      return this.selectedCriteria && this.selectedCriteria.type === "answer"
    },
    isDurationFilterVisible () {
      return this.selectedCriteria && this.selectedCriteria.type === "callDuration"
    }
  },
  methods: {
    setUp () {
      this.transformToDisplay()
      this.configChanged()
    },
    transformToDisplay () {
      const existingConfig = this.configuration.value || {}
      for (const [key, value] of Object.entries(existingConfig)) {
        if (this.isType(key) && !this.isEmpty(value)) {
          this.selectedCriteria = this.criteriaOptions.find(element => element.type === value)
        } else if (this.isPlaybook(key)) {
          this.selectedPlaybooks = value
        } else if (this.isPlaybookItem(key)) {
          this.selectedPlaybookItems = value
        } else if (this.isAnswer(key)) {
          this.selectedAnswers = value
        } else if (this.isDuration(key)) {
          this.callDuration = value
        }
      }
    },
    getDataToSave () {
      const configToSave = {}
      configToSave.type = this.selectedCriteria.type
      if (this.isPlaybookFilterVisible) {
        if (!this.isEmpty(this.selectedPlaybooks)) {
          configToSave.playbooks = this.selectedPlaybooks
        }
        if (!this.isEmpty(this.selectedPlaybookItems)) {
          configToSave.playbookItems = this.selectedPlaybookItems
        }
        if (!this.isEmpty(this.selectedAnswers)) {
          configToSave.answers = this.selectedAnswers
        }
      } else if (this.isDurationFilterVisible) {
        if (!this.isEmpty(this.callDuration)) {
          configToSave.callDuration = this.callDuration
        }
      }
      return configToSave
    },
    isType (option) {
      return option === "type"
    },
    isPlaybook (option) {
      return option === "playbooks"
    },
    isPlaybookItem (option) {
      return option === "playbookItems"
    },
    isAnswer (option) {
      return option === "answers"
    },
    isDuration (option) {
      return option === "callDuration"
    },
    playbookItemAnswerSelected (value) {
      this.selectedPlaybooks = value.selectedPlaybooks
      this.selectedPlaybookItems = value.selectedPlaybookItems
      this.selectedAnswers = value.selectedAnswers
      this.configChanged()
    },
    configChanged () {
      if (this.checkRequiredFieldsUnanswered()) return
      const data = this.getDataToSave()
      this.$emit("change", data)
    },
    checkRequiredFieldsUnanswered () {
      if (this.isPlaybookFilterVisible) {
        return this.isEmpty(this.selectedPlaybooks) ||
          this.isEmpty(this.selectedPlaybookItems) ||
          this.isEmpty(this.selectedAnswers)
      }
      return false
    },
    isEmpty (value) {
      if (value === undefined || value === null || value === "") return true
      return Array.isArray(value) && (value.length <= 0)
    },
    validateFilter () {
      let refLabel = null
      if (this.isPlaybookFilterVisible) {
        refLabel = this.filterRefs.isPlaybookFilterVisible
      } else if (this.isDurationFilterVisible) {
        refLabel = this.filterRefs.isDurationFilterVisible
      }
      return refLabel ? this.$refs[refLabel].validateFilter() : true
    }
  }
}

</script>
