<template>
  <div class="h-100">
    <b-card class="h-100 rounded-border">

      <template v-slot:header>

        <div :id="'chartTitle-'+chart.id"
             class="mb-0 card-title"
        >
          {{ chart.title }}
        </div>
        <img :id="'chartInfo-'+chart.id" src="../../../../public/img/icons/info-icon.svg" style="cursor: pointer;"/>

        <b-tooltip :id="'chartDescription-' + chart.id"
                   :target="'chartInfo-'+ chart.id"
        >
          {{ chart.description }}
        </b-tooltip>
      </template>

      <div class="w-100 h-100 my-auto mx-auto">

        <div v-if="loading"
             id="loading-indicator-202012311632"
        >
          <progress-indicator></progress-indicator>
        </div>

        <div v-show="!loading"
             class="my-auto mx-auto"
        >
          <bao-dashboard-chart :widget="chart"
                               @loading-changed="setLoading"
          ></bao-dashboard-chart>
        </div>

      </div>

    </b-card>
  </div>
</template>

<script>
import BaoDashboardChart from "./BaoDashboardChart"
import ProgressIndicator from "../../base/ProgressIndicator"

export default {
  name: "BaoDashboardCard",
  data () {
    return {
      loading: false
    }
  },
  components: {
    ProgressIndicator,
    BaoDashboardChart
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  computed: {
    chart () {
      return this.widget
    }
  },
  methods: {
    setLoading (loading) {
      this.loading = loading
    }
  }
}
</script>

<style scoped lang="scss">
.rounded-border {
  border-radius: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  line-height: 19px;
  background-color: $grey;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  color: $slate;
}

.card-title{
  color: $slate;
  opacity: 0.8;
}
</style>
