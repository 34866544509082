/***
 * networkController controls the flow of simultaneous requests for the same object to
 * guarantee consistency and avoid race condition.
 */
export const networkController = {
  waitingQueue: {},
  methods: {
    sendRequest (index, request) {
      /**
       *  Returns a wrapped promise that waits until waiting/in-progress operation with same index
       *  is completed
       *  Adds the pending promise to waiting queue
       */
      const promise = networkController.methods.executeRequest(index, request)
      networkController.methods.addToQueue(index, promise)
      return promise
    },
    getQueue (index) {
      /**
       * Returns the queue of waiting operations for given index
       */
      return networkController.waitingQueue[index] ? networkController.waitingQueue[index] : []
    },
    addToQueue (index, data) {
      /**
       * Updates the queue of the given index with given data
       */
      const queue = networkController.methods.getQueue(index)
      queue.push(data)
      networkController.waitingQueue[index] = queue
    },
    getLatestOperation (index) {
      /**
       * Returns the latest operation(promise) from the queue of the given index
       */
      const queue = networkController.methods.getQueue(index)
      return queue.length > 0 ? queue[queue.length - 1] : Promise.resolve()
    },
    removeFromQueue (index) {
      /**
       * Removes the oldest operation(promise) from the queue of the given index
       */
      const queue = networkController.methods.getQueue(index)
      queue.shift()
      networkController.waitingQueue[index] = queue
    },
    executeRequest (index, request) {
      /**
       * Returns a promise that wraps existing waiting/in-progress operation(promise)
       */
      const existingPromise = networkController.methods.getLatestOperation(index)
      return new Promise((resolve, reject) => {
        existingPromise.finally(() => {
          return request().then((response) => {
            networkController.methods.removeFromQueue(index)
            resolve(response)
          }).catch(error => {
            reject(error)
          })
        })
      })
    },
    checkAllRequestsCompleted () {
      /**
       * Waits until all pending requests in the networkController queue to finish before resolving the promise
       * */
      return new Promise((resolve, reject) => {
        const waitFor = []
        for (const index in networkController.waitingQueue) {
          for (const waitingPromise of networkController.waitingQueue[index]) {
            waitFor.push(waitingPromise)
          }
        }
        Promise.all(waitFor).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
