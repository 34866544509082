import moment from "moment"
import {
  createdDateArray,
  getDefaultHorizontalBarChartOptions,
  getDefaultLineChartOptions,
  getThisMonth,
  getUsername,
  transformDateRange
} from "./chartUtils"
import axios from "axios"
import BaoWidgetMostFrequentShortcuts from "@/apps/dashboard/BaoWidgets/BaoWidgetMostFrequentShortcuts"
import BaoWidgetCallsPerDay from "@/apps/dashboard/BaoWidgets/BaoWidgetCallsPerDay"
import BaoWidgetCallsPerPlaybook from "@/apps/dashboard/BaoWidgets/BaoWidgetCallsPerPlaybook"
import BaoWidgetCallsPerUser from "@/apps/dashboard/BaoWidgets/BaoWidgetCallsPerUser"
import BaoWidgetCallsPerMonth from "@/apps/dashboard/BaoWidgets/BaoWidgetCallsPerMonth"
import BaoDashboardCard from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoDashboardCard"
import BaoWidgetCallAttemptsGoal from "@/apps/dashboard/BaoWidgets/BaoWidgetCallAttemptsGoal"
import BaoWidgetTeamCallActivity from "@/apps/dashboard/BaoWidgets/BaoWidgetTeamCallActivity"
import BaoWidgetReachabilityMatrix from "@/apps/dashboard/BaoWidgets/BaoWidgetReachabilityMatrix/BaoWidgetReachabilityMatrix"
import BaoWidgetConversationGoal from "@/apps/dashboard/BaoWidgets/BaoWidgetConversationGoal/BaoWidgetConversationGoal"
import BaoWidgetPlaybookConversion from "@/apps/dashboard/BaoWidgets/BaoWidgetPlaybookConversion/BaoWidgetPlaybookConversion"
import BaoWidgetSuccessGoal from "@/apps/dashboard/BaoWidgets/BaoWidgetSuccessGoal"
import BaoWidgetCallDurationStats from "@/apps/dashboard/BaoWidgets/BaoWidgetCallDurationStats"
import BaoWidgetTotalCallTime from "@/apps/dashboard/BaoWidgets/BaoWidgetTotalCallTime"
import BaoWidgetTimeGraph from "@/apps/dashboard/BaoWidgets/BaoWidgetTimeGraph"
import BaoWidgetMostFrequentAnswersForItem from "@/apps/dashboard/BaoWidgets/BaoWidgetMostFrequentAnswersForItem"

const BaoAnalytics = () => import(/* webpackChunkName: "BaoAnalytics" */ "@/apps/dashboard/BaoAnalytics")

export const defaultLineDatasetValues = {
  backgroundColor: "rgba(255, 86, 50, 0.08)",
  borderColor: "#B15224",
  pointBorderColor: "#B15224",
  borderWidth: 1
}

export const defaultHorizontalDatasetValues = {
  backgroundColor: "rgba(42, 45, 82, 0.8)",
  borderColor: "rgba(42, 45, 82, 0.8)",
  borderWidth: 1
}

export function getDataForWidget (widget) {
  return new Promise((resolve, reject) => {
    if (!widget.id) reject(new Error("Widget has no id, aborting."))
    axios.get(`/api/widgets/${widget.id}/query`).then(resolve)
  })
}

export function getDefaultWidgets () {
  return {
    activity_over_time: {
      component: BaoWidgetTimeGraph,
      type: "activity_over_time",
      thumbnail: "/img/widgetThumbnails/conversationsPerMonth.svg",
      deprecated: false
    },
    conversations_per_month: {
      component: BaoWidgetCallsPerMonth,
      type: "conversations_per_month",
      thumbnail: "/img/widgetThumbnails/conversationsPerMonth.svg",
      deprecated: true
    },
    team_call_activity: {
      component: BaoWidgetTeamCallActivity,
      type: "team_call_activity",
      thumbnail: "/img/widgetThumbnails/conversationsPerUser.svg",
      deprecated: false
    },
    daily_activity_goal: {
      component: BaoWidgetCallAttemptsGoal,
      type: "daily_activity_goal",
      thumbnail: "/img/widgetThumbnails/daily_activity_goal.svg",
      deprecated: false
    },
    daily_success_goal: {
      component: BaoWidgetConversationGoal,
      type: "daily_success_goal",
      thumbnail: "/img/widgetThumbnails/dailySuccessGoal.svg",
      deprecated: false
    },
    conversations_per_day: {
      component: BaoWidgetCallsPerDay,
      type: "conversations_per_day",
      thumbnail: "/img/widgetThumbnails/conversationsPerDay.svg",
      deprecated: true
    },
    monthly_success_goal: {
      component: BaoWidgetSuccessGoal,
      type: "monthly_success_goal",
      thumbnail: "/img/widgetThumbnails/conversationsPerMonth.svg",
      deprecated: false
    },
    conversations_per_playbook: {
      component: BaoWidgetCallsPerPlaybook,
      type: "conversations_per_playbook",
      thumbnail: "/img/widgetThumbnails/conversationsPerPlaybook.svg",
      deprecated: false
    },
    conversations_per_user: {
      component: BaoWidgetCallsPerUser,
      type: "conversations_per_user",
      thumbnail: "/img/widgetThumbnails/conversationsPerUser.svg",
      deprecated: true
    },
    answers_for_item: {
      component: BaoWidgetMostFrequentAnswersForItem,
      type: "answers_for_item",
      thumbnail: "/img/widgetThumbnails/most_frequent_answers.svg",
      deprecated: false
    },
    most_frequent_objections: {
      component: BaoWidgetMostFrequentShortcuts,
      type: "most_frequent_objections",
      thumbnail: "/img/widgetThumbnails/mostFrequentObjections.svg",
      deprecated: false
    },
    reachability_matrix: {
      component: BaoWidgetReachabilityMatrix,
      type: "reachability_matrix",
      thumbnail: "/img/widgetThumbnails/reachabilityMatrix.svg",
      deprecated: false
    },
    playbook_conversion: {
      component: BaoWidgetPlaybookConversion,
      type: "playbook_conversion",
      thumbnail: "/img/widgetThumbnails/playbookConversion.svg",
      deprecated: false
    },
    call_duration_stats: {
      component: BaoWidgetCallDurationStats,
      type: "call_duration_stats",
      thumbnail: "/img/widgetThumbnails/conversationsPerDay.svg",
      deprecated: false
    },
    total_call_time: {
      component: BaoWidgetTotalCallTime,
      type: "total_call_time",
      thumbnail: "/img/widgetThumbnails/baoWidgetTotalCallTime.svg",
      deprecated: false
    }
  }
}

/* TODO:can be removed */
export function getAvailableCharts (staticText) {
  const MAX_ITEM_NUMBER = 10

  return [
    {
      id: 1,
      title: staticText.conversationsPerMonthLabel,
      description: staticText.conversationsPerMonthDescription,
      url: "/api/calls/stats?group_by[created_at__year]&group_by[created_at__month]&aggregate[Count]=id",
      filter: {
        created_at__range: {
          field: "created_at__range",
          value: {
            start: moment().subtract(11, "month").startOf("month"),
            end: moment().endOf("month")
          },
          transform: transformDateRange
        }
      },
      chartType: "line",
      mappingFunc (items) {
        const dateArray = createdDateArray(moment().subtract(11, "month"), "month")
        const data = dateArray.map(date => {
          const result = { text: date.toLocaleString("default", { month: "short", year: "numeric" }), count: 0 }
          const data = items.filter(item => item.created_at__month === moment(date).month() + 1)
          if (data && data.length > 0) result.count = data[0].count_id
          return result
        })
        const result = {}
        result.labels = data.map(item => item.text)
        result.datasets = [{
          ...defaultLineDatasetValues,
          data: data.map(item => Math.round(item.count * 100) / 100)
        }]
        return result
      },
      getOptions: getDefaultLineChartOptions
    },
    {
      id: 5,
      title: staticText.conversationsPerPlaybookLabel,
      description: staticText.conversationsPerPlaybookDescription,
      url: "/api/calls/stats?group_by[talkscript__name]&aggregate[Count]=id",
      filter: {
        created_at__range: {
          field: "created_at__range",
          value: getThisMonth(),
          transform: transformDateRange,
          label: "Date Filter"
        }
      },
      chartType: "horizontalbar",
      mappingFunc (items) {
        items = items.sort((a, b) => b.count_id - a.count_id)
          .filter(item => item.count_id > 0)
          .splice(0, MAX_ITEM_NUMBER)
        const labels = []
        const data = []
        for (const item of items) {
          labels.push(item.talkscript__name)
          data.push(item.count_id)
        }
        return {
          labels,
          datasets: [{ ...defaultHorizontalDatasetValues, data }]
        }
      },
      getOptions: getDefaultHorizontalBarChartOptions
    },
    {
      id: 3,
      title: staticText.conversationsPerUserLabel,
      description: staticText.conversationsPerUserDescription,
      url: "/api/calls/stats?group_by[user__email]&group_by[user__first_name]&group_by[user__last_name]&aggregate[Count]=id",
      filter: {
        created_at__range: {
          field: "created_at__range",
          value: getThisMonth(),
          transform: transformDateRange
        },
        user__is_active: {
          field: "user__is_active",
          value: true
        }
      },
      chartType: "horizontalbar",
      mappingFunc (items) {
        items = items.sort((a, b) => b.count_id - a.count_id).splice(0, MAX_ITEM_NUMBER)
        const labels = []
        const data = []
        for (const item of items) {
          labels.push(getUsername(item))
          data.push(item.count_id)
        }
        return {
          labels,
          datasets: [{ ...defaultHorizontalDatasetValues, data }]
        }
      },
      getOptions: getDefaultHorizontalBarChartOptions
    },
    {
      id: 10,
      title: staticText.conversationsPerDayLabel,
      description: staticText.conversationsPerDayDescription,
      url: "/api/calls/stats?group_by[created_at__date]&aggregate[CountDistinct]=id",
      filter: {
        created_at__range: {
          field: "created_at__range",
          value: getThisMonth(),
          transform: transformDateRange
        }
      },
      chartType: "line",
      mappingFunc: items => {
        const dateArray = createdDateArray(moment().startOf("month"), "day", moment().endOf("month"))
        items = dateArray.map(date => {
          const result = { text: date.toLocaleString("default", { month: "short", day: "numeric" }), count: 0 }
          const data = items.filter(item => item.created_at__date === moment(date).format("YYYY-MM-DD"))
          if (data && data.length > 0) result.count = data[0].countdistinct_id
          return result
        })
        const result = {}
        result.labels = items.map(item => item.text)
        result.datasets = [{
          data: items.map(item => Math.round(item.count * 100) / 100),
          ...defaultLineDatasetValues
        }]
        return result
      },
      getOptions: getDefaultLineChartOptions
    },
    {
      id: 8,
      title: staticText.objectionsPerPlaybookLabel,
      description: staticText.objectionsPerPlaybookDescription,
      url: "/api/calls/stats?group_by[talkscript]&group_by[talkscript__name]&aggregate[CountIfTrue]=additional_data_for_call__is_objection",
      filter: {
        created_at__range: {
          field: "created_at__range",
          value: getThisMonth(),
          transform: transformDateRange,
          label: "Date Filter"
        }
      },
      chartType: "horizontalbar",
      mappingFunc (items) {
        items = items.map(item => {
          return {
            text: item.talkscript__name,
            count: item.countiftrue_additional_data_for_call__is_objection
          }
        }).sort((a, b) => b.count - a.count).filter(item => item.count > 0).splice(0, MAX_ITEM_NUMBER)
        const result = {}
        result.labels = items.map(item => item.text)
        result.datasets = [{
          data: items.map(item => item.count),
          ...defaultHorizontalDatasetValues
        }]
        return result
      },
      getOptions: getDefaultHorizontalBarChartOptions
    },
    {
      id: 7,
      title: staticText.objectionsPerUserLabel,
      description: staticText.objectionsPerUserDescription,
      url: "/api/calls/stats?group_by[created_by__email]&group_by[created_by__first_name]&group_by[created_by__last_name]&aggregate[CountIfTrue]=additional_data_for_call__is_objection",
      filter: {
        created_at__range: {
          field: "created_at__range",
          value: getThisMonth(),
          transform: transformDateRange,
          label: "Date Filter"
        }
      },
      chartType: "horizontalbar",
      mappingFunc (items) {
        items = items.map(item => {
          return {
            text: getUsername(item, "created_by"),
            count: item.countiftrue_additional_data_for_call__is_objection
          }
        }).sort((a, b) => b.count - a.count).filter(item => item.count > 0).splice(0, MAX_ITEM_NUMBER)
        const result = {}
        result.labels = items.map(item => item.text)
        result.datasets = [{
          data: items.map(item => item.count),
          ...defaultHorizontalDatasetValues
        }]
        return result
      },
      getOptions: getDefaultHorizontalBarChartOptions
    }
  ].map(item => {
    item.loading = true
    item.component = BaoDashboardCard
    return item
  })
}

export function playbookAnalyticsPage (staticText) {
  return {
    id: "playbookanalytics",
    name: staticText.playbookAnalytics,
    widgets: [{
      size: 2,
      component: BaoAnalytics
    }],
    static: true,
    icon: "/img/icons/bar-chart.svg"
  }
}

export function getApexLineChartDefaultOptions (widget, labels) {
  const widgetName = widget.name ? widget.name : widget.title
  const chartId = widgetName.split(" ").join("") + widget.id
  return {
    chart: {
      type: "line",
      id: chartId,
      toolbar: {
        show: false
      }
    },
    colors: ["rgba(255, 86, 50, 0.4)", "rgba(42, 45, 82, 0.4)"],
    yaxis: {
      labels: {
        formatter: function (val, index) {
          return val.toFixed(0)
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1,
      curve: ["stepline", "straight"]
    },
    grid: {
      padding: {
        right: 30,
        left: 20
      }
    },
    labels,
    tooltip: {
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          return Math.round(value)
        }
      }
    }
  }
}
