import {
  FILTER_KEY_TAGS,
  FILTER_KEY_TEXT_MATCH,
  FILTER_KEY_USER,
  FILTER_KEY_OBJECTIONS,
  FILTER_KEY_COUNTERPART_REACHABILITY,
  FILTER_KEY_CALL_RESULT,
  FILTER_KEY_ANSWER,
  FILTER_KEY_CALL_DURATION,
  FILTER_KEY_OBJECTIONS__OBJECTIONS,
  FILTER_KEY_TIMEFRAME,
  FILTER_KEY_TRANSCRIPT_SEARCH,
  FILTER_KEY_PLAYBOOK
} from "@/apps/dashboard/chartUtils"

export const MINIMUM_CALL_DURATION_FILTER_KEY = "duration__gte"
export const MAXIMUM_CALL_DURATION_FILTER_KEY = "duration__lte"
export const LABELS_FILTER_KEY = "tags__name__in"
export const PLAYBOOK_FILTER_LABEL = "playbook_id"
export const USER_PK_FILTER_LABEL = "user__id__in"
export const CREATED_AT_START_DATE_FILTER_KEY = "created_at__gte"
export const ITEM_ANSWER_PLAYBOOK_FILTER_KEY = "call_items__talkscript__id"
export const ITEM_ANSWER_PLAYBOOK_ITEM_FILTER_KEY = "call_items__talkscript_item__in"
export const ITEM_ANSWER_FILTER_KEY = "call_items__answers__text__in"
export const OBJECTION_ITEM_FILTER_KEY = "selected_objections"
export const COUNTERPART_AVAILABLE_FILTER_LABEL = "counterpart_available"
export const AUDIO_AVAILABLE_FILTER_LABEL = "audio_available"
export const VIDEO_AVAILABLE_FILTER_LABEL = "video_available"
export const CALL_DURATION_FILTER_LABEL = "duration"
export const CREATED_AT_END_DATE_FILTER_KEY = "created_at_end_date"
export const USER_TYPE_FILTER_KEY = "user_type"
export const INDIVIDUAL_USERS_LABEL = "individual_users"
export const SUCCESS_CRITERIA_ITEM_ANSWER_PLAYBOOK_FILTER_KEY = "successCriteria_playbook_id"
export const SUCCESS_CRITERIA_ITEM_ANSWER_PLAYBOOK_ITEM_FILTER_KEY = "successCriteria_playbook_item_id"
export const SUCCESS_CRITERIA_ITEM_ANSWER_FILTER_KEY = "successCriteria_answers_text"
export const SUCCESS_CRITERIA_MINIMUM_CALL_DURATION_FILTER_KEY = "successCriteria_minimum_duration"
export const PLAYBOOK_ITEM_ANSWER_FILTER_LABEL = "playbook_item_and_answer"
export const TRANSCRIPTS_SEARCH_FILTER_TEXT = "transcript_texts"
export const TRANSCRIPTS_SEARCH_FILTER_SIMILARITY = "transcript_similarity"
export const ARRAY_DATA_TYPE_LABEL = "array"
export const OBJECT_DATA_TYPE_LABEL = "object"

export const transformFilters = ({ dataType, activeFilters }) => {
  // This function transforms the widgets/conversation history filters in a way that aligns with how the calls are filtered on the backend
  // The widget filters come in Object format and the Conversation History filters come in arrays, hence we have "dataType" passed in the
  // function, which is then passed in the filterLength, filterArray, filterKey and filterValue functions to get the length, Array for the
  // loop. the key and the value of the filters
  const transformedFilters = {}
  if (filterLength({ dataType, activeFilters })) transformedFilters.advanced_filters = true

  for (const filter of filterArray({ dataType, activeFilters })) {
    const key = filterKey({ dataType, filter })
    const value = filterValue({ dataType, filter })

    if (key === CALL_DURATION_FILTER_LABEL) {
      if (value.gte || value.gte === 0) transformedFilters[MINIMUM_CALL_DURATION_FILTER_KEY] = value.gte
      if (value.lte || value.lte === 0) transformedFilters[MAXIMUM_CALL_DURATION_FILTER_KEY] = value.lte
    } else if (key === FILTER_KEY_TIMEFRAME) {
      if (typeof value !== "string") {
        transformedFilters[CREATED_AT_START_DATE_FILTER_KEY] = value.created_at__gte
        transformedFilters[CREATED_AT_END_DATE_FILTER_KEY] = value.created_at__lte
      } else transformedFilters[key] = value
    } else if (key === PLAYBOOK_ITEM_ANSWER_FILTER_LABEL) {
      if (value.call_items__talkscript__id__in[0] !== null) transformedFilters[ITEM_ANSWER_PLAYBOOK_FILTER_KEY] = value.call_items__talkscript__id__in.toString()
      transformedFilters[ITEM_ANSWER_PLAYBOOK_ITEM_FILTER_KEY] = value.call_items__talkscript_item__id__in
      transformedFilters[ITEM_ANSWER_FILTER_KEY] = value.call_items__answers__text__in
    } else if (key === FILTER_KEY_OBJECTIONS) {
      transformedFilters[OBJECTION_ITEM_FILTER_KEY] = value[FILTER_KEY_OBJECTIONS__OBJECTIONS].toString()
    } else if (key === FILTER_KEY_TAGS) {
      transformedFilters[LABELS_FILTER_KEY] = value
    } else if (key === FILTER_KEY_TEXT_MATCH) {
      if (Object.entries(value).length) {
        transformedFilters.call_transcript__text_similarity__gte = value.min_value ? value.min_value : 0
        transformedFilters.call_transcript__text_similarity__lte = value.max_value ? value.max_value : 100
      }
    } else if (key === FILTER_KEY_TRANSCRIPT_SEARCH) {
      transformedFilters[TRANSCRIPTS_SEARCH_FILTER_TEXT] = value.transcript_texts
      transformedFilters[TRANSCRIPTS_SEARCH_FILTER_SIMILARITY] = value.includeSimilar
    } else if (key === FILTER_KEY_USER) {
      if (value.filterKey === USER_TYPE_FILTER_KEY) {
        transformedFilters[USER_TYPE_FILTER_KEY] = value.selectedUser.toString()
      } else transformedFilters[INDIVIDUAL_USERS_LABEL] = value.selectedUser
    } else if (key === FILTER_KEY_PLAYBOOK) {
      transformedFilters[PLAYBOOK_FILTER_LABEL] = value
    } else transformedFilters[key] = value.toString()
  }

  return transformedFilters
}

export const filterLength = ({ dataType, activeFilters }) => {
  if (dataType === ARRAY_DATA_TYPE_LABEL) return activeFilters.length
  else return Object.keys(activeFilters).length
}

export const filterArray = ({ dataType, activeFilters }) => {
  if (dataType === ARRAY_DATA_TYPE_LABEL) return activeFilters
  else return Object.entries(activeFilters)
}

export const filterKey = ({ dataType, filter }) => {
  if (dataType === ARRAY_DATA_TYPE_LABEL) return filter.query_label
  else return filter[0]
}

export const filterValue = ({ dataType, filter }) => {
  if (dataType === ARRAY_DATA_TYPE_LABEL) return filter.value
  else return filter[1]
}

export const transformSuccessCriteria = ({ successCriteria, transformedFilters }) => {
  transformedFilters.success_criteria = transformedFilters.advanced_filters = true

  if (successCriteria.type === FILTER_KEY_COUNTERPART_REACHABILITY) {
    transformedFilters[FILTER_KEY_COUNTERPART_REACHABILITY] = true
  } else if (successCriteria.type === FILTER_KEY_CALL_RESULT) {
    transformedFilters[FILTER_KEY_CALL_RESULT] = true
  } else if (successCriteria.type === FILTER_KEY_ANSWER) {
    if (successCriteria.playbooks[0] !== null) transformedFilters[SUCCESS_CRITERIA_ITEM_ANSWER_PLAYBOOK_FILTER_KEY] = successCriteria.playbooks.toString()
    transformedFilters[SUCCESS_CRITERIA_ITEM_ANSWER_PLAYBOOK_ITEM_FILTER_KEY] = successCriteria.playbookItems
    transformedFilters[SUCCESS_CRITERIA_ITEM_ANSWER_FILTER_KEY] = successCriteria.answers
  } else if (successCriteria.type === FILTER_KEY_CALL_DURATION) {
    transformedFilters[SUCCESS_CRITERIA_MINIMUM_CALL_DURATION_FILTER_KEY] = Number(successCriteria.callDuration) * 1000
  }

  return transformedFilters
}
