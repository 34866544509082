const MeetingBotStatusEnum = Object.freeze({
  NOT_STARTED: "not_started",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  ENDED: "ended",
  DONE: "done"
})

const BotRecordingStatusEnum = Object.freeze({
  NOT_RECORDING: "not_recording",
  RECORDING: "recording",
  TOGGLING: "toggling"
})

const BotTerminatedStatusList = ["meeting_bot_ended", "meeting_bot_data_processing_done"]
const MeetingStatusListForVideoData = ["connected", "done", "ended"]
const MeetingEndedStatusList = ["done", "ended"]
const MeetingDataProcessingDoneStatus = "meeting_bot_data_processing_done"
const BotConnectedStatus = "meeting_bot_connected"

export {
  MeetingBotStatusEnum, BotRecordingStatusEnum, BotTerminatedStatusList,
  MeetingStatusListForVideoData, MeetingEndedStatusList, MeetingDataProcessingDoneStatus,
  BotConnectedStatus
}
