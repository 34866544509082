const ResultItemConfigurator = () => import(/* webpackChunkName: "ResultItemConfigurator" */ "./ResultItemList.vue")

// exports basic app infos required for other modules and CallViewItem_components
const baseUrl = "/conversationresult"

export default {
  name: "CallResult",
  apiUrl: "/api/resultitems",
  route: {
    path: baseUrl,
    component: ResultItemConfigurator
  }
}
