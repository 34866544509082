<template>
  <base-filter
    :value="options.value"
    :label="options.label || staticText.selectAnswers"
    :showLabel="false"
    :url="getUrl"
    :multiselect="options.multiselect"
    key-to-extract-value="name"
    :required="options.required"
    :internal-search="true"
    :hasError="hasError"
    @input="handleValueChanged"
  ></base-filter>
</template>

<script>
import BaseFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/BaseFilter"
import { WidgetConfigOptions } from "@/apps/dashboard/chartUtils"

export default {
  name: "AnswerSelector",
  components: { BaseFilter },
  props: {
    options: {
      type: WidgetConfigOptions,
      required: true
    },
    selectedPlaybookItems: { // playbook items selected for filtering
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      selectedItemAnswers: [],
      hasError: false,
      staticTextDefault: {
        selectAnswers: "Item answers"
      }
    }
  },
  computed: {
    getPlaybookItemFilter () {
      return `&id__in=${this.selectedPlaybookItems}`
    },
    getUrl () {
      return `/api/talkscriptitems/get_input_items?${this.getPlaybookItemFilter}`
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.selectedItemAnswers = this.options.value || []
  },
  methods: {
    handleValueChanged (newValue) {
      this.selectedItemAnswers = newValue
      this.$emit("input", this.selectedItemAnswers)
      if (this.selectedItemAnswers.length > 0) this.validateFilter()
    },
    validateFilter () {
      this.hasError = !this.selectedItemAnswers.length
      return !this.hasError
    }
  }
}
</script>

<style scoped>

</style>
