<template>
  <div class="d-flex">
    <bao-widget-filter-container
      :label="staticText.selectCriteria"
      label-tag="criteria-filter-202223051346"
      :required="required"
    >
      <vue-multiselect
        id="criteria-filter-202223051346"
        v-model="selected"
        :options="options"
        label="label"
        track-by="type"
        :show-labels="false"
        :allow-empty="false"
        :multiple="false"
        :placeholder="staticText.selectCriteria"
        @input="inputValue=>$emit('input', inputValue)"
      ></vue-multiselect>
    </bao-widget-filter-container>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"
import axios from "axios"
import BaoWidgetFilterContainer from "./Filter/BaoWidgetFilterContainer.vue"

export default {
  name: "CriteriaSelector",
  components: { VueMultiselect, BaoWidgetFilterContainer },
  props: {
    value: { // criteria selected for filtering
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        selectCriteria: "Conversation Criteria"
      },
      selected: this.value
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped>

.red{
  color: red;
}

</style>
