import axios from "axios"
import { debug, setCookie, getCookie, removeCookie } from "@/utils/utils"

const BASE_URL = process.env.VUE_APP_BASEURL
const FORCE_LOGOUT = process.env.VUE_APP_FORCE_LOGOUT
const POPUP_LOGIN_FOR_SWIFT = process.env.VUE_APP_POPUP_LOGIN_FOR_SWIFT
const NON_ACCEPTABLE_STORAGE_VALUES = ["", undefined, "undefined", null]

export function clearStorage () {
  // TODO: leaving this here for the first release so we clear out
  // user's localStorage. After this following cleanup can be removed.
  localStorage.removeItem("access_token")
  localStorage.removeItem("refresh_token")
  localStorage.removeItem("refresh_token_created_at")

  removeBaoUserFromStorage()

  removeSocialAuthInProgress()
}

export const conditionalForceLogout = () => {
  // If it's set in server then users will be logged out
  if (["true", "True"].includes(FORCE_LOGOUT)) {
    // If user force logout has not never been done before
    if (getLocalStorage("force_logged_out") !== "true") {
      axios.post(BASE_URL + "api/users/logout/")
      clearStorage()
      setLocalStorage("force_logged_out", "true")
      window.location.reload()
    }
  }
  // If it's set to false then we remove the force_logged_out flag
  if (["false", "False"].includes(FORCE_LOGOUT)) {
    localStorage.removeItem("force_logged_out")
  }
}

export const setTokenPair = (accessToken, refreshToken) => {
  if (getLocalStorage("access_token")) {
    clearStorage()
  }
  const refreshTokenCreatedAt = Date.now().toString()
  setLocalStorage("access_token", accessToken)
  setLocalStorage("refresh_token", refreshToken)
  setLocalStorage("refresh_token_created_at", refreshTokenCreatedAt)
}

export const areFreshAuthTokens = () => {
  // We are only concerned if the auth tokens are fresh
  // I've arbitrarily set the limit to 60 seconds.
  const accessToken = getLocalStorage("access_token")
  const refreshToken = getLocalStorage("refresh_token")
  const refreshTokenCreatedAt = getLocalStorage("refresh_token_created_at")

  if (accessToken && refreshToken && refreshTokenCreatedAt) {
    if (Date.now() - parseInt(refreshTokenCreatedAt) <= 60 * 1000) {
      return true
    }
  }
  return false
}

export const isRefreshTokenExpired = () => {
  const refreshTokenCreatedAt = getLocalStorage("refresh_token_created_at")
  if (!refreshTokenCreatedAt) {
    return false
  }
  // Check if refresh token is more than a day old
  if (parseInt(refreshTokenCreatedAt) + 24 * 60 * 60 * 1000 < Date.now()) {
    return true
  }
  return false
}

// We use cookies for managing schema name
// so admin console is aware of the schema
export const setSchemaName = schemaName => {
  clearSchemaInfo()
  setCookie("schema_name", schemaName)
}

export const getSchemaName = () => getCookie("schema_name")

export const clearSchemaInfo = () => removeCookie("schema_name")

export const getSocialOTT = () => getLocalStorage("social_ott")

export const setSocialOTT = socialOTT => {
  setLocalStorage("social_ott", socialOTT)
}

export const clearSocialOTT = () => {
  localStorage.removeItem("social_ott")
}

export const getEmail = () => getLocalStorage("email")

export const setEmail = email => setLocalStorage("email", email)

export const clearEmail = () => localStorage.removeItem("email")

export const popupLoginForSwift = () => ["true", "True"].includes(POPUP_LOGIN_FOR_SWIFT)

export const isSocialAuthInProgress = () =>
  !!getLocalStorage("SocialAuthInProgress")

export const setSocialAuthInProgress = () => {
  setLocalStorage("SocialAuthInProgress", true)
  setLocalStorage("SocialAuthInProgressInitiateTime", Date.now().toString())
}

export const removeSocialAuthInProgress = () => {
  localStorage.removeItem("SocialAuthInProgress")
  localStorage.removeItem("SocialAuthInProgressInitiateTime")
  localStorage.removeItem("visit_count")
  // socialOTT was only needed for social authentication
  clearSocialOTT()
}

// If social authentication initiated more than 30 seconds ago or
// user has reloaded the page on existing loading screen, then
// clear out the SSO data and return true indicating the same
export const isSocialAuthProgressOldThenClear = () => {
  if (isSocialAuthInProgress()) {
    if (getLocalStorage("visit_count") === "1") {
      setLocalStorage("visit_count", "2")
    } else {
      setLocalStorage("visit_count", "1")
    }
    const initiateTime = getLocalStorage(
      "SocialAuthInProgressInitiateTime"
    )
    if (
      Date.now() - parseInt(initiateTime) > 120000 ||
      parseInt(getLocalStorage("visit_count")) > 1
    ) {
      removeSocialAuthInProgress()
      return true
    }
  }
  return false
}

export const getAccessToken = () => getLocalStorage("access_token")

export const getUserProfile = async () => {
  return axios.get(BASE_URL + "api/users/profile/", {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(({ data }) => {
    setLocalStorage("bao-user", JSON.stringify({
      client_name: data.client_name,
      email: data.email,
      full_name: data.full_name,
      language: data.language
    }))
    return Promise.resolve(data)
  }).catch(error => {
    debug.log("get user profile error", error)
    localStorage.removeItem("bao-user")
    return Promise.reject(error)
  })
}

export const getBaoUserFromStorage = () => JSON.parse(getLocalStorage("bao-user"))

export const removeBaoUserFromStorage = () => localStorage.removeItem("bao-user")

// Following is for function refreshAccessTokenAndRetry
// We que the failed requests and resolve them eventually
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

export const refreshTokensAndRetry = async (originalRequest) => {
  /*
    All failing requests are queued and retried after a new access token
    has been obtained. If request still fails with good access token,
    we let it fail.
  */
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject })
    }).then(token => {
      originalRequest.headers.Authorization = `Bearer ${token}`
      return axios(originalRequest)
    }).catch(err => {
      debug.log("refreshTokensAndRetry failed", err)
      clearStorage()
      return Promise.reject(err)
    })
  }

  isRefreshing = true

  const refreshToken = getLocalStorage("refresh_token")
  return new Promise((resolve, reject) => {
    axios.post(BASE_URL + "api/token/refresh/", { refresh: refreshToken })
      .then(({ data }) => {
        const { access, refresh } = data
        setTokenPair(access, refresh)
        axios.defaults.headers.common.Authorization = `Bearer ${data.access}`
        originalRequest.headers.Authorization = `Bearer ${data.access}`
        processQueue(null, data.access)
        resolve(axios(originalRequest))
      })
      .catch((err) => {
        processQueue(err, null)
        reject(err)
      })
      .finally(() => { isRefreshing = false })
  })
}

export const getLocalStorage = (key) => {
  const value = localStorage.getItem(key)
  if (!NON_ACCEPTABLE_STORAGE_VALUES.includes(value)) {
    return value
  }
  return null
}

export const getValueFromURLParams = (key = "") => {
  let decodedURI = decodeURIComponent(window.location.search)
  decodedURI = decodedURI.replaceAll("%40", "@")
  // Here decodedURI for email invite looks like:
  // "?redirect=/call?email=someone@domain.com&..."
  decodedURI = decodedURI.split("?")[2]
  const urlSearchParams = new URLSearchParams(decodedURI)
  return urlSearchParams.get(key)
}

export const lookupEmail = () => {
  const emailParam = getValueFromURLParams("email")
  return emailParam || getLocalStorage("email")
}

export const setLocalStorage = (key, value) => {
  if (!NON_ACCEPTABLE_STORAGE_VALUES.includes(value)) {
    localStorage.setItem(key, value)
  }
}
