/* eslint no-undef: 0 */
window._ctrack = []
window.trackEnabled = false

export function initCustifyTracker (accountId) {
  (function () {
    for (let t = ["identify", "track", "setInstance", "setAccount", "trackTime", "stopTrackTime", "debug"],
      a = function (t) {
        return function () {
          _ctrack.push([t].concat(Array.prototype.slice.call(arguments, 0)))
        }
      }, n = 0; n < t.length; n++) {
      _ctrack[t[n]] = a(t[n])
    }
    const g = document.createElement("script")
    const s = document.getElementsByTagName("script")[0]
    g.type = "text/javascript"
    g.async = true
    g.defer = true
    g.src = "https://assets.custify.com/assets/track.min.js"
    s.parentNode.insertBefore(g, s)
  })()

  _ctrack.setAccount(accountId)
}

// eslint-disable-next-line no-unused-vars
function identifyCustifyUser (user) {
  trackEnabled = (user != null)
  _ctrack.identify(trackEnabled ? user : { email: -1 })
}

// eslint-disable-next-line no-unused-vars
function track (eventName, data) {
  if (trackEnabled) _ctrack.track(eventName, data)
}
