<template>
  <div :class="['bao-widget-filter w-100 d-flex', { 'advanced-filters' : isOpenFromAdvancedFilters }]">
    <div class="w-100 filter-body">
      <label
        class="filter-title"
      >
        {{ staticText.playbookItemAnswerLabel }}
      </label>
      <playbook-selector
        :ref="filterRefs[0]"
        :options="playbookConfigOptions"
        @input="playbookSelected"
        @delete="$emit('delete')"
      ></playbook-selector>

      <playbook-item-selector
        class="mt-2"
        v-if="showTalkscriptItemSelection"
        :ref="filterRefs[1]"
        :options="playbookItemConfigOption"
        :selected-playbooks="selectedPlaybooks"
        :playbook-item-type="playbookItemType"
        @input="playbookItemSelected"
        @delete="playbookItemSelected([])"
      ></playbook-item-selector>

      <answer-selector
        class="mt-2"
        v-if="showAnswerSelection"
        v-model="selectedAnswers"
        :ref="filterRefs[2]"
        :options="answerConfigOption"
        :selected-playbook-items="selectedPlaybookItems"
        @input="answerSelected"
        @delete="answerSelected([])"
      ></answer-selector>
    </div>
    <div
      v-if="!criteriaConfigurator"
      @click.prevent="$emit('delete')"
      class="ml-3 filter-delete"
    >
      <i
        v-if="!isOpenFromAdvancedFilters"
        class="fa fa-trash my-auto"
      ></i>
      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
    </div>
  </div>
</template>

<script>

import PlaybookSelector from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/PlaybookSelector"
import PlaybookItemSelector from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/PlaybookItemSelector"
import AnswerSelector from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/AnswerSelector"
import { getDefaultConfigOptions, LIBRARY_ITEM_TYPE_LABEL, NON_LIBRARY_ITEM_TYPE_LABEL } from "@/apps/dashboard/chartUtils"

export default {
  name: "PlaybookItemAnswerFilters",
  components: { PlaybookSelector, PlaybookItemSelector, AnswerSelector },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return { playbooks: [], playbookItems: [], answers: [] }
      }
    },
    criteriaConfigurator: {
      type: Boolean,
      default: false,
      required: false
    },
    noAnswerRequired: {
      type: Boolean,
      default: false,
      required: false
    },
    isOpenFromAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        playbookItemAnswerLabel: "Playbook, Item, Answer",
        me: "Me",
        team: "Team + Me"
      },
      configurations: [],
      selectedPlaybooks: [],
      selectedPlaybookItems: [],
      selectedAnswers: [],
      filterRefs: ["playbook-filter", "playbook-item-filter", "answer-filter"]
    }
  },
  created () {
    this.setUp()
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showTalkscriptItemSelection () {
      return this.selectedPlaybooks && this.selectedPlaybooks.length > 0
    },
    showAnswerSelection () {
      return !this.noAnswerRequired && this.selectedPlaybookItems && this.selectedPlaybookItems.length > 0
    },
    playbookConfigOptions () {
      return getDefaultConfigOptions({
        value: this.selectedPlaybooks,
        labelForPlaybookItemAnswer: true,
        showDelete: false,
        enableGrouping: true,
        ...this.criteriaAdditionalConfig
      })
    },
    internalDefaultConfig () {
      return {
        showLabel: false,
        multiselect: true
      }
    },
    criteriaAdditionalConfig () {
      return this.criteriaConfigurator ? { required: true } : {}
    },
    playbookItemConfigOption () {
      return getDefaultConfigOptions({
        value: this.selectedPlaybookItems,
        ...this.internalDefaultConfig,
        ...this.criteriaAdditionalConfig
      })
    },
    answerConfigOption () {
      return getDefaultConfigOptions({
        value: this.selectedAnswers,
        ...this.internalDefaultConfig,
        ...this.criteriaAdditionalConfig
      })
    },
    playbookItemType () {
      if (this.selectedPlaybooks.length > 0) {
        // Here we assume that only one playbook can be selected. In case it stops being the case,
        // this would have to be refactored
        return this.selectedPlaybooks[0] === null ? LIBRARY_ITEM_TYPE_LABEL : NON_LIBRARY_ITEM_TYPE_LABEL
      }
      return null
    }
  },
  methods: {
    setUp () {
      this.selectedPlaybooks = this.value.call_items__talkscript__id__in || this.value.selectedPlaybooks || []
      this.selectedPlaybookItems = this.value.call_items__talkscript_item__id__in || this.value.selectedPlaybookItems || []
      this.selectedAnswers = this.value.call_items__answers__text__in || this.value.selectedAnswers || []
    },
    emitData () {
      const data = !this.criteriaConfigurator
        ? {
            call_items__talkscript__id__in: this.selectedPlaybooks,
            call_items__talkscript_item__id__in: this.selectedPlaybookItems,
            call_items__answers__text__in: this.selectedAnswers
          }
        : {
            selectedPlaybooks: this.selectedPlaybooks,
            selectedPlaybookItems: this.selectedPlaybookItems,
            selectedAnswers: this.selectedAnswers
          }
      this.$emit("input", data)
    },
    answerSelected (selectedAnswers) {
      this.selectedAnswers = selectedAnswers
      if (!selectedAnswers || selectedAnswers.length === 0) return
      this.emitData() // now the configuration is complete and we send it via event
    },
    playbookSelected (selectedPlaybooks) {
      this.selectedPlaybooks = selectedPlaybooks
    },
    playbookItemSelected (selectedPlaybookItems) {
      this.selectedPlaybookItems = selectedPlaybookItems
      if (this.noAnswerRequired) this.emitData() // the configuration required is now complete if it's just playbook and playbook item
    },
    validateFilter () {
      return this.validateChildrenFilters()
    },
    validateChildrenFilters () {
      let errorCount = 0
      for (const filterRef of this.filterRefs) {
        if (this.$refs[filterRef]) {
          const validatedFilter = this.$refs[filterRef].validateFilter()
          if (!validatedFilter) errorCount++
        }
      }
      return errorCount <= 0
    }
  }
}

</script>
