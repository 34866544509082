const state = {
  active: false,
  message: null,
  status: "success"
}

const getters = {
  getToastActiveState (state) {
    return state.active
  },
  getToastMessage (state) {
    return state.message
  },
  getToastType (state) {
    return state.status
  }
}

const mutations = {
  setToastActiveState (state, active) {
    state.active = active
  },
  setToastMessage (state, message) {
    state.message = message
  },
  setToastType (state, status) {
    state.status = status
  }
}

const actions = {
  setToastActiveState ({ commit }, value) {
    commit("setToastActiveState", value)
  },
  setToastMessage ({ commit }, message) {
    commit("setToastMessage", message)
  },
  setToastType ({ commit }, status) {
    commit("setToastType", status)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
