import axios from "axios"
import appInfo, { utils } from "@/apps/call"
import Vue from "vue"
import { registerAndGetStore } from "vuex-stores"
import { debounceService } from "@/services/debounceService"
import { networkController } from "@/services/networkController"
import { GROUP_TYPE } from "@/apps/talkscript/components/dynamicDataIndex"

const INCREMENT_LABEL = "increment"
const REDUCTION_LABEL = "reduction"

export const getInitialState = () => {
  return {
    call: null,
    playbook: null,
    mainContainer: null,
    callItems: {},
    playbookItems: [],
    counterparts: null,
    notes: null,
    callDuration: 0,
    callDurationCounter: null,
    error: null,
    loading: false,
    dismissCountDownForSave: 0,
    activeItem: null,
    itemActivatedTime: Date.now(),
    saving: false,
    showCallResultModal: false,
    objectionRaisedForItem: null,
    selectedObjection: null,
    activeShortcut: null,
    parentContainerOfSelectedObjection: null,
    displayObjectionModal: false,
    loadedCrmFields: {}, // list of crmfields that contain data. It is a "service-crm_object_type" key value pair
    unansweredRequiredItems: [],
    scroll: false, // used to check if scrolling must happen when objection modal closed,
    callViewComponent: null, // used to scroll through the component
    callViewItemsContainerId: "playbook-items-container" // this id is needed in the store for the scroll. So I'm passing it from here to the component so the scroll doesn't break if a renaming has to happen
  }
}
export const state = getInitialState()

export const getters = {
  getCallItem: (state) => (id) => {
    return state.callItems[id]
  },
  getActiveItem (state) {
    return state.activeItem
  },
  getCallId (state) {
    if (state.call && state.call.id) return state.call.id
    return null
  },
  getUserId (state, getters, rootState) {
    return rootState.auth.user && rootState.auth.user.pk ? rootState.auth.user.pk : null
  },
  getPlaybookId: state => {
    return state.playbook && state.playbook.id ? state.playbook.id : null
  },
  getCallData (state, getters) {
    return {
      notes: state.notes,
      counterparts: state.counterparts,
      duration: state.callDuration,
      talkscript: getters.getPlaybookId,
      user: getters.getUserId
    }
  },
  isActiveItem: (state) => (item) => {
    if (!state.activeItem) return false
    return state.activeItem === item
  },
  isCallActive: (state) => {
    return !!state.call && !state.call.duration
  },
  canShowCallResultModal (state, getters, rootState, rootGetters) {
    return rootGetters["auth/canUseCallResult"] && (!!state.call && !state.call.result)
  },
  isItemAnswered: (state) => (item) => {
    return !!item && !!item.callItem && (
      (!!utils.stripHtmlTags(item.callItem.note)) || (!!item.callItem.answers && item.callItem.answers.length > 0)
    )
  },
  getItem: (state) => (id, type, parentContainer) => {
    if (type === "objection") {
      return parentContainer.objections.find(objection => objection.id === id)
    }
    if (type === "add_playbook") {
      let adc = null
      for (const item of state.mainContainer.children) {
        adc = item.additional_talkscripts.find(adc => adc.id === id)
        if (adc) break
      }
      return adc
    }
    return parentContainer.children.find(item => item.id === id)
  },
  /** This is recursive function to find the unanswered-required items in playbookItems
   and also in the item's loadedData
   */
  findUnansweredRequiredItems: (state, getters) => ({ playbookItems, parent }) => {
    let unansweredRequiredItems = []
    playbookItems.forEach(item => {
      if (item.required && !getters.isItemAnswered(item)) {
        unansweredRequiredItems.push(item.uniqueId)
        item.open = true
        if (parent) parent.open = true
      } else {
        item.open = false
      }
      if (!!item.loadedData && !!item.loadedData.length > 0) {
        item.loadedData.forEach(loadedData => {
          const unansweredRequiredItemsFromLoadedData = getters.findUnansweredRequiredItems({
            playbookItems: loadedData.selected_choice.workflow.children,
            parent: loadedData
          })
          unansweredRequiredItems = unansweredRequiredItems.concat(unansweredRequiredItemsFromLoadedData)
        })
      }
    })
    return unansweredRequiredItems
  },
  getCrmLookupFieldsInPlaybook (state) {
    /**
     * Returns set of key value pairs of lookup field and target data type present in entire playbook including
     * objections where type of dynamic data is CRM data.
     * format: {crmObjectType-lookupField: lookup-targetObjectType}; e.g.: {Opportunity-parent_contact_id: lookup-contact}
     * @return {Object}
     */
    const crmLookupFieldsInPlaybook = {}
    if (state.playbook) {
      const rootContainer = state.playbook.main_container
      const playbookItems = rootContainer.children
      const objectionItems = rootContainer.objections.flatMap(objection => objection.workflow.children)
      const allItems = playbookItems.concat(objectionItems)
      for (const playbookItem of allItems) {
        const tempDiv = document.createElement("div")
        tempDiv.innerHTML = playbookItem.display_text
        const spanElements = tempDiv.querySelectorAll("span[data-id]")
        if (spanElements.length > 0) {
          for (const spanElement of spanElements) {
            const dataId = spanElement.getAttribute("data-id").slice(2, -2).split(",")
            // expected dataId format: [dataType, Label, fieldKey, lookup-targetType]
            // e.g.: [CRM Data, Opportunity-Contact, Opportunity-parent_contactid_value, lookup-contact]
            if (dataId.length > 3 && dataId[0] === GROUP_TYPE.CRM_DATA) {
              const lookupId = dataId[3].split("-")
              if (lookupId[0] === "lookup") {
                const fieldKey = dataId[2]
                if (!Object.keys(crmLookupFieldsInPlaybook).includes(fieldKey)) {
                  crmLookupFieldsInPlaybook[fieldKey] = lookupId[1]
                }
              }
            }
          }
        }
      }
    }
    return crmLookupFieldsInPlaybook
  }
}

export const mutations = {
  setCall (state, call) {
    state.call = call
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setSaving (state, saving) {
    state.saving = saving
  },
  setError (state, error) {
    state.error = error
  },
  clearCallDurationCounter (state) {
    clearInterval(state.callDurationCounter)
  },
  /** Maps the call data into the store - doing so also merges call items with the playbook items so data in the call
   * is displayed properly */
  setCallDetails (state, call) {
    state.call = call
    state.callItems = call.call_items
    state.playbook = call.talkscript
    state.counterparts = call.counterparts
    state.notes = call.notes
    state.callDuration = call.duration || 0
    if (state.playbook) {
      /** Finds and attaches call item data to the provided playbook data - this is required, since we do no longer
       * serialize it merged in the backend to enable reasonable caching of playbook data */
      // TODO: we could also move this outside of here, its just convenience for now
      const attachCallItem = (playbookItem, callItems) => {
        // no call items, we just return the playbookItem as it is
        if (!callItems) return playbookItem

        // 1. get the call items for this particular playbook based on the
        // talkscript_item field and the playbookItem.id
        callItems = callItems.filter(ci => ci.talkscript_item === playbookItem.id)

        // if no call data is available we return early
        if (callItems.length === 0) return playbookItem

        // could be a bug, could be unfortunate circumstances (race condition much?!)
        if (callItems.length > 1) console.error("Found more than 1 call item -> investigate!")

        // 2. call items should be sorted by recency anyway (descending) - so we take the first one
        playbookItem.call_item = callItems[0]
        if (
          playbookItem.call_item &&
            playbookItem.call_item.loaded_additional_data &&
            playbookItem.call_item.loaded_additional_data.length > 0
        ) {
          // 3. if the call item contains loaded_additional_data we also have to attach that to the playbook item
          // then do the same for all playbook items
          playbookItem.loadedData = playbookItem.call_item.loaded_additional_data.map(item => {
            const newItem = { ...item }
            newItem.open = true // the state of newly loaded objections/dyn. playbooks should be open
            newItem.selected_choice.workflow.children = item.selected_choice.workflow.children.map(
              // do the same for all playbook items in the loaded data
              addItem => attachCallItem(addItem, newItem.call_items)
            )
            return newItem
          })
        }
        return playbookItem
      }
      const playbookItemWithCallItems = call.talkscript.main_container.children.map(
        item => {
          if (call.call_items && call.call_items.length > 0) {
            return attachCallItem(item, call.call_items.filter(callItem => {
              return callItem.additional_data === null
            }))
          }
          return item
        }
      )
      state.mainContainer = state.playbook.main_container
      state.playbookItems = playbookItemWithCallItems
      state.activeItem = state.playbookItems[0] || null
    }
  },
  startCallTimer (state) {
    if (!!state.call && !state.call.duration) {
      state.callDurationCounter = setInterval(function () {
        state.callDuration = state.callDuration + 1000
      }, 1000)
    }
  },
  setActiveItem (state, item) {
    state.activeItem = item || state.playbookItems[0]
  },
  updateActiveItemDuration (state) {
    if (state.activeItem) {
      const activeInterval = Date.now() - state.itemActivatedTime
      const oldDuration = state.activeItem.duration || 0
      state.activeItem.duration = oldDuration + activeInterval
    }
  },
  resetItemActivatedTime (state) {
    state.itemActivatedTime = Date.now()
  },
  resetCallData (state) {
    const newState = { ...state, ...getInitialState() }
    Object.assign(state, newState)
  },
  updateCounterparts (state, index, counterpart) {
    state.counterparts.splice(index, 1, counterpart)
  },
  setCallResult (state, result) {
    state.call.result = result
  },
  setPlaybook (state, playbook) {
    state.playbook = playbook
  },
  setLastSave (state, newValue) {
    state.call.updated_at = newValue
    state.dismissCountDownForSave = 1
    setTimeout(() => {
      state.dismissCountDownForSave = 0
    }, 1000)
  },
  setShowCallResultModal (state, showCallResultModal) {
    state.showCallResultModal = showCallResultModal
  },
  setObjectionRaisedForItem (state, item) {
    state.objectionRaisedForItem = item || state.activeItem
  },
  setSelectedObjection (state, objection) {
    state.selectedObjection = objection
  },
  setActiveShortcut (state, shortcut) {
    state.activeShortcut = shortcut
  },
  setParentContainerOfSelectedObjection (state, parentContainer) {
    state.parentContainerOfSelectedObjection = parentContainer
  },
  setDisplayObjectionModal (state, value) {
    state.displayObjectionModal = value
  },
  addToLoadedCrmFields (state, { serviceTypeCombination, itemId }) {
    state.loadedCrmFields[serviceTypeCombination] = { count: 1, itemIds: {} }
    state.loadedCrmFields[serviceTypeCombination].itemIds[itemId] = true
  },
  updateLoadedCrmFields (state, { serviceTypeCombination, type, itemId }) {
    if (type === INCREMENT_LABEL) {
      if (!state.loadedCrmFields[serviceTypeCombination].itemIds[itemId]) {
        state.loadedCrmFields[serviceTypeCombination].count++
        state.loadedCrmFields[serviceTypeCombination].itemIds[itemId] = true
      }
    } else {
      state.loadedCrmFields[serviceTypeCombination].count--
      state.loadedCrmFields[serviceTypeCombination].itemIds[itemId] = false
    }
  },
  updateUnansweredRequiredItems (state, value) {
    state.unansweredRequiredItems = value
  },
  resetUnansweredRequiredItems (state) {
    state.unansweredRequiredItems = []
  },
  setScroll (state, value) {
    state.scroll = value
  },
  setCallViewComponent (state, component) {
    state.callViewComponent = component
  }

}

export const actions = {
  updateLastSaved ({ commit }) {
    commit("setLastSave", new Date().toLocaleString("de-DE"))
  },
  handleCrmFieldLoaded ({ commit, state }, { value, crmObject, itemId }) {
    // This function checks if the combination of object_type and service of a crm field was already added to
    // loadedCrmFields. If not available then adds the object_type and service(combination) as a new entry or it removes
    // it from the crmField is data is cleared.
    // It is used to check if crm field corresponding to counterpart type(that is being loaded) has data already
    const crmService = crmObject.service.key
    const crmObjectType = crmObject.object_type
    const serviceTypeCombination = `${crmService}-${crmObjectType}`
    if (!state.loadedCrmFields[serviceTypeCombination]) {
      commit("addToLoadedCrmFields", { serviceTypeCombination, itemId })
    } else {
      if (!value || value.length === 0) {
        commit("updateLoadedCrmFields", {
          serviceTypeCombination,
          type: REDUCTION_LABEL,
          itemId
        })
      } else commit("updateLoadedCrmFields", { serviceTypeCombination, type: INCREMENT_LABEL, itemId })
    }
  },
  setCounterparts ({ state }, counterparts) {
    if (!Array.isArray(counterparts)) {
      state.error = "Counterparts must be an array"
      return
    }
    state.counterparts = counterparts
  },
  setNotes ({ state }, notes) {
    state.notes = notes
  },
  createCall ({ dispatch, commit }, callData) {
    // TODO: can be moved into utils function?
    // I dont think the axios call here is required, the only thing we need is a convenient way to set the loading flags
    return new Promise((resolve, reject) => {
      commit("setLoading", true)
      axios.post(appInfo.apiUrl, callData).then(response => {
        dispatch("handleError", null)
        commit("setLoading", false)
        resolve(response.data)
      }).catch(error => {
        dispatch("handleError", error.response)
        commit("setLoading", false)
        reject(error)
      })
    })
  },
  loadCall ({ dispatch, commit }, callId) {
    commit("setLoading", true)
    return axios.get(appInfo.apiUrl + "/" + callId).then(response => {
      dispatch("handleError", null)
      commit("setCallDetails", response.data)
      commit("startCallTimer")
    }).catch(error => {
      dispatch("handleError", error.response)
    }).finally(() => {
      commit("setLoading", false)
    })
  },
  updateCall ({ dispatch, getters }) {
    return axios.put(appInfo.apiUrl + "/" + getters.getCallId, getters.getCallData).catch(error => {
      dispatch("handleError", error.response)
    })
  },
  activateItem ({ getters, commit }, item) {
    if (getters.isCallActive) {
      commit("updateActiveItemDuration")
      commit("resetItemActivatedTime")
    }
    commit("setActiveItem", item)
  },
  saveCallDetails ({ commit, dispatch }) {
    /**
     * This method updates the time duration for last active item, clears the call duration counter and updates the call
     */
    return new Promise((resolve, reject) => {
      commit("updateActiveItemDuration")
      commit("clearCallDurationCounter")
      dispatch("updateCall").then(() => {
        dispatch("setEndConversation", true)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  endCall ({ commit, dispatch }, checkRequiredItems = true) {
    /**
     * This method waits for all items to be saved(delayed queue and waiting queue is emptied) and then checks if all
     * required items are answered only if checkRequiredItems flag is true. If checkRequiredItems is false, it saves
     * the call immediately without performing the check for the required items.
     * Note: requiredItem check must happen once all the items' saving operations are completed to ensure that the notes
     * and answers are updated to backend correctly before ending the call
     */
    return new Promise((resolve, reject) => {
      commit("setSaving", true)
      debounceService.methods.executeAllDelayedOperations().then(() => {
        return networkController.methods.checkAllRequestsCompleted().then(() => {
          const promise = checkRequiredItems ? dispatch("canEndCall") : Promise.resolve()
          return promise.then(() => {
            // update and end the call and stop audio recording if needed
            return dispatch("saveCallDetails").then(() => {
              commit("setSaving", false)
              // Importing this function from the GlobalMixin didn't work; that's why I repeated the
              // code to send message to the iFrame here
              dispatch("sendMessageToBaoSwift", { type: "callEnded" })
              resolve()
            })
          })
        })
      }).catch((error) => {
        commit("setSaving", false)
        dispatch("handleError", error.response)
        reject(error)
      })
    })
  },
  sendMessageToBaoSwift ({ commit }, message) {
    // This is to check if Application is in iFrame
    if (window.self !== window.top) {
      if (window.parent && window.parent.postMessage) {
        window.parent.postMessage(message, "*")
      }
    }
  },
  canEndCall ({ commit, state, getters }) {
    /**
     * This method checks if call can be ended based on required items being answered.
     * Returns a resolved promise if all required items are answered
     * Rejects the promise if any required items are unanswered
     */
    return new Promise((resolve, reject) => {
      commit("resetUnansweredRequiredItems")
      const unansweredRequiredItems = getters.findUnansweredRequiredItems({
        playbookItems: state.playbookItems,
        parent: null
      })
      setTimeout(() => {
        // Updating unanswered required items has a watcher on the component which scrolls to the alert. "findUnansweredRequiredItems" closes all non-required
        // items, so I need to ensure all the non-required items are closed before updating the unanswered required items, if not, the targetItem clientHeight might be
        // shorter than initially calculated for the scroll because the items above it might close while the scroll is ongoing. To solve this, hence the timeout, and the unanswered
        // required items are updated at once, instead of being pushed one by one
        commit("updateUnansweredRequiredItems", unansweredRequiredItems)
        if (unansweredRequiredItems.length === 0) {
          resolve()
        } else {
          reject(new Error("Required items not answered"))
        }
      }, 500)
    })
  },
  handleCancelCallResult ({ dispatch, commit }) {
    dispatch("setEndConversation", false)
    commit("setShowCallResultModal", false)
  },
  handleSavedCallResult ({ commit }, result) {
    commit("setShowCallResultModal", false)
    commit("setCallResult", result)
  },
  handleResetUnansweredRequiredItems ({ commit }) {
    commit("resetUnansweredRequiredItems")
  },
  handleItemChanged: ({ dispatch }, item) => {
    /***
     * This method debounces "saveCallItem" method to avoid multiple requests
     * to backend
     */
    const callback = () => dispatch("saveCallItem", item)
    debounceService.methods.debounceOperation(item.uniqueId, callback)
  },
  saveCallItem ({ dispatch, getters }, item) {
    /***
     * saves(creates/updates) the callItem for the given item and updates
     * the item accordingly
     */
    return new Promise((resolve, reject) => {
      // extract the callItem data from the item
      const callItemData = utils.getCallItemDataToSave(item, getters.getCallId)
      // prepare the request-callback for saving the callItem
      const request = utils.getCallItemSaveRequest(item, callItemData)
      // send the request via networkController(to avoid race condition)
      item.callItemPromise = networkController.methods.sendRequest(item.uniqueId, request)
      item.callItemPromise.then(() => {
        if (getters.isItemAnswered(item)) {
          Vue.set(item, "edited", true)
          dispatch("updateLastSaved")
        }
        resolve(item)
      }).catch(error => {
        dispatch("handleError", error.response)
        reject(error)
      })
    })
  },

  setEndConversation ({ dispatch }, value) {
    const setEndConversation = require("@/../src/apps/call/utils").setEndConversation
    return setEndConversation({ dispatch }, value)
  },
  createAdditionalData ({ dispatch, commit }, data) {
    return axios.post("/api/objections", data).catch(error => {
      dispatch("handleError", error.response)
    })
  },
  deleteAdditionalData ({ dispatch }, additionalDataId) {
    return axios.delete("/api/objections/" + additionalDataId).catch(error => {
      dispatch("handleError", error.response)
    })
  },
  objectionModalClosed ({ dispatch, commit, state }, item) {
    return new Promise((resolve, reject) => {
      if (
        state.objectionRaisedForItem &&
        state.objectionRaisedForItem.loadedData &&
        state.objectionRaisedForItem.loadedData.length > 0
      ) {
        // collapse the item with the raised objection
        // this item is the last one in the list of loadedData of state.objectionRaisedForItem
        const objectionList = state.objectionRaisedForItem.loadedData
        objectionList[objectionList.length - 1].open = false
      }

      dispatch("activateItem", item || state.objectionRaisedForItem).then(() => {
        commit("setDisplayObjectionModal", false)
        commit("setActiveShortcut", null)
        commit("setObjectionRaisedForItem", null)
        if (!state.scroll) {
          resolve()
          return
        }
        return dispatch("scrollTo", "#" + state.activeItem.uniqueId).then(() => {
          commit("setScroll", false)
          resolve()
        })
      }).catch(error => {
        dispatch("handleError", error)
        reject(error)
      })
    })
  },
  additionalDataSelected ({ dispatch, getters }, { payload, isObjection }) {
    if (isObjection) return dispatch("objectionWasSelected", payload)
    return dispatch("additionalPlaybookSelected", payload)
  },
  objectionWasSelected ({ dispatch, commit }, { objection, item }) {
    return new Promise((resolve, reject) => {
      objection = JSON.parse(JSON.stringify(objection))
      objection.selected_choice = objection
      commit("setObjectionRaisedForItem", item)
      commit("setSelectedObjection", objection)
      commit("setActiveShortcut", objection)
      dispatch("getParentContainerOfObjection", { id: objection.id })
      if (objection.workflow && objection.workflow.children) {
        dispatch("activateItem", objection.workflow.children[0])
      }
      commit("setDisplayObjectionModal", true)
      const data = { additionalDataChoice: objection, item, isObjection: true }
      dispatch("saveAdditionalData", data).then(() => {
        resolve()
      }).catch(error => {
        dispatch("handleError", error)
        reject(error)
      })
    })
  },
  additionalPlaybookSelected ({ dispatch, getters }, { additionalDataChoice, item }) {
    return new Promise((resolve, reject) => {
      dispatch("additionalPlaybookIsLoaded", { additionalDataChoice, item }).then(adcLoaded => {
        if (adcLoaded) {
          // TODO: add comment why we are resolving + returning in this case
          resolve()
          return
        }
        dispatch("saveAdditionalData", { additionalDataChoice, item, isObjection: false })
          .then(resolve)
          .catch(reject)
      })
    })
  },
  saveAdditionalData (
    { dispatch, commit, getters },
    { additionalDataChoice, item, isObjection = false }) {
    return new Promise((resolve, reject) => {
      if (!item.loadedData) Vue.set(item, "loadedData", [])
      const adcIndex = item.loadedData.length
      if (!isObjection) {
        // this should only be done for dyn. playbook but not for objection - not 100% why
        // TODO: once dyn. playbook type is removed from the item, we don't need to retrieve the data from the backend for the workflow
        // This is done so the user is shown a loading icon till the actual workflow is retrieved from the backend
        const { id, name, type } = additionalDataChoice
        Vue.set(item.loadedData, adcIndex, { open: true, selected_choice: { id, name, type } })
      }

      // The adcIndex is used to update item (at that index) with the newly created playbook
      // if no items are available we take the first one
      // this should almost never happen
      if (!item) item = state.playbookItems[0]

      if (!item.callItemPromise) {
        // if call-item was not created previously, then create it - and wait for it to be created
        dispatch("saveCallItem", item)
      }
      item.callItemPromise.then(() => {
        // save additional data choice and retrieve data at the same time
        const playbookData = {
          call: getters.getCallId,
          talkscript_item: item.id,
          selected_choice: additionalDataChoice.id,
          call_item: item.callItem.id
        }
        dispatch("createAdditionalData", playbookData).then(response => {
          const newADC = response.data
          if (additionalDataChoice.selected_choice) {
            newADC.selected_choice = additionalDataChoice.selected_choice
          }
          newADC.open = true
          Vue.set(item.loadedData, adcIndex, newADC)
          if (isObjection) {
            commit("setSelectedObjection", newADC)
          } else {
            Vue.set(additionalDataChoice, "loadedData", newADC)
          }
          resolve()
        }).catch(err => reject(err))
      }).catch(err => reject(err))
    })
  },
  additionalPlaybookIsLoaded ({ dispatch }, { additionalDataChoice, item }) {
    // TODO: refactor
    return new Promise((resolve, reject) => {
      const { loadedData, index } = utils.getLoadedData(additionalDataChoice, item)
      if (!loadedData) {
        resolve(false)
        return
      }
      if (!additionalDataChoice.loadedData) additionalDataChoice.loadedData = loadedData
      additionalDataChoice.loadedData.open = true

      const selector = `#${item.uniqueId}-loadedData-${index}`
      dispatch("scrollTo", selector).then(() => {
        resolve(true)
      }).catch(error => {
        reject(error)
      })
    })
  },
  async getParentContainerOfObjection ({ dispatch, commit, getters, state }, { id }) {
    // Treating the main container as a tree, I use breadth first search to get the parent container of the objection
    // or the objection itself, depending on what is required
    commit("setParentContainerOfSelectedObjection", state.mainContainer)
    const queuedParentContainers = [state.mainContainer]
    for (let i = 0; i < queuedParentContainers.length; i++) {
      const currentContainer = queuedParentContainers[i]
      const playbookObjections = currentContainer.objections || []
      for await (const objection of playbookObjections) {
        if (objection.id === id) {
          // This might never be a case if the objection was added to a child item and not the parent
          // Hence the other check on the children below
          commit("setParentContainerOfSelectedObjection", currentContainer)
          return currentContainer
        }
      }
      // After checking the mainContainer to get the parent of the objection, if it is not found
      // we first check the children's objections and return the parent container if the objection is found, else
      // we check the children for additional data and add the additional data's mainContainer to the queue
      const playbookChildren = currentContainer.children
      for await (const child of playbookChildren) {
        const currentChildObjections = child.objections
        for await (const objection of currentChildObjections) {
          if (objection.id === id) {
            commit("setParentContainerOfSelectedObjection", currentContainer)
            return currentContainer
          }
        }
        const loadedData = child.loadedData
        if (loadedData && loadedData.length > 0) {
          for await (const adc of loadedData) {
            const childContainer = adc.selected_choice.workflow
            queuedParentContainers.push(childContainer)
          }
        }
      }
    }
    return null
  },
  async handleAnswerActions ({ dispatch, commit, getters, state }, {
    answerActions,
    vueComponent,
    answerPBItem,
    parentContainer,
    additionalData
  }) {
    for (const answerAction of answerActions) {
      if (answerAction.type === "end_conversation") {
        dispatch("endCall")
        continue
      }
      const targetItem = getters.getItem(answerAction.data.id, answerAction.type, parentContainer)

      if (answerAction.type === "custom") {
        dispatch("handleCustomAnswerAction", { answerAction, vueComponent, answerPBItem, targetItem })
        continue
      }

      if (!answerAction.data.id) continue

      if (!targetItem && answerAction.type !== "add_playbook") continue

      if (answerAction.type === "expand" || answerAction.type === "collapse") {
        dispatch("handleExpandAndCollapseAnswerAction", { targetItem, type: answerAction.type })
      } else if (answerAction.type === "jump") {
        dispatch("handleJumpAnswerAction", { answerActions, targetItem })
      } else if (answerAction.type === "set_answer") {
        dispatch("handleSetAnswerAction", { answerAction, targetItem })
      } else if (answerAction.type === "objection" || answerAction.type === "add_playbook") {
        let activePlaybookItem
        if (answerPBItem.is_objection) {
          activePlaybookItem = await dispatch("getActivePlaybookItemForObjection", { parentContainer, additionalData })
        } else activePlaybookItem = state.activeItem

        if (answerAction.type === "objection") {
          dispatch("handleObjectionAnswerAction", { targetItem, activePlaybookItem })
        } else if (answerAction.type === "add_playbook") {
          // for scrolling
          const adcIndex = (activePlaybookItem && activePlaybookItem.loadedData) ? activePlaybookItem.loadedData.length : 0
          let selector = activePlaybookItem ? `#${activePlaybookItem.uniqueId}-loadedData-${adcIndex}` : ""
          // if the target item is chosen
          if (answerAction.data.targetId) {
            activePlaybookItem = state.mainContainer.children.find(item => item.id === answerAction.data.targetId)
            selector = undefined
          }

          dispatch("handleAddPlaybookAnswerAction", {
            targetItem,
            activePlaybookItem,
            objectionID: answerAction.data.id
          }).then(() => {
            // This timeout was added because the scroll wasn't working properly; as if it was not waiting for
            // Vue.set(additionalDataChoice, "loadedData", newADC) to finish before scrolling
            // NextTick didn't resolve it too, hence the timeout
            // if the target item for playbook insertion is specified don't perform jump
            if (selector) {
              setTimeout(() => dispatch("scrollTo", selector), 200)
            }
          })
        }
      }
    }
  },
  async getActivePlaybookItemForObjection ({ commit, state }, { parentContainer, additionalData }) {
    for await (const playbookItem of parentContainer.children) {
      if (playbookItem.loadedData) {
        for await (const loadedData of playbookItem.loadedData) {
          if (loadedData.id === additionalData.id) {
            return playbookItem
          }
        }
      }
    }
  },
  handleCustomAnswerAction ({ dispatch, commit, getters, state }, { answerAction, vueComponent, answerPBItem, targetItem }) {
    try {
      // Note: we explicitly WANT to execute a string-based function as this is coming from the backend in order to
      // execute custom code in an answer action
      const body = "function( {vueComponent, storeFuncs, answerPBItem, targetPBItem} ){ " + answerAction.data.code + " }"
      const wrap = s => "{ return " + body + " };"
      // eslint-disable-next-line no-new-func
      const func = new Function(wrap(body))

      let targetPBItem = null
      if (answerAction.data.id) {
        targetPBItem = targetItem
      }

      // We suppress lint warning here because we think we know what we are doing (note: we probably dont...)
      // eslint-disable-next-line no-useless-call
      func.call(null).call(null,
        { vueComponent, storeFuncs: { dispatch, commit, getters, state }, answerPBItem, targetPBItem }
      ) // looks weird but works, the function is called with the above specified arguments
    } catch (error) {
      console.error(error)
    }
  },
  handleExpandAndCollapseAnswerAction ({ commit, state }, { targetItem, type }) {
    if (type === "expand") {
      targetItem.open = true
    } else targetItem.open = false
  },
  handleSetAnswerAction ({ dispatch, commit, state }, { answerAction, targetItem }) {
    const answersChoicesToSelect = answerAction.data.targetId
    const getTransformedAnswer = (answerChoice, targetItem) => {
      return {
        id: answerChoice.id,
        question_id: answerChoice.id,
        text: answerChoice.label,
        // set has_importance flag to true if item type is rated_multiselect otherwise set to false.
        has_importance: targetItem.item_type === "rated_multiselect",
        importance: 5,
        actions: answerChoice.actions, // note: nested actions will have no effects here because answer actions are handled just once.
        selected: true,
        tags: answerChoice.tags
      }
    }

    let transformedAnswers = []
    // check whether the answer type is single select or multiselect and transform accordingly.
    if (Array.isArray(answersChoicesToSelect)) {
      transformedAnswers = answersChoicesToSelect.map(answerChoice => {
        return getTransformedAnswer(answerChoice, targetItem)
      })
    } else {
      transformedAnswers.push(getTransformedAnswer(answersChoicesToSelect, targetItem))
    }

    Vue.set(targetItem, "selectedAnswers", transformedAnswers)
    dispatch("handleItemChanged", targetItem)
  },
  handleJumpAnswerAction ({ dispatch, commit, state }, { answerActions, targetItem }) {
    // If objection was raised before the jump then, on close of the objection modal, the item stored in the
    // ObjectionRaisedForItem-variable is made active item and it is scrolled to that item. So, reset the
    // ObjectionRaisedForItem to the target item of the jump, so that on close of the objection Modal the
    // item being jumped to is the target item and not the item where objection was raised.
    commit("setObjectionRaisedForItem", targetItem)
    // TODO: The below code is required to re-jump if objection modal was opened as modal on-close event
    //  re-jumps to old item. Figure-out the reason the closing of modal triggers its own scroll-event
    if (utils.findActionType("objection", answerActions)) {
      commit("setScroll", true)
    }

    const selector = `#${targetItem.uniqueId}`
    const targetElement = state.callViewComponent.$el.querySelector(selector)
    const scrollElement = document.getElementById(state.callViewItemsContainerId)

    dispatch("handleAnimationForScroll", { targetElement })
    dispatch("handleScrollForJumpAnswerAction", { targetElement, scrollElement })

    // This timeout is giving a rough estimate as to when the scroll will be finished, where the idea is to activate the item
    // immediately after the scroll. This is not entirely smooth but all methods I tried to get the exact time the scroll stopped
    // proved abortive 😔
    setTimeout(() => {
      dispatch("activateItem", targetItem)
    }, 800)
  },
  handleAnimationForScroll ({ dispatch, commit, state }, { targetElement }) {
    const css = ".sample-class {padding: 20px;transition: all 0.4s; transition-delay: 0.7s; transition-timing-function: cubic-bezier(.45, 10.01, .65, -10.68);} .sample-class__active {transform: scaleX(1.012); padding: 0}"
    const head = document.head || document.getElementsByTagName("head")[0]
    const style = document.createElement("style")

    head.appendChild(style)

    style.type = "text/css"
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css
    } else {
      style.appendChild(document.createTextNode(css))
    }

    // animation - focus management
    const animElement = targetElement.parentElement
    animElement.classList.add("sample-class")
    animElement.classList.toggle("sample-class__active")
    setTimeout(() => {
      animElement.classList.toggle("sample-class__active")
      animElement.classList.toggle("sample-class")
    }, 1200)
  },
  handleScrollForJumpAnswerAction ({ commit, state }, { targetElement, scrollElement }) {
    return new Promise((resolve, reject) => {
      try {
        setTimeout(() => {
          if (targetElement.clientHeight > scrollElement.clientHeight) {
            // console.log("element is larger than screen -> scroll top of element to top of screen")
            targetElement.scrollIntoView({ block: "start", behavior: "smooth" })
          } else if (targetElement.clientHeight > (scrollElement.clientHeight - mouseY)) {
            // console.log("element fits on screen, but not between mouse position and end of screen -> scroll end of element to end of screen")
            targetElement.scrollIntoView({ block: "end", behavior: "smooth" })
          } else {
            // console.log("in this case the item title should be scrolled onto the mouse cursor")
            // ParentElement is used because that's the component offsetting the scrollable element
            const targetTopPosition = targetElement.parentElement.offsetTop

            // Because we want to scroll to the mouse cursor, and not the top of the element, we have to
            // subtract the mouse offset to the top of the scrollable element i.e. (mouse offset to top of
            // screen - scrollable element offset to top of screen
            const headerOffset = scrollElement.offsetTop
            const topPosition = targetTopPosition - (mouseY - headerOffset)

            scrollElement.scrollTo({ top: topPosition, behavior: "smooth" })
          }
        }, 50)
        resolve()
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  },
  async handleObjectionAnswerAction ({ dispatch, commit, state }, { targetItem, activePlaybookItem }) {
    const data = { objection: targetItem, item: activePlaybookItem }
    await dispatch("objectionWasSelected", data)
  },
  async handleAddPlaybookAnswerAction (
    { dispatch, commit, state },
    { targetItem, activePlaybookItem, objectionID }) {
    let data
    if (!targetItem) {
      const newTargetItem = await dispatch("getObjectionChoice", objectionID)
      data = { additionalDataChoice: newTargetItem, item: activePlaybookItem }
    } else {
      data = { additionalDataChoice: targetItem, item: activePlaybookItem }
    }
    if (data) await dispatch("additionalPlaybookSelected", data)
  },
  async getObjectionChoice ({ commit, state }, ID) {
    const { data } = await axios.get(`/api/objectionchoices/${ID}`)

    return data
  },
  handleError ({ commit }, error) {
    commit("setError", error) // Todo: set the global error store
  },
  storeCallViewComponent ({ commit }, component) {
    commit("setCallViewComponent", component)
  },
  scrollTo ({ dispatch, commit, state }, selector) {
    return new Promise((resolve, reject) => {
      Vue.nextTick(() => {
        try {
          const targetElement = state.callViewComponent.$el.querySelector(selector)
          const scrollElement = document.getElementById(state.callViewItemsContainerId)
          dispatch("handleAnimationForScroll", { targetElement })

          if (targetElement.clientHeight < scrollElement.clientHeight && targetElement.clientHeight > (scrollElement.clientHeight - mouseY)) {
            targetElement.scrollIntoView({ block: "end", behavior: "smooth" })
          } else {
            targetElement.scrollIntoView({ block: "start", behavior: "smooth" })
          }
          resolve()
        } catch (e) {
          console.error(e)
          reject(e)
        }
      })
    })
  }
}

function getNamespace (callId) {
  return "conversation-" + callId
}

export const getCallStore = (namespace, override = {}) => {
  const usedGetters = { ...getters, ...(override.getGetters ? override.getGetters() : {}) }
  const usedState = { ...getInitialState(), ...(override.getState ? override.getState() : {}) }
  const usedActions = { ...actions, ...(override.getActions ? override.getActions() : {}) }
  const usedMutations = { ...mutations, ...(override.getMutations ? override.getMutations() : {}) }

  // console.log(usedActions)

  return {
    namespace,
    state: usedState,
    getters: usedGetters,
    mutations: usedMutations,
    actions: usedActions
  }
}

// Note: default exports are NOT refreshed when the value changes, thus unsuited for dynamic computation
// export default currentCallStore
export let currentCallStore
export let mouseY
document.addEventListener("mousemove", function (event) {
  mouseY = event.clientY
})

export function attachCallStore (vuexStore, callStore) {
  return registerAndGetStore(vuexStore, callStore)
}

function removeCallStore (vuexStore, callStore) {
  if (vuexStore.hasModule(callStore.moduleNamespace)) {
    vuexStore.unregisterModule(callStore.moduleNamespace)
  }
  callStore = null
}

const previousCallStores = []

// MAX_CALL_STORES contains the maximum number of call stores that are allowed to be created to avoid memory leak
const MAX_CALL_STORES = 3

export function setupNewCallStore (callId, vuexStore) {
  if (currentCallStore && currentCallStore.testing) {
    // we are testing -> nothing to be done, store should already be set up by the testing code
    return currentCallStore
  }

  const namespace = getNamespace(callId)
  try {
    const previousCallStore = previousCallStores.find(item => item.moduleNamespace === namespace)
    if (previousCallStore) {
      // in this case a module with the same ID has already been created -> we should set it as the currentCallStore and
      // reload the data from the backend
      currentCallStore = previousCallStore
      return currentCallStore
    }
  } catch (e) {
    console.log(e)
  }

  // First create a new store using the provided call id
  const newCallStore = getCallStore(namespace)
  // Next attach the call store to our Vuex store
  const result = setCallStore(vuexStore, newCallStore)
  // If number of call stores exceeds MAX_CALL_STORES, then remove/unregister the oldest call store
  if (previousCallStores.length === MAX_CALL_STORES) {
    const oldestCallStore = previousCallStores.shift()
    removeCallStore(vuexStore, oldestCallStore)
  }
  previousCallStores.push(result)

  // Debug logs:
  // console.log("Setup")
  // console.log(result)
  // console.log(currentCallStore)

  return result
}

export function setCallStore (vuexStore, callStore) {
  currentCallStore = attachCallStore(vuexStore, callStore)
  return currentCallStore
}
