import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetReachabilityDisplay from "./BaoWidgetReachabilityMatrixDisplay"
import { getLanguage } from "@/apps/dashboard"

const HeatMapChart = () => import(/* webpackChunkName: "HeatMapChart" */ "../../BaoWidgetsBaseComponents/HeatMapChart")

export default {
  name: "BaoWidgetReachabilityMatrix",
  info: {
    title: "Reachability Matrix",
    description: "Shows on which day of the week and at what time the counterparts are best reachable."
  },
  extends: BaoWidgetBasicMixin,
  beforeDestroy () {
    clearInterval(this.dateTimeInterval)
  },
  mounted () {
    this.dateTimeInterval = setInterval(() => { this.currentDate = new Date() }, 180000)
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getLanguage,
      chartComponent: HeatMapChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetReachabilityDisplay,
      dateTimeInterval: null,
      currentDate: new Date(),
      overallReachability: "0",
      staticTextDefault: {
        mon: "Mon",
        tue: "Tue",
        wed: "Wed",
        thu: "Thu",
        fri: "Fri",
        hour: "Hour"
      }
    }
  },
  computed: {
    WeekNames () {
      return {
        2: this.staticText.mon,
        3: this.staticText.tue,
        4: this.staticText.wed,
        5: this.staticText.thu,
        6: this.staticText.fri
      }
    },
    getChartProps () {
      return {
        chartData: this.chartData,
        previewMode: this.previewMode,
        currentDate: this.currentDate
      }
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    mappingFunc (data) {
      const { result, successRatios, overallCalls, overallSuccessCalls } = this.preprocessData(data)
      this.maxSuccesRatio = Math.max(...successRatios)
      this.overallReachability = overallCalls > 0 ? ((overallSuccessCalls / overallCalls) * 100).toFixed(0) : "0"
      return {
        id: this.widget.id,
        data: result,
        headerLabels: Object.values(this.WeekNames),
        maxValue: this.maxSuccesRatio
      }
    },
    preprocessData (data) {
      const result = {}
      const successRatios = []
      let overallCalls = 0
      let overallSuccessCalls = 0
      for (let hour = 7; hour < 21; hour++) {
        const hourLabel = this.getHourLabel(hour)
        result[hourLabel] = {}
        for (const week of Object.keys(this.WeekNames)) {
          const weekNumber = parseInt(week)
          let entry = data.find(item =>
            parseInt(item.created_at__hour) === hour && parseInt(item.created_at__week_day) === weekNumber
          )
          if (!entry) {
            entry = {
              total_calls: 0,
              success_calls: 0,
              created_at__hour: hour,
              created_at__week_day: weekNumber
            }
          }
          const successData = this.getSuccessData(entry)
          result[hourLabel][this.WeekNames[week]] = { ...entry, ...successData }
          successRatios.push(successData.successRatio)
          overallCalls = overallCalls + entry.total_calls
          overallSuccessCalls = overallSuccessCalls + entry.success_calls
        }
      }
      return { result, successRatios, overallCalls, overallSuccessCalls }
    },
    getSuccessData (item) {
      const data = { successRatio: 0, successPercentage: 0 }
      if (item.total_calls === 0) return data
      data.successRatio = (item.success_calls / item.total_calls).toPrecision(2)
      data.successPercentage = (data.successRatio * 100).toFixed(0)
      return data
    },
    getChartOptions () {
      return null
    },
    getHourLabel (hour) {
      let meridiem = "Uhr"
      let localHour = hour
      if (this.getLanguage() !== "de") {
        meridiem = hour < 12 || hour === 24 ? "AM" : "PM"
        localHour = hour % 12 || 12
      }
      return localHour + " " + meridiem
    },
    getConfigOptions () {
      return [{
        type: "criteriaOptions",
        value: { type: "counterpartReachability" },
        options: ["counterpartReachability", "callDuration", "answer"],
        fieldName: "successCriteria"
      }]
    },
    getDisplayProps () {
      return { widget: this.widget, overallReachability: this.overallReachability }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
