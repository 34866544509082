import BaoWidgetBasicMixin from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetBasicMixin"
import {
  getDefaultHorizontalBarChartOptions, getUsername
} from "@/apps/dashboard/chartUtils"
import { defaultHorizontalDatasetValues } from "@/apps/dashboard/chartConfig"
import BaoWidgetConfigurator from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetConfigurator"
import BaoWidgetDisplay from "@/apps/dashboard/BaoWidgetsBaseComponents/BaoWidgetDisplay"

const HorizontalBarChart = () => import(/* webpackChunkName: "HorizontalBarChart" */ "../BaoWidgetsBaseComponents/HorizontalBarChart")

export default {
  name: "BaoWidgetTeamCallActivity",
  info: {
    title: "Team Call Activity",
    description: "Displays the number of calls from my team on a user-level."
  },
  extends: BaoWidgetBasicMixin,
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  data () {
    return {
      getDefaultHorizontalBarChartOptions,
      getUsername,
      chartComponent: HorizontalBarChart,
      configuratorComponent: BaoWidgetConfigurator,
      displayComponent: BaoWidgetDisplay,
      staticTextDefault: {
        maximumUsersDisplayed: "Maximum users displayed",
        others: "Others"
      },
      totalCalls: 0
    }
  },
  computed: {
    showGoToConversationsButton () {
      return this.totalCalls > 0
    }
  },
  methods: {
    async mappingFunc (items) {
      const usersLimit = this.widget.config.usersLimit || 6
      const sortedItems = items.sort((a, b) => b.countdistinct_id - a.countdistinct_id)
      this.totalCalls = this.getTotalCount(sortedItems, "countdistinct_id")
      const usersWithinLimit = sortedItems.splice(0, usersLimit)
      const labels = []
      const data = []
      const callIds = []
      for (const item of usersWithinLimit) {
        labels.push(this.getUsername(item, "created_by"))
        data.push(item.countdistinct_id)
        callIds.push(item.arrayagg_id)
      }
      const userOutsideLimit = sortedItems.slice(Math.max(sortedItems.length - usersLimit, 0))
      let userOutsideLimitCount = 0
      let userOutsideLimitCallIds = []
      for await (const item of userOutsideLimit) {
        userOutsideLimitCount += item.countdistinct_id
        userOutsideLimitCallIds = userOutsideLimitCallIds.concat(item.arrayagg_id)
      }

      if (userOutsideLimitCount > 0) {
        labels.push(this.staticText.others)
        data.push(userOutsideLimitCount)
        callIds.push(userOutsideLimitCallIds)
      }

      return {
        labels,
        datasets: [{ ...defaultHorizontalDatasetValues, data }],
        callIds
      }
    },
    getChartOptions () {
      const dataLabelClickCallBack = (dataIndex) => {
        this.showConversations(dataIndex)
      }

      return this.getDefaultHorizontalBarChartOptions(this.widget, this, { dataLabelClickCallBack })
    },
    getConfigOptions () {
      return [
        {
          label: this.staticText.maximumUsersDisplayed,
          type: "number",
          value: 6, // default
          fieldName: "usersLimit"
        }
      ]
    },
    getDisplayProps () {
      return { widget: this.widget, totalItems: this.totalCalls, showConversationsView: this.showGoToConversationsButton }
    },
    getConfigProps () {
      return { widget: this.widget, configOptions: this.getConfigOptions() }
    }
  }
}
