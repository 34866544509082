import axios from "axios"
import { getDefaultWidgets } from "@/apps/dashboard/chartConfig"
import { sortByAlphaNumeric } from "@/apps/dashboard/chartUtils"
import { dashboardStoreUtils, getTranslation } from "@/apps/dashboard"

export const state = {
  loadedAnalyticsPages: [],
  currentPage: {},
  widgets: [],
  showWidgetSidebar: false,
  widgetToConfigure: null,
  defaultWidgets: {},
  favoritePage: null,
  loadingAnalyticsPages: false,
  showMenu: false,
  loadedDashboardsOnce: false,
  previewChartData: null
}

export const getters = {
  getFavoritePage (state) {
    return state.favoritePage
  },
  myDashboards (state, getters) {
    return sortByAlphaNumeric(state.loadedAnalyticsPages.filter(item => getters.isUserCreator(item) && !item.is_among_favorites))
  },
  sharedDashboards (state, getters) {
    return sortByAlphaNumeric(state.loadedAnalyticsPages.filter(item => !getters.isUserCreator(item) && !item.is_among_favorites))
  },
  favoriteDashboards (state) {
    return sortByAlphaNumeric(state.loadedAnalyticsPages.filter(item => item.is_among_favorites))
  },
  getDefaultDashboard (state, getters) {
    if (getters.favoriteDashboards.length > 0) return getters.favoriteDashboards[0]
    if (getters.myDashboards.length > 0) return getters.myDashboards[0]
    if (getters.sharedDashboards.length > 0) return getters.sharedDashboards[0]
    return null
  },
  openConfiguratorView (state) {
    return !!state.widgetToConfigure
  },
  getLoadedAnalyticsPages (state) {
    return state.loadedAnalyticsPages
  },
  getLoadingAnalyticsPages (state) {
    return state.loadingAnalyticsPages
  },
  getCurrentPage (state) {
    return state.currentPage
  },
  getCurrentPageId (state) {
    return state.currentPage.id
  },
  getWidgets (state) {
    return state.widgets
  },
  getDefaultWidgets (state) {
    return state.defaultWidgets
  },
  showWidgetSidebar (state) {
    return state.showWidgetSidebar
  },
  widgetsAvailable (state) {
    return state.widgets.length > 0
  },
  getWidgetToConfigure (state) {
    return state.widgetToConfigure
  },
  getTransformedWidget: (state) => (widget) => {
    try {
      const widgetConfigurations = state.defaultWidgets[widget.type]
      return Object.assign(widget, widgetConfigurations)
    } catch (error) {
      return null
    }
  },
  getTransformedWidgets: (state, getters) => (widgets) => {
    return widgets.map(widget => {
      widget = getters.getTransformedWidget(widget)
      return widget
    })
  },
  getTranslatedInfo: (state, getters, rootState, rootGetters) => (component) => {
    return rootGetters["I18nStore/getI18n"](component.name, component.info)
  },
  isUserCreator: (state, getters, rootState) => (page = null) => {
    page = page || state.currentPage
    const pageCreator = page && page.created_by ? page.created_by.pk : null
    return pageCreator === rootState.auth.user.pk
  },
  canModifyWidgetPage: (state, getters, rootState, rootGetters) => (page = null) => {
    return getters.isUserCreator(page) || rootGetters["auth/isAdminOrSuperUser"]
  },
  showMenu (state) {
    return state.showMenu
  },
  loadedDashboardsOnce (state) {
    return state.loadedDashboardsOnce
  },
  getPreviewChartData (state) {
    return state.previewChartData
  }
}

export const mutations = {
  addFavoritePage (state, page) {
    state.favoritePage = page
  },
  setWidgets (state, widgets) {
    state.widgets = widgets
    state.currentPage.widgets = state.widgets
  },
  updateWidgetsList (state, updatedWidget) {
    let index = state.widgets.findIndex(widget => widget.id === updatedWidget.id)
    let deleteCount = 1
    // To show the user that the widget was updated/added the below properties are used
    updatedWidget.added = false
    updatedWidget.alertCountDown = 5
    if (index < 0) {
      index = 0
      deleteCount = 0
      updatedWidget.added = true
    }
    state.widgets.splice(index, deleteCount, updatedWidget)
  },
  showWidgetSidebar (state) {
    state.showWidgetSidebar = true
  },
  closeWidgetSidebar (state) {
    state.showWidgetSidebar = false
  },
  setWidgetToConfigure (state, value) {
    state.previewChartData = null
    state.widgetToConfigure = value
  },
  setAnalyticsPages (state, value) {
    state.loadedAnalyticsPages = value
  },
  updateCurrentPage (state, value) {
    state.currentPage = { ...state.currentPage, ...value }
  },
  deleteWidget (state, widgetId) {
    state.widgets = state.widgets.filter(widget => widget.id !== widgetId)
    // update widgets in the current page
    state.currentPage.widgets = state.widgets
  },
  deletePage (state, pageId) {
    state.loadedAnalyticsPages = state.loadedAnalyticsPages.filter(page => page.id !== pageId)
  },
  showPage (state, page = null) {
    if (page) {
      state.currentPage = page
      state.widgets = page.widgets || []
    } else {
      state.currentPage = {}
      state.widgets = []
    }
  },
  addPage (state, page) {
    state.loadedAnalyticsPages.push(page)
  },
  setDefaultWidgets (state, defaultWidgets) {
    state.defaultWidgets = defaultWidgets
  },
  setLoadingAnalyticsPages (state, value) {
    state.loadingAnalyticsPages = value
  },
  showDashboardMenu (state) {
    state.showMenu = true
  },
  hideDashboardMenu (state) {
    state.showMenu = false
  },
  toggleDashboardMenu (state) {
    state.showMenu = !state.showMenu
  },
  updatePageList (state, page) {
    const index = state.loadedAnalyticsPages.findIndex(widget => widget.id === page.id)
    if (index > -1) {
      state.loadedAnalyticsPages.splice(index, 1, page)
    }
  },
  setLoadedDashboardsOnce (state) {
    state.loadedDashboardsOnce = true
  },
  resetCurrentPage (state) {
    state.currentPage = {}
    state.widgets = []
  },
  setPreviewChartData (state, chartData) {
    state.previewChartData = chartData
  }
}

export const actions = {
  duplicateDashboard ({ getters, dispatch, commit }, dashboard) {
    return new Promise((resolve, reject) => {
      const titlePrefix = getTranslation(dashboardStoreUtils).copyOf
      const titleCopy = dashboard.name.startsWith(titlePrefix) ? dashboard.name : `${titlePrefix} ${dashboard.name}`
      const newDashboardTitle = dashboardStoreUtils.getNewDashboardTitle(getters.getLoadedAnalyticsPages, titleCopy)
      dispatch("cloneDashboard", { id: dashboard.id, name: newDashboardTitle })
        .then((newPage) => {
          resolve(newPage)
        }).catch(error => {
          reject(error)
        })
    })
  },
  cloneDashboard ({ getters, commit, dispatch }, widgetPage) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/widgetpages/${widgetPage.id}/clone`, { name: widgetPage.name }).then(response => {
        const widgets = []
        const newPage = response.data
        if (newPage.widgets.length > 0) {
          newPage.widgets.forEach((widget) => {
            widgets.push({ ...widget, ...getters.getDefaultWidgets[widget.type] })
          })
        }
        newPage.widgets = widgets
        commit("addPage", newPage)
        resolve(newPage)
      }).catch(error => {
        reject(error)
      })
    })
  },
  handleWidgetDeleted ({ dispatch, commit }, widgetId) {
    dispatch("closeWidgetSidebar")
    commit("deleteWidget", widgetId)
  },
  handleWidgetCloned ({ dispatch, commit, getters }, widget) {
    dispatch("closeWidgetSidebar")
    return new Promise((resolve, reject) => {
      dispatch("updateWidget", { ...widget, id: null }).then(data => {
        const widgets = getters.getWidgets
        const clonedWidget = {
          ...widget,
          id: data.id,
          order: widget.order + 1,
          added: true,
          alertCountDown: 5
        }
        const newOrder = widgets.slice(0, widget.order).concat([clonedWidget], widgets.slice(widget.order))
        dispatch("setNewWidgetsOrder", newOrder).then(() => resolve())
      }).catch(error => {
        console.debug(error)
        reject(error)
      })
    })
  },
  loadAnalyticsPages ({ commit, getters, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingAnalyticsPages", true)
      dispatch("loadWidgetTypesComponents")
      axios.get("/api/widgetpages").then(response => {
        let loadedAnalyticsPages = response.data.results
        loadedAnalyticsPages = loadedAnalyticsPages.map(page => {
          page.widgets = getters.getTransformedWidgets(page.widgets).filter(item => item !== null)
          return page
        })
        commit("setAnalyticsPages", loadedAnalyticsPages)
        commit("setLoadingAnalyticsPages", false)
        commit("setLoadedDashboardsOnce")
        dispatch("setFavoritePage")
        resolve()
      }).catch(error => {
        commit("setLoadingAnalyticsPages", false)
        reject(error)
      })
    })
  },
  loadWidgetTypesComponents ({ commit, getters }) {
    const transformedDefaultWidgets = {}
    for (const widget of Object.values(getDefaultWidgets())) {
      const translatedInfo = getters.getTranslatedInfo(widget.component)
      transformedDefaultWidgets[widget.type] = { ...widget, ...translatedInfo }
    }
    commit("setDefaultWidgets", transformedDefaultWidgets)
  },
  setFavoritePage ({ state, commit, getters }) {
    return new Promise((resolve, reject) => {
      // set as favorite page if a page has "is_favorite" value true
      // otherwise reset the value of favorite page on store to initial state (in case of page delete action).
      const favoritePage = getters.getLoadedAnalyticsPages.filter(page => page.is_favorite === true)
      if (favoritePage.length > 0) {
        commit("addFavoritePage", favoritePage[0])
      } else { state.favoritePage = null }
      resolve()
    })
  },
  createFavoritePage ({ dispatch }, pageId) {
    return new Promise((resolve, reject) => {
      axios.post("/api/widgetpages/" + pageId + "/set_as_favorite").then((response) => {
        dispatch("loadAnalyticsPages")
        resolve()
      }).catch(error => {
        console.debug(error)
      })
    })
  },
  toggleFavorite ({ commit }, page) {
    return new Promise((resolve, reject) => {
      axios.get("/api/widgetpages/" + page.id + "/toggle_favorite").then((response) => {
        commit("updatePageList", { ...page, is_among_favorites: response.data.is_among_favorites })
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  openWidgetConfigurator ({ dispatch, commit }, widget) {
    commit("showWidgetSidebar")
    commit("setWidgetToConfigure", widget)
  },
  updateWidget ({ getters }, widget) {
    return new Promise((resolve, reject) => {
      const data = {
        ...widget,
        config: widget.config || {},
        order: 0,
        page: getters.getCurrentPageId
      }
      let method = "post"
      let url = "/api/widgets"
      if (widget.id) {
        method = "put"
        url = url + "/" + widget.id
      }
      axios[method](url, data).then((response) => {
        resolve(response.data)
      }).catch(error => {
        console.debug(error)
      })
    })
  },
  async previewWidget ({ getters, commit }, widget) {
    try {
      const { name, type, id } = widget
      const widgetData = {
        config: widget.config || {},
        type,
        name,
        id
      }
      const url = "/api/widgets/preview"
      const { data } = await axios.post(url, widgetData)
      commit("setPreviewChartData", data)
    } catch (error) {
      console.debug(error)
    }
  },
  updateWidgetAndClose ({ dispatch, commit }, widget) {
    return new Promise((resolve, reject) => {
      dispatch("updateWidget", widget).then((response) => {
        dispatch("closeWidgetSidebar")
        resolve(response)
      }).catch(error => {
        console.log(error)
      })
    })
  },
  setNewWidgetsOrder ({ dispatch, commit }, newWidgetsOrder) {
    commit("setWidgets", newWidgetsOrder)
    dispatch("updateWidgetsOrder")
  },
  updateWidgetsOrder ({ getters }) {
    return new Promise((resolve, reject) => {
      const data = {
        widgets: getters.getWidgets.map(item => item.id)
      }
      axios.post("/api/widgets/update_order", data).then((response) => {
        resolve(response.data)
      }).catch(error => {
        console.debug(error)
      })
    })
  },
  closeWidgetSidebar ({ commit }) {
    commit("closeWidgetSidebar")
    commit("setWidgetToConfigure", null)
  },
  updateWidgetsList ({ dispatch, commit, getters }, updatedWidget) {
    const transformedWidget = getters.getTransformedWidget(updatedWidget)
    commit("updateWidgetsList", transformedWidget)
    dispatch("updateWidgetsOrder")
  },
  getPlaybookNames ({ commit }, playbookIds) {
    return new Promise((resolve, reject) => {
      axios.get("/api/talkscripts/get_analytics_view?id__in=" + playbookIds).then((response) => {
        const playbookNames = response.data.map(item => item.name)
        resolve(playbookNames)
      }).catch(error => {
        console.debug(error)
        reject(error)
      })
    })
  },
  getObjectionNames ({ commit }, { playbookId, objectionIds }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/talkscripts/${playbookId}/get_objections`).then((response) => {
        const objectionNames = response.data.filter(item => objectionIds.includes(item.id)).map(item => item.name)
        resolve(objectionNames)
      }).catch(error => {
        console.debug(error)
        reject(error)
      })
    })
  },
  getPlaybookItemNames ({ commit }, playbookItemIds) {
    return new Promise((resolve, reject) => {
      axios.get("/api/talkscriptitems/get_analytics_view?id__in=" + playbookItemIds).then((response) => {
        const playbookItemNames = response.data.map(item => item.name)
        resolve(playbookItemNames)
      }).catch(error => {
        console.debug(error)
        reject(error)
      })
    })
  },
  getCounterpartNames ({ commit }, counterpartIds) {
    return new Promise((resolve, reject) => {
      axios.get("/api/counterpartsnew/get_saved_counterparts?id__in=" + counterpartIds).then((response) => {
        const counterpartNames = response.data.results.map(item => item.name)
        resolve(counterpartNames)
      }).catch(error => {
        console.debug(error)
        reject(error)
      })
    })
  },
  downloadWidgetAsPNG ({ getters }, widget) {
    // Problem: svg images are not rendered in the downloaded png
    // useful solution proposals:
    // https://github.com/niklasvh/html2canvas/issues/722
    // https://github.com/zeusstl/html2canvas-proxy-nodejs
    const widgetView = document.getElementById("chart-" + widget.id)
    const filename = (widget.name ? widget.name : widget.title) + ".png"
    if (widgetView == null) return

    import("html2canvas").then((module) => {
      const html2canvas = module.default
      html2canvas(widgetView).then(canvas => downloadCanvasAsPng(canvas, filename))
    })
  },
  createDashboard ({ commit }, data) {
    return new Promise((resolve, reject) => {
      return axios.post("/api/widgetpages", data).then(response => {
        commit("addPage", response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateDashboard ({ commit }, widgetPage) {
    return new Promise((resolve, reject) => {
      return axios.put(`/api/widgetpages/${widgetPage.id}`, widgetPage).then(response => {
        commit("updatePageList", response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export function downloadCanvasAsPng (canvas, filename) {
  const link = document.createElement("a")
  link.download = filename
  link.href = canvas.toDataURL()
  link.click()
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
