<template>
  <div>
    <div :id="'filter-applied-20220902-'+widget.id"
         class="grey-div mw-100 d-inline-block"
         :class="filtersExist ? 'cursor-pointer' : ''"
         @click="toggleFiltersPopover"
    >
      <img src="/img/icons/filter.svg"/>{{ getFilterText }}
    </div>

    <transition name="animate-down">
      <div v-if="showFiltersPopover && filtersExist" class="mt-1 p-2 filters-popover">
        <progress-indicator
          v-if=loading
          :small="true"
          :show-loading-label="false"
        ></progress-indicator>

        <ul v-else
            class="filters-popover__inner"
        >
          <li v-for="(filter, index) in filtersList"
              :key="index+'-'+widget.id"
              class="filter pb-2"
          >
            <span class="filter__name pb-1">{{ filter.name }}</span>
            <ul v-if="Array.isArray(filter.value)">
              <li v-for="(value, valueIndex) in filter.value"
                  :key="index+valueIndex"
                  class="filter__values"
              >
                {{ value }}
              </li>
            </ul>
            <div v-else>{{ filter.value }}</div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import _isEqual from "lodash/isEqual"
import {
  FILTER_KEY_TIMEFRAME,
  FILTER_KEY_PLAYBOOK,
  FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER,
  FILTER_KEY_TAGS,
  FILTER_KEY_DURATION,
  FILTER_KEY_COUNTERPART,
  FILTER_KEY_USER,
  FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__ITEMS,
  FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__TEXT,
  getTranslatedTimeframes,
  getTranslatedUserFilters,
  FILTER_KEY_OBJECTIONS,
  FILTER_KEY_OBJECTIONS__PLAYBOOK,
  FILTER_KEY_OBJECTIONS__OBJECTIONS,
  FILTER_KEY_TEXT_MATCH,
  FILTER_KEY_TRANSCRIPT_SEARCH,
  FILTER_KEY_AUDIO_AVAILABLE,
  FILTER_KEY_VIDEO_AVAILABLE, FILTER_KEY_CALLS_ENDED_WITH_QUICK_END
} from "@/apps/dashboard/chartUtils"
import { USER_TYPE_FILTER_KEY, USER_PK_FILTER_LABEL } from "@/apps/call_history/CallListComponents/filtersUtils.js"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import axios from "axios"

export default {
  name: "BaoWidgetFiltersDisplay",
  components: {
    ProgressIndicator
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      getTranslatedTimeframes,
      getTranslatedUserFilters,
      staticTextDefault: {
        filter: "filter",
        filters: "filters",
        dateRange: "Date range",
        itemAnswer: "Item: Answers",
        playbook: "Playbook",
        shortcuts: "Shortcuts",
        tags: "Labels",
        duration: "Call duration",
        counterparts: "Counterparts",
        textMatch: "Text-Match",
        browseTranscript: "Browse Transcript",
        videoAvailable: "Video available",
        audioAvailable: "Audio available",
        callsEndedWithQuickEnd: "Calls Ended with Quick Call End",
        yes: "Yes",
        no: "No",
        user: "User",
        seconds: "seconds",
        lessThanLabel: "less than",
        greaterThanLabel: "greater than",
        equalToLabel: "equal to"
      },
      filtersList: [],
      filtersObject: {},
      loading: false,
      translatedTimeframes: {},
      translatedUserFilters: {},
      showFiltersPopover: false
    }
  },
  computed: {
    ...mapGetters({
      currentUserIsAdminOrSuperuser: "auth/isAdminOrSuperUser",
      showTextMatch: "auth/showTextMatch",
      canUseBaoAudio: "auth/canUseBaoAudio",
      canFilterOnUserBasis: "auth/canFilterOnUserBasis",
      canUseVideoIntegration: "auth/canUseVideoIntegration"
    }),
    filtersExist () {
      return this.filtersCount > 0
    },
    filtersCount () {
      if (!(!!this.widget && !!this.widget.config && !!this.widget.config.filters)) return 0
      else {
        let visibleFiltersCount = 0
        for (const key of Object.keys(this.widget.config.filters)) {
          if (this.filterData[key]) visibleFiltersCount++
        }
        return visibleFiltersCount
      }
    },
    getFilterText () {
      return `${this.filtersCount} ${this.filtersCount === 1 ? this.staticText.filter : this.staticText.filters}`
    },
    filterData () {
      const filterData = {
        [FILTER_KEY_TIMEFRAME]: { name: this.staticText.dateRange, getFormattedValue: this.getTranslatedTimeframe },
        [FILTER_KEY_PLAYBOOK]: { name: this.staticText.playbook, getFormattedValue: this.getPlaybookNames },
        [FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER]: {
          name: this.staticText.itemAnswer,
          getFormattedValue: this.getFormattedItemAnswers
        },
        [FILTER_KEY_OBJECTIONS]: { name: this.staticText.shortcuts, getFormattedValue: this.getFormattedObjections },
        [FILTER_KEY_TAGS]: { name: this.staticText.tags, getFormattedValue: this.getFormattedTags },
        [FILTER_KEY_DURATION]: { name: this.staticText.duration, getFormattedValue: this.getFormattedDuration },
        [FILTER_KEY_COUNTERPART]: { name: this.staticText.counterparts, getFormattedValue: this.getCounterpartNames },
        [FILTER_KEY_CALLS_ENDED_WITH_QUICK_END]: {
          name: this.staticText.callsEndedWithQuickEnd,
          getFormattedValue: this.getAvailabilityValue
        }
      }
      if (this.currentUserIsAdminOrSuperuser && this.canFilterOnUserBasis) {
        filterData[FILTER_KEY_USER] = { name: this.staticText.user, getFormattedValue: this.getTranslatedUserFilter }
      }
      if (this.showTextMatch) {
        filterData[FILTER_KEY_TEXT_MATCH] = {
          name: this.staticText.textMatch,
          getFormattedValue: this.getFormattedPercentage
        }
      }
      if (this.canUseBaoAudio) {
        filterData[FILTER_KEY_TRANSCRIPT_SEARCH] = {
          name: this.staticText.browseTranscript,
          getFormattedValue: this.getFormattedTranscriptSearchValue
        }
      }
      if (this.canUseBaoAudio) {
        filterData[FILTER_KEY_AUDIO_AVAILABLE] = {
          name: this.staticText.audioAvailable,
          getFormattedValue: this.getAvailabilityValue
        }
      }
      if (this.canUseVideoIntegration) {
        filterData[FILTER_KEY_VIDEO_AVAILABLE] = {
          name: this.staticText.videoAvailable,
          getFormattedValue: this.getAvailabilityValue
        }
      }
      Object.keys(filterData).map(function (key, index) {
        // we add the "type" property for easier handling of the objects later on
        filterData[key].type = key
        return filterData[key]
      })
      return filterData
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    "widget.config.filters": {
      handler (val, oldVal) {
        if (!_isEqual(oldVal, val)) {
          this.setUp()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.translatedTimeframes = this.getTranslatedTimeframes()
    this.translatedUserFilters = this.getTranslatedUserFilters()
    this.setUp()
  },
  methods: {
    ...mapActions({
      getPlaybookNames: "dashboardStore/getPlaybookNames",
      getPlaybookItemNames: "dashboardStore/getPlaybookItemNames",
      getCounterpartNames: "dashboardStore/getCounterpartNames",
      getObjectionNames: "dashboardStore/getObjectionNames"
    }),
    setUp () {
      /**
       * This method extract the filter type and value from config.filters one by one and calls the method
       * transformValue to get formatted values instead of raw values
       * Once all the promises are resolved or rejected it sets loading flag to false
       * Note: for every transform method a promise is returned so that we can run parallel requests(to backend) rather
       * than waiting(using async) for a promise of a specific type(playbook names/counterpart names/playbook item
       * names) to complete
       * */
      this.filtersList = []
      this.filtersObject = {}
      const promises = []
      if (this.widget && this.widget.config && this.widget.config.filters) {
        this.loading = true
        for (const [key, value] of Object.entries(this.widget.config.filters)) {
          if (this.filterData[key]) promises.push(this.transformData(key, value))
        }
        return Promise.all(promises).catch(error => {
          console.debug(error)
        }).finally(() => {
          this.filtersList = this.sortList(Object.values(this.filtersObject), Object.keys(this.widget.config.filters))
          this.loading = false
        })
      }
    },
    transformData (key, value) {
      /**
       * This method calls the getTransformedValue method and on success, it sets the type, name and
       * value(transformed value) for the filters
       * On receiving error from getTransformedValue method, it does not add that type to filtersObject
       * */
      return new Promise((resolve, reject) => {
        this.getTransformedValue(key, value).then(value => {
          this.filtersObject[key] = {
            type: key,
            name: this.filterData[key].name,
            value
          }
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getFormattedItemAnswers (value) {
      /** Returns a new promise that gets resolved only once the data is computed for playbook-items and answers
       * Only the first playbook item is shown right now
       * Format :
       * "Item 1: ans1, ans2" - if item is available
       * "ans1, ans2" - if item is not available
       * */
      const that = this
      return new Promise(function (resolve, reject) {
        return that.getPlaybookItemNames(value[FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__ITEMS]).then((items) => {
          let data = ""
          if (items && items.length > 0) {
            data = `${items.join(", ")}: `
          }
          if (typeof value[FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__TEXT] === "string") {
            data = `${data}${value[FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__TEXT]}`
          } else {
            data = `${data}${value[FILTER_KEY_PLAYBOOK_ITEM_AND_ANSWER__TEXT].join(", ")}`
          }
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getFormattedObjections (value) {
      /** Returns a new promise that gets resolved only once the data is computed for objections
       * Format :
       * "Playbook: obj1, obj2"
       * */
      const that = this
      return new Promise(function (resolve, reject) {
        return that.getPlaybookNames(value[FILTER_KEY_OBJECTIONS__PLAYBOOK]).then((items) => {
          let data = ""
          if (items && items.length > 0) {
            data = `${items[0]}: `
          }
          const objectionData = {
            playbookId: value[FILTER_KEY_OBJECTIONS__PLAYBOOK],
            objectionIds: value[FILTER_KEY_OBJECTIONS__OBJECTIONS]
          }
          that.getObjectionNames(objectionData).then((objections) => {
            if (objections) {
              data = `${data}${objections.join(", ")}`
              resolve(data)
            }
          })
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getFormattedDuration (value) {
      /***
       * This method transforms max and min duration from milliseconds to secs and formats them as below
       * Format :
       * [ "Min: 5 seconds", "Max: 60 seconds"]
       */
      return new Promise((resolve) => {
        const data = []
        if (value && value.gte) data.push(`Min.: ${value.gte / 1000} ${this.staticText.seconds}`)
        if (value && value.lte) data.push(`Max.: ${value.lte / 1000} ${this.staticText.seconds}`)
        resolve(data)
      })
    },
    getFormattedPercentage (value) {
      /***
       * This method transforms max and min percentage
       * Format :
       * [ "Min: 0%", "Max: 100%"]
       */
      return new Promise((resolve) => {
        const data = []
        if (value && value.min_value) data.push(`Min: ${value.min_value}%`)
        if (value && value.max_value) data.push(`Max: ${value.max_value}%`)
        resolve(data)
      })
    },
    getAvailabilityValue (value) {
      return new Promise((resolve) => {
        resolve(value ? this.staticText.yes : this.staticText.no)
      })
    },
    getFormattedTranscriptSearchValue (value) {
      /***
       * This method transforms {
       *  transcript_texts: ["SampleA", "SampleB"],
       *  includeSimilar: True
       * }
       * Format :
       *  Browse Transcript: ["SampleA", "SampleB"]
       */
      return new Promise((resolve) => {
        resolve(value.transcript_texts)
      })
    },
    getTranslatedTimeframe (value) {
      if (typeof value === "object" && (!!value.created_at__gte || !!value.created_at__lte)) value = "static"
      /***
       * This method returns the timeframe text to display(based on language)
       * translatedTimeframes is set in mounted method
       * If key is not available in translatedTimeframes, it returns error
       */
      return new Promise((resolve, reject) => {
        if (this.translatedTimeframes[value]) resolve(this.translatedTimeframes[value])
        reject(new Error("Invalid timeframe"))
      })
    },
    getTranslatedUserFilter (value) {
      /***
       * This method returns the user filter text to display(based on language) or based on selected users
       * If based on selected User Ids, each user is fetched from the DB, and the full_name of each is displayed
       * Else display is gotten from translation
       * user filter is set in mounted method
       * If key is not available in translatedUserFilter, it returns error
       */
      return new Promise((resolve, reject) => {
        if (value && value.selectedUser && value.selectedUser.length) {
          if (value.filterKey === USER_TYPE_FILTER_KEY) {
            if (this.translatedUserFilters[value.selectedUser[0]]) resolve(this.translatedUserFilters[value.selectedUser[0]])
          } else if (value.filterKey === USER_PK_FILTER_LABEL) {
            resolve(this.getSelectedUsers(value.selectedUser))
          }
        }

        reject(new Error("Invalid user option"))
      })
    },
    async getSelectedUsers (users) {
      try {
        const params = {
          is_active: true,
          id__in: users.toString()
        }
        const { data } = await axios.get("/api/users/get_group_members", { params })
        return data.length ? data.map(user => user.full_name) : []
      } catch (error) {
        console.error(error)
      }
    },
    getTransformedValue (filterType, value) {
      /***
       * This method calls respective functions to format the values based on the filter-type
       * Note: for every transform-method a promise is returned so that we can run parallel requests(to backend) rather
       * than waiting(using async) for a promise of a specific type(playbook-names/counterpart-names/playbook-item-names)
       * to complete
       */
      return this.filterData[filterType].getFormattedValue(value)
    },
    sortList (transformedFilterList, orderedFilterList) {
      /***
       * This method sorts the transformedFilterList according to the elements order in orderedFilterList
       * This is required to show the filterList in the same order as they are available in widget.config.filter
       */
      return transformedFilterList.sort((a, b) => orderedFilterList.indexOf(a.type) - orderedFilterList.indexOf(b.type))
    },
    toggleFiltersPopover () {
      this.showFiltersPopover = !this.showFiltersPopover
    },
    getFormattedTags (value) {
      /***
       * This method returns the formatted tags list
       * Input: ["group_1/group2/tag_1_name", "group3/tag2", "tag3"]
       * Output: ["Group 1: Group2 : tag 1 name", "Group3: tag2", "tag3"]
       */
      return new Promise((resolve, reject) => {
        if (!Array.isArray(value)) {
          reject(new Error("Invalid tag"))
        }
        const formattedTagNames = value.map(item => {
          return item.split("/").map(str => this.toPascalCase(str)).join(": ")
        })
        resolve(formattedTagNames)
      })
    }
  }
}
</script>

<style scoped lang="scss">
ul {
  padding-inline-start: 20px !important;
  margin-bottom: 0;
}

.grey-div {
  padding: 0.5rem;
  background-color: $slate06;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 32px;
  white-space: nowrap;
  border-radius: 8px;
  font-size: 12px;
  color: $slate;
}

.filter {
  text-align: left !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: $slate60 !important;
}

.filter__name {
  font-weight: 700;
  font-size: 14px;
  color: $slate80;
}

.filter__values {
  padding-bottom: 0.25em;
}

.filters-popover__inner {
  max-height: 250px;
  overflow-y: auto;
  padding-right: 0.25em;
}

.tooltip.b-tooltip {
  opacity: 1 !important;
}

img {
  height: 15px;
  width: 15px;
  margin-right: 0.25rem;
}

.filters-popover {
  border-radius: 12px !important;
  max-width: 250px;
  min-width: 150px;
  box-shadow: 6px 8px 20px 5px rgba(0, 0, 0, 0.03);
  z-index: 4000;
  position: absolute;
  background: $white;
  -webkit-box-shadow: 6px 8px 20px 5px rgba(0, 0, 0, 0.03);
}

</style>
